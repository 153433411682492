import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "./Baseurl";
import { HOMEROUTE } from "../Constants";
const BoardName = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [avtar, setAvtar] = useState();
  const [totalStars, setTotalStars] = useState();
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem("groupId");
  if(user_details === null)
  {
      return navigate("/");
  }

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {
    userType = 'group';
    user_id = groupId;
  }


  // save url resume pages starts here

  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
      url: location.pathname,
    });

    var config = {
      method: "post",
      url: BaseURL + "/resumePages",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  //ends hre
  useEffect(() => {
    getAlerts()
    // setInterval(() => getAlerts(), 10000)
}, [])

const getAlerts= ()=>{
  var data = JSON.stringify({
    token:
    `${user_details.token}`,
  });
  var config = {
    method: "post",
    url: BaseURL+"/get_user",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios(config)
    .then(function (response) {
      if(response.status === "Token is Expired")
      {
        window.localStorage.clear();
        navigate('/welcome')
      }
      if(response.data.message === "Token Invalid")
      {
        window.localStorage.clear();
        navigate('/welcome')
      }
        if(response.data === '' || response.data === null)
        {
          window.localStorage.clear();
          navigate('/welcome')
        }
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log(error);
    });
}
  if (user_details !== null) {
    useEffect(() => {
      if (user_details) {
        setName(user_details.logUsername);
      }
      var data = JSON.stringify({
        user_id: user_details.user_id,
      });
      var config = {
        method: "post",
        url: BaseURL + "/getAvtarByUser",
        headers: {
          Authorization: `Bearer ${user_details.token} `,

          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          setAvtar(response.data.data.avtar);
          // console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);

    useEffect(() => {

      if (user_details) {
        setName(user_details.logUsername);
      }
      var data = JSON.stringify({
        user_id: user_id,
      });
      var config = {
        method: "post",
        url: BaseURL + "/getFinalStars",
        headers: {
          Authorization: `Bearer ${user_details.token} `,
          type: `${userType} `,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          setTotalStars(response.data.data.total_stars);
          // console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);
  } else {
    navigate("/");
  }
  const goToFaq = () => {
    const pathdata = location.pathname;
    console.log(pathdata);
    localStorage.setItem("previous_link", JSON.stringify(pathdata));
    navigate("/faq2");
  };

  const Faq = () => {
    if (
      location.pathname !== "/board-screen" &&
      location.pathname !== "/karo-05-activity-03-screen" &&
      location.pathname !== "/karo-05-activity-04-screen" &&
      location.pathname !== "/karo-05-activity-05-screen"
    ) {
      return (
        <>
          <div class={props.faqsize}>
            <ul class="list-navi float-right">
              <li>
                {/* <NavLink to="/Faq2" className="tooltip">
                  <img src="assets/img/icon/ques.svg" />
                  <span className="tooltiptext">Faq</span>
                </NavLink> */}
                <button onClick={goToFaq} className="help" title="Faq">
                  <img src="assets/img/icon/ques.svg" />
                  {/* <span className="tooltiptext">Faq</span> */}
                </button>
              </li>
            </ul>
          </div>
        </>
      );
    }
  };
  return (
    <>

        <div className="my-0 mr-md-auto font-weight-norma d-flex my-profile">
          <NavLink to={HOMEROUTE} className="width-auto help" title="Main Page">
            <img
              // style={{ width: "5vw" }}
              className="home-icon"
              src="assets/img/icon/home.png"
              alt=""
            />

            {/* <span className="tooltiptext">Main Page</span> */}
          </NavLink>
          <NavLink to="/profile">
            <div className="anu">
              <img className="anu-img" src={avtar} />
              <p className="anu-para">{name}</p>
              <img className="anu-star" src="assets/img/general/star.svg" />
              <p className="anu-para">{totalStars}</p>
            </div>
          </NavLink>

      </div>



      <Faq />






    </>
  );
};
export default BoardName;
