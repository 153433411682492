import React, { useEffect, useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import InnerFooter from "../common/InnerFooter";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";
import axios from "axios";
import BaseURL from "../common/Baseurl";

import swal from "sweetalert";

const Support = () => {

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [contact, setContact] = useState("");


  const submitHelp = (e) => {

    e.preventDefault();
    let data = JSON.stringify({
      "userId": userId,
      "subject": subject,
      "contact": contact,
      "message": message
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseURL+'/support',
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      if (response.data.success === true) {



        swal(response.data.message, "", "success");
        setSubject("");
        setMessage("");
        setUserId("");
        setContact("");
        window.location.reload();


        return false;
      }

      swal(response.data.message, "", "error");
      // console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });

  };

  return (
    <>

<form action="#" method="post" onSubmit={submitHelp}>
      <main>
        <InnerFooter />
        <section className="fixed-bg">
          <HomeMenuOuter />
          <div className="container-fluid cf-set">
            <div className="row ">
              <div className="col-md-6" style={{ "margin-top": "81px" }}>
                <div className="profile-enam padding-set-a">
                  <div className=" d-flex align-items-center">
                    <div className="content-profile ">
                      <h2 style={{ color: "#fff" }}>Email:-</h2>
                      <h3>contact@inbreakthrough.org</h3>
                    </div>
                  </div>
                </div>

                <div className="profile-enam profile-color-b mt-3 ">
                  <div class=" d-flex align-items-center">
                    <div class="content-profile">
                      <h2 style={{ color: "#fff" }}>Address:-</h2>
                      <h4>
                        Plot-3, DDA Community Centre, Zamrudpur, New Delhi,
                        Delhi 110048
                      </h4>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-lg-6 mt-5 pb-5">

                <h3 className="text-center">Contact Us</h3>


                <div className="input  right" >


                  <select
                    className="set-input"

                    required

                    value={subject}
                    onChange={(e) =>
                      setSubject(
                        e.target.value
                      )
                    }

                  >
                    <option  value="" >विषय</option>
                    <option value="खाता हटाना" >खाता हटाना</option>
                    <option value="अन्य">अन्य</option>

                  </select>
                </div>



                <div
                  className="input-group wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <label className="levelset ">
                    यूजर आईडी <span className="error">*</span>
                  </label>
                  <div className="input right">
                    <input
                      id="userna"
                      autoComplete="off"
                      type="text"
                      className="set-input"
                      placeholder=""
                      defaultValue={userId}
                      onChange={(e) =>
                        setUserId(e.target.value)
                      }


                    />
                  </div>


                </div>

                <div
                  className="input-group"

                >
                  <label className="levelset ">
                  ईमेल/फ़ोन <span className="error">*</span>
                  </label>
                  <div className="input right">
                    <input
                      id="phonenumber"
                      autoComplete="off"
                      type="text"
                      className="set-input"
                      placeholder=""

                      defaultValue={contact}
                      onChange={(e) =>
                        setContact(e.target.value)
                      }



                    />
                  </div>


                </div>


                <div
                  className="input-group"

                >
                  <label className="levelset ">
                  संदेश <span className="error">*</span>
                  </label>
                  <div className="input right textarea_box">
                    <textarea

                      autoComplete="off"

                      className="set-textarea"
                      placeholder=""
                      value={message}
                      onChange={(e) =>
                        setMessage(e.target.value)
                      }

                    ></textarea>
                  </div>


                </div>








                <div
                  className="mt-15 mt-m-30 mb-15 "


                >
                  {/* <NavLink to="general-screen6.html" className="btnset blue-shadow "><span>लॉग इन </span> </NavLink> */}

                  <button type="submit" className="btnset blue-shadow ">
                    {" "}
                    <span>सबमिट </span>
                  </button>
                </div>

              </div>


            </div>
          </div>
        </section>
      </main>
              </form>
    </>
  );
};

export default Support;
