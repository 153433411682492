import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../style.css";
import axios from "axios";
import FlashMessage from "../FlashMessage";
import BaseURL from "../common/Baseurl";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

import GoogleLogin from "react-google-login";

// import { signInWithGoogle } from '../../Firebase';

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const StudentSignUp = () => {
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [mobEmail, setMobEmail] = useState();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [gender, setGender] = useState();
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const [policy, setPolicy] = useState(false);
  const [policyErr, setPolicyErr] = useState({});

  const [btnDisabled, setBtnDisabled] = useState(false);

  // for error state
  const [mobEmailErr, setmobEmailErr] = useState({});
  const [userNameErr, setUserNameErr] = useState({});
  const [passwordErr, setPasswordErr] = useState({});
  const [genderErr, setGenderErr] = useState({});



  const [isVisible, setVisible] = useState(false);
  const users = JSON.parse(localStorage.getItem("student-details"));


  const toggle = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    if (users) {
      setItems(users);
    }
  }, []);

  const studentSignUpForm = (e) => {
    // console.log(e);
    e.preventDefault();
    const isValid = formValidation();

    if (isValid === true) {
      const regex = /(<([^>]+)>)/gi;
      const __mobEmail =
      mobEmail !== undefined ? mobEmail.replace(regex, "") : "";
      const __userName =
      userName !== undefined ? userName.replace(regex, "") : "";
      const __password =
      password !== undefined ? password.replace(regex, "") : "";
      const __fullName =
      items.fullname !== undefined ? items.fullname.replace(regex, "") : "";
      const __dob = items.dob !== undefined ? items.dob.replace(regex, "") : "";

      setBtnDisabled(true);

      var data12 = JSON.stringify({
        email: mobEmail,
      });

      var config = {
        method: "post",
        url: BaseURL + "/checkNoofEmailPhone",
        headers: {
          "Content-Type": "application/json",
        },
        data: data12,
      };

      axios(config)
        .then(function (response) {
          if(response.data.success == true)
          {
            var forgot_password_details = JSON.stringify({
              fullName: __fullName,
              dob: __dob,
              mobemail: __mobEmail,
              email: __mobEmail,
              username: __userName,
              username1: userName,
              password: __password,
              type: "student",
              gender: gender,
              auth_provider: "without_google",
              registration_type:users.registration_type
              // email_message_id: response.data.data.message_id,
            });


            localStorage.setItem(
              "student_signup_details",
              forgot_password_details
            );

            navigate("/acceptance");

          }


          mobEmailErr.required = response.data.message;
          setmobEmailErr(mobEmailErr);
        })
        .catch(function (error) {
          console.log(error);
        });









    }
    // console.log(mobEmail);
    // remove html tag
  };

  // form validation
  const formValidation = () => {
    const mobEmailErr = {};
    const userNameErr = {};
    const passwordErr = {};
    const genderErr = {};
    const mobEmail1 = mobEmail === undefined || mobEmail === "" ? "" : mobEmail;
    const userName1 = userName === undefined || userName === "" ? "" : userName;
    const password1 = password === undefined || password === "" ? "" : password;
    const gender1 = gender === undefined || gender === "" ? "" : gender;

    let isValid = true;

    // check username only string
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (pattern.test(mobEmail1)) {
      if (mobEmail1.length != 10) {
        isValid = false;

        mobEmailErr.required = "कृपया एक वैध नंबर डालें।";
      }
    } else {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(mobEmail1) === false) {
        isValid = false;
        mobEmailErr.required = "कृपया वैध ईमेल दर्ज़ करें";
      }
    }

    // console.log(mobEmail.length);
    if (mobEmail1.length === 0) {
      mobEmailErr.required = "ईमेल/ मोबाइल नंबर/ यूजर नाम की जरूरत है";
      isValid = false;
    }



    if (password1.length === 0) {
      passwordErr.required = "पासवर्ड की आवश्यकता है";
      isValid = false;
    }


    if (password1.length < 8) {
      passwordErr.required = "पासवर्ड 8 अक्षर के बराबर या उससे अधिक होना चाहिए";
      isValid = false;
    }

   const  password_validate = (password) => {
      var re = {
          capital: /(?=.*[A-Z])/,
          length: /(?=.{7,20}$)/,
          specialChar: /[ -\/:-@\[-\`{-~]/,
          digit: /(?=.*[0-9])/,
      };
      return (
          re.capital.test(password) &&
          re.length.test(password) &&
          !re.specialChar.test(password) &&
          re.digit.test(password)
      );
  };

       var checkpas =  password_validate(password1)
        if (!checkpas) {
          isValid = false;
          passwordErr.required = "कृपया पासवर्ड में एक संख्यात्मक संख्या, एक अपरकेस, एक लोअरकेस का उपयोग करें या विशेष वर्ण का उपयोग न करें, उदाहरण के लिए @!#$%^&*() आदि। पासवर्ड 8 अक्षर के बराबर या उससे अधिक होना चाहिए।";
        }





    if (gender1.length === 0) {
      genderErr.required = "लिंग आवश्यक है";
      isValid = false;
    }

    if(policy === false)
    {
      policyErr.required = "कृपया सहमत पर टिक करें ";
      isValid = false;
    }

    setPolicyErr(policyErr);


    setmobEmailErr(mobEmailErr);
    // setUserNameErr(userNameErr);
    setPasswordErr(passwordErr);
    setGenderErr(genderErr);
    return isValid;
  };

  const checkEmailMob = (e) => {
    const MobEmailVal = e.target.value;
    setMobEmail(MobEmailVal);

  };

  const checkUserName = (e) => {
    const username = e.target.value;
    setUserName(username);
    var data12 = JSON.stringify({
      logUsername: username,
    });

    var config = {
      method: "post",
      url: BaseURL + "/checkUserId",
      headers: {
        "Content-Type": "application/json",
      },
      data: data12,
    };

    axios(config)
      .then(function (response) {
        userNameErr.required = response.data.message;
        setUserNameErr(userNameErr);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <main>
        <form action="" onSubmit={studentSignUpForm} autoComplete="off">
          <section className="bg-flexible">
            <HomeMenuOuter />
            <div class="data-adjust">
              <div className="container-fluid cf-set">
                <div className="row align-items-center justify-content-center onboarding-set">
                  <div className="col-lg-7">
                    <div className="row">
                      <div
                        className="col-lg-12 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        <h3 className="main-headding">साइन अप </h3>

                      </div>

                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >
                        <div className="input-group">
                          <label className="levelset">
                            ईमेल या मोबाईल <span className="error">*</span>
                          </label>
                          <div className="input right">
                            <input
                              type="text"
                              className="set-input"
                              value={mobEmail}
                              // onChange={(e)=>setMobEmail(e.target.value)}
                              onChange={checkEmailMob}
                              placeholder="example@xyz.com"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        {Object.keys(mobEmailErr).map((key) => {
                          return (
                            <span className="error">{mobEmailErr[key]}</span>
                          );
                        })}
                      </div>


                      {/* <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">

                               <div className="input-group" >
                                   <label className="levelset">पूरा नाम </label>
                                   <div className="input right blue-shodow-input">
                                       <input type="text" className="set-input" placeholder="नताशा" />
                                   </div>
                               </div>

                           </div> */}

                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.6s"
                      >
                        <div className="input-group">
                          <label className="levelset">
                            पासवर्ड <span className="error">*</span>
                          </label>
                          <div className="input right pink-shodow-input">
                            <input
                              type={!isVisible ? "password" : "text"}
                              className="set-input"
                              placeholder="***********"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              autoComplete="new-password"
                            />

                            <span
                              onClick={toggle}
                              style={{
                                "margin-right": "5px",
                                "margin-top": "10px",
                              }}
                            >
                              {isVisible ? (
                                <i className="fa fa-eye fa-2x text-black"></i>
                              ) : (
                                <i className="fa fa-eye-slash fa-2x text-black"></i>
                              )}
                            </span>
                          </div>
                        </div>

                        {Object.keys(passwordErr).map((key) => {
                          return (
                            <span className="error">{passwordErr[key]}</span>
                          );
                        })}
                      </div>

                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.7s"
                      >
                        <div className="input-box ">
                          <label className="levelset">
                            लिंग <span className="error">*</span>
                          </label>
                          <br></br>
                          <div className="radiobuttons d-flex ml-15 mt-15">
                            <div className="rdio rdio-primary radio-inline">
                              <input
                                name="radio"
                                value="male"
                                id="radio1"
                                type="radio"
                                onChange={(e) => setGender(e.target.value)}
                              />
                              <label for="radio1">पुरुष</label>
                            </div>
                            <div className="rdio rdio-primary radio-inline">
                              <input
                                name="radio"
                                value="female"
                                id="radio2"
                                type="radio"
                                onChange={(e) => setGender(e.target.value)}
                              />
                              <label for="radio2">महिला</label>
                            </div>
                            <div className="rdio rdio-primary radio-inline">
                              <input
                                name="radio"
                                value="other"
                                id="radio3"
                                type="radio"
                                onChange={(e) => setGender(e.target.value)}
                              />
                              <label for="radio3">अन्य</label>
                            </div>
                          </div>
                        </div>

                        {Object.keys(genderErr).map((key) => {
                          return (
                            <span className="error">{genderErr[key]}</span>
                          );
                        })}
                      </div>

                      <p>
                        अपना ईमेल या मोबाइल नंबर और एक पासवर्ड लिखें।  अपने पासवर्ड को याद रखें क्योंकि हर बार लॉगिन करने के लिए इसी की आवश्यकता होगी। लिंग चुन कर  नीचे दिए गए नियम व शर्ते (टर्म्स एन्ड कंडीशंस) और गोपनीयता नीति (प्राइवेसी पॉलिसी) को ध्यान पूर्वक पढ़ें, और यदि आप इन नियम व शर्तों और गोपनीयता नीति से   सहमत हैं तो नीचे दिए गए चेक बॉक्स पर क्लिक करें और अंत में साइन अप बटन पर क्लिक करें।
                        </p>
                        <p>इस कोर्स को पूरा करने पर आपको एक सर्टिफिकेट मिलेगा जिसे आप डाउनलोड कर सकते हैं।</p>


                        <NavLink to="/terms-and-conditions" style={{"color":"blue"}}>Terms & Conditions </NavLink>&nbsp;&nbsp;
                             <NavLink to="/privacy-policy" style={{"color":"blue"}}>Privacy Policy </NavLink>
                        <br></br>


                      <div class="custom-control custom-checkbox">



                        <input type="checkbox" onClick={(e)=>{setPolicy(e.target.checked)}}  name="signed"  class="custom-control-input" id="customCheck" />

                        <label class="custom-control-label" for="customCheck">मैं सहमत हूं </label>

                        {Object.keys(policyErr).map((key) => {
                        return <span className="error">{policyErr[key]}</span>;
                      })}
                    </div>


                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.8s"
                      >
                        <div
                          className="mt-15 ml-20 wow fadeInDown"
                          data-wow-duration="1s"
                          data-wow-delay="0.7s"
                        >
                          {/* <a href="on-boarding-screen-8-sign-up.html" className="btnset"><span>साइन अप  </span> </a> */}
                          <button type="submit" disabled={btnDisabled} className="btnset mt-m-2">
                            <span>साइन अप </span>
                          </button>
                        </div>
                      </div>

                      {/* <div
                      className="col-lg-12 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.8s"
                    >
                      <div className="mt-30 ml-20">
                        <NavLink
                          to="#"
                          className="btnset googlebtn"
                          onClick={signInWithGoogle}
                        >
                          <img
                            width="8%"
                            src="assets/img/icon/google-icon.svg"
                            alt=""
                          />{" "}
                          &nbsp; गूगल से आगे बढ़ें
                        </NavLink>


                      </div>
                    </div> */}
                    </div>
                  </div>

                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <center>
                      <img
                        draggable="false"
                        width="78%"
                        src="assets/img/general/stand-pinky.svg"
                      />
                    </center>
                  </div>
                </div>
                <FlashMessage type={type} message={message} />
              </div>
            </div>
          </section>
        </form>
      </main>
    </>
  );
};

export default StudentSignUp;
