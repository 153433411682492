import React from 'react'
import { NavLink } from 'react-router-dom'

const Kyakrna03ActivityScreen = () => {
  return (
    <>
          <main>

        <section className="bg-boarding bg-flexible pt-m-2 karo">
           <div className="container-fluid cf-set">

               <div className="row align-items-center justify-content-center ">
                   <div className="col col-lg-7 ">
                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />क्या करना है ?</h2>
                   </div>
                   <div className="col col-lg-5">
                       <NavLink className="float-right width-auto" to="/karo-03-activity-03-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div>
               </div>


               <div className="row align-items-center justify-content-between mt-30">
                   <div className="col-lg-7">
                       <h2 className="h2 h2-en mt-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">साफ़ सुंदर स्वच्छ</h2>




                       <p className="mpara large-para fs-30 mt-10 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">इस एक्टिविटी को करने के लिए आप अपने घर से शुरुआत करेंगे। अपनी अलमारी, या पढ़ने वाले टेबल या फिर अपने कमरे को ध्यान से देखिए और क्या आपका सामान फैला हुआ है और चीज़ें अपनी जगह पर नहीं रखीं हैं?  </p>



                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d purple-shadow" to="/karo-03-activity-05-screen">शुरू करें</NavLink>
                       </div>
                   </div>




                   <div className="col-lg-5">

                       <div className="six-tare">
                           <p>जब आप दोनों फोटो अपलोड कर देंगे तो आपको 10 तारे मिलेंगे। </p>

                           <ul className="ul-r align-left">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt=""/></li>
                               <li><h4>X 10</h4></li>
                           </ul>
                       </div>
                   </div>
               </div>
           </div>
        </section>



   </main>


    </>
  )
}

export default Kyakrna03ActivityScreen