import React, { useState, useEffect } from "react";
import { NavLink, useLocation,useNavigate } from "react-router-dom";
import { HOMEROUTE } from "../Constants";
import BaseURL from "./Baseurl";
import axios from "axios";

const ProfileMenu = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [avtar, setAvtar] = useState();
  const user_details = JSON.parse(localStorage.getItem("user_details"));


  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_details.user_id,
      url: location.pathname,
    });

    var config = {
      method: "post",
      url: BaseURL + "/resumePages",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  if (user_details === null) {
    return navigate('/')

  }

  if(user_details === null)
  {

      return navigate("/");
  }
  useEffect(() => {
    getAlerts()

    // setInterval(() => getAlerts(), 10000)
}, [])

const getAlerts= ()=>{
  var data = JSON.stringify({
    token:
    `${user_details.token}`,
  });

  var config = {
    method: "post",
    url: BaseURL+"/get_user",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {



      if(response.status === "Token is Expired")
      {
        window.localStorage.clear();
        navigate('/welcome')
      }

      if(response.data.message === "Token Invalid")
      {
        window.localStorage.clear();
        navigate('/welcome')
      }


        if(response.data === '' || response.data === null)
        {
          window.localStorage.clear();
          navigate('/welcome')
        }
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log(error);
    });
}


  useEffect(() => {
    if (user_details) {
      setName(user_details.logUsername);
    }

    var data = JSON.stringify({
      user_id: user_details.user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/getAvtarByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setAvtar(response.data.data.avtar);
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const HomeIcon = () => {
    if (
      location.pathname === "/general-screen" ||
      location.pathname === "/general-screen-result"
    ) {
      return (
        <>
          <NavLink to={HOMEROUTE} className="width-auto help" title="Main Page">
            <img
              className="home-icon"
              src="assets/img/icon/home-white.png"
              alt=""
            />
            {/* <span className="tooltiptext">Main Page</span> */}
          </NavLink>
        </>
      );
    } else {
      return (
        <>
          <NavLink to={HOMEROUTE} className="width-auto help" title="Main Page">
            <img className="home-icon" src="assets/img/icon/home.png" alt="" />
            {/* <span className="tooltiptext">Main Page</span> */}
          </NavLink>
        </>
      );
    }
  };

  const goToFaq = ()=>{
    const pathdata = location.pathname
    localStorage.setItem('previous_link',JSON.stringify(pathdata));
    navigate("/faq2")

  }


  const FaqIcon = () => {
    if (
      location.pathname === "/general-screen" ||
      location.pathname === "/general-screen-result"
    ) {
      return (
        <>
          <li>
            {/* <NavLink to="/faq2" className="tooltip">
              <img src="assets/img/icon/faq-white.svg" />
              <span className="tooltiptext">Faq</span>
            </NavLink> */}

            <button onClick={goToFaq} className="help" title="Faq">
              <img src="assets/img/icon/faq-white.svg" />
              {/* <span className="tooltiptext">Faq</span> */}
            </button>


          </li>
        </>
      );
    } else {
      return (
        <>
          <li>
            {/* <NavLink to="/faq2" className="tooltip">
              <img src="assets/img/icon/ques.svg" />{" "}
              <span className="tooltiptext">Faq</span>
            </NavLink> */}

            <button onClick={goToFaq} className="help" title="Faq" >
            <img src="assets/img/icon/ques.svg" />{" "}
              {/* <span className="tooltiptext">Faq</span> */}
            </button>
          </li>
        </>
      );
    }
  };


  const ProfileRedirect = ()=>{
   if((location.pathname === "/group-list") || (location.pathname === "/add-group"))
    {
      return(
        <>
            <NavLink to="#">
            <div className="anu">
              <img className="anu-img" src={avtar} />
              <p className="anu-para">{name}</p>

            </div>
          </NavLink>
        </>
      );
    }else {
      return(
        <>
            <NavLink to="/profile">
            <div className="anu">
              <img className="anu-img" src={avtar} />
              <p className="anu-para">{name}</p>

            </div>
          </NavLink>
        </>
      )
    }
  }


  return (
    <>

<div className="fixed-header">
  <div className="container-fluid cf-set">
    <div className="d-flex flex-column flex-md-row align-items-center pt-30">
        <div className="my-0 mr-md-auto font-weight-norma d-flex my-profile">
    {( (location.pathname !== "/group-list") && (location.pathname !== "/add-group") )?<HomeIcon/>:""}

        <ProfileRedirect/>
        </div>



      <nav className="my-2 my-md-0">
        <ul className="list-navi float-right">
        <FaqIcon />
          {/* <li>
            <NavLink to="#" title="Book">
              <img src="assets/img/icon/book.svg" />
            </NavLink>
          </li>
          <li>
            <NavLink to="#" title="Settings">
              <img src="assets/img/icon/settings.svg" />
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </div>
  </div>
</div>


    </>
  );
};

export default ProfileMenu;
