import React, { useEffect, useState } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Khelo04Activity11Screen = () => {

  const navigate = useNavigate();
  const [totalStar, setTotalStar] = useState(0);

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  useEffect(() => {
    const data = JSON.stringify({
      user_id: user_id,
      "activity_type": "khelo",
      "activity_no": "4"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setTotalStar(response.data.data.total_stars);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    facilatorUrl();

    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-04-activity-01-screen');
      }
    })
  }



  const retryActivityApi = () => {

    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "khelo",
      "activity_key_no": "khelo04",
      "activity_no": "4"
    });

    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        "type":`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data,
    };


    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }


  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = ()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    axios(config)
      .then(function (response112) {

        // let sum  = parseInt(kheloIndex) + parseInt(1);
        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{

    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      // localStorage.setItem('khelo', sum);
      // navigate(kheloFacilatorUrl[sum].url);

      if(kheloFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('khelo', sum);

        navigate(kheloFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    } else {
      navigate('/khelo-05-activity-01-screen')
    }
  }



  return (
    <>
      <main>
        <section className="bg-flexible F8F1FD">
          <div className="container">
            <Name />
            <div className="row align-items-center justify-content-center ">
              <div className="col-lg-4 mt-m-1">
                <div
                  className="six-tare  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="rh1 vw-2-5">सभी स्तर पूरे हो गए! </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{totalStar}</h4>
                    </li>
                  </ul>
                </div>


                <div className="d-flex justify-content-center mt-30 gkw-img">
                  <div className="mr-20">
                    <div className=" d-flex">



                      <NavLink onClick={retryActivity} to="#">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">दुबारा कोशिश करें</p>
                      </NavLink>


                    </div>
                  </div>


                  <div >
                    <div className="">
                      <button onClick={nextUrlLink} className="btnset "><span>अगली एक्टिविटी </span> </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    draggable="false"
                    className="mt-50"
                    width="92%"
                    src="assets/img/khelo/kkm-main.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div className="row align-items-center justify-content-center mt-20">
                  <div
                    className="col-lg-6 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >

                    <p className="mpara large-para fs-30 mt-40">
                    क्या आप भी हैरान हैं कि एक लड़के और लड़की में समान ताकत भी हो सकती है | ये लोग वास्तविक जीवन के हीरोज़ हैं | यह ज़रूरी है कि  हम किसी जेंडर को छोटा या कमज़ोर न मानें | लड़का और लड़की दोनों बराबर हैं जैसा आपने एक्टिविटी में देखा की लड़कियाँ  भी खेलों में इनाम लाती हैं और देश का नाम रोशन करती हैं शायद आपको पता होगा कि हमारे देश में 1976 से भारतीय महिला क्रिकेट टीम भी है और दूसरे  खेलों में भी महिलाएं परस्पर भाग ले रही हैं और मैडल ला रही हैं | हमारे पास दिव्यांग महिला और पुरुषों की स्पोर्ट टीम भी है जो 1968 से पैरालंपिक में भारत का नाम रोशन कर रही हैं आप गूगल पर इनके बारे में और जानकारी ले सकते हैं
                    </p>

                  </div>
                  <div
                    className="col-lg-4 offset-lg-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink to="">
                      <center>
                        <img
                          draggable="false"
                          className="mt-50"
                          width="95%"
                          src="assets/img/khelo/kkm-main.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo04Activity11Screen;
