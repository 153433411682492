import React, { useEffect, useState } from "react";
import { useNavigate, useHistory, NavLink } from "react-router-dom";
import FlashMessage from "../FlashMessage";
import HomeMenu from "../common/HomeMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HomeMenuOuter from "../common/HomeMenuOuter";

const FacilatorSignup = () => {
  const [lg, setLg] = useState("col-lg-5");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [dob, setDob] = useState();
  // const [date, setDate] = useState(new Date());
  const handleChange = (dob) => setDob(dob);





  const auth = localStorage.getItem("user_details");

  useEffect(()=>{
    if(auth)
    {
      navigate('/board-screen');
    }

  })

  const navigate = useNavigate();
  const [fullName, setFullName] = useState();

  //for show message
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  // for error state
  const [fullNameErr, setFullNameErr] = useState({});
  const [dobErr, setDobErr] = useState({});

  const getAge = (e) => {
    e.preventDefault();
    const isValid = formValidation();

    // console.log(dob);
    // return false;
    const __dob = dob.split("-");

    const udate = __dob[0];
    const umonth = __dob[1];
    const uyear = __dob[2];



    var today = new Date();
    var birthDate = new Date(dob);



    function getAge(dob) {
      var today = new Date();

      // var today = '2023-11-10';
      var birthDate = new Date(dob);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
  }

  var age_now = getAge(dob);




    if(age_now >= 18)
    {
        const studentDetails = { fullname: fullName, dob: dob, registration_type:'facilator' };
        localStorage.setItem("student-details", JSON.stringify(studentDetails));

        navigate("/student-signup");
    }else{
        setMessage("आपकी आयु 18 वर्ष के बराबर या अधिक होनी चाहिए");

      setType("danger");
    }
  };

  // form validation
  const formValidation = () => {
    const fullNameErr = {};
    const dobErr = {};

    const fullName1 = fullName === undefined ? "" : fullName;
    const dob1 = dob === undefined ? "" : dob;

    let isValid = true;

    //username only alpa validation



    // console.log(mobEmail.length);


    if (dob1.length === 0) {
      dobErr.required = "जन्मतिथि आवश्यक है";
      isValid = false;
    }

    // setFullNameErr(fullNameErr);
    setDobErr(dobErr);

    return isValid;
  };

  const validateDob = (e)=>{
    let datVal = e.target.value;
    datVal = datVal.split('-');
    if(datVal[0].length > 4)
    {
      setDobErr('Invalid Date Format');
      return false;
    }else{
      setDobErr('');
      setDob(e.target.value)
    }
    // console.log(datVal[0]);
  }
  return (
    <>
      <main>
        <form action="" onSubmit={getAge}>
          <section className="bg-flexible">

              <HomeMenuOuter  />
            <div class="data-adjust">
            <div className="container-fluid cf-set">

              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                  <FlashMessage type={type} message={message} />
                  <h3
                    className="main-headding wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    साइन अप{" "}
                  </h3>

                  <div
                    className="input-group wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <label className="levelset ">अपना नाम लिखें </label>
                    <div className="input right">
                      <input
                        type="text"
                        className="set-input"
                        value={fullName}
                        placeholder="पूरा नाम "
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>

                    {Object.keys(fullNameErr).map((key) => {
                      return <span className="error">{fullNameErr[key]}</span>;
                    })}
                  </div>

                  <div
                    className="input-group wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <label className="levelset ">आपकी जन्म तिथि क्या है? <span className="error">*</span></label>
                    <div className="input right">
                      <input
                        type="date"
                        className="set-input"
                        value={dob}
                        placeholder="DD-MM-YYYY"
                        onChange={validateDob}
                        required
                      />
                    </div>

                    {Object.keys(dobErr).map((key) => {
                      return <span className="error">{dobErr[key]}</span>;
                    })}
                  </div>



 <p
                    className="mpara mt-15 ml-10 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                      साइन अप करने के लिए ऊपर दिए गए फॉर्म में अपना नाम, जन्म तिथि लिखिए। फिर नीचे दिए गए नियम व शर्ते (टर्म्स एन्ड कंडीशंस) और गोपनीयता नीति (प्राइवेसी पॉलिसी) को ध्यान पूर्वक पढ़ें, और यदि आप इन नियम व शर्तों और गोपनीयता नीति से   सहमत हैं तो नीचे दिए गए चेक बॉक्स पर क्लिक करें और आगे बढ़ें।
                  </p>





                  {/* <div
                    className="input-group mt-20 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  > */}
                    {/* <label className="levelset ">आपकी जन्म तिथि क्या है?</label> */}
                    {/* <div className="input right green-shodow-input"> */}
                      {/* <DatePicker
                        className="input right green-shodow-input"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        adjustDateOnChange
                        selected={dob}
                        onChange={handleChange}

                      /> */}

                      {/* <input
                        type="date"
                        className="set-input"
                        value={dob}
                        placeholder="पूरा नाम "
                        onChange={(e) => setDob(e.target.value)}
                      /> */}
                    {/* </div> */}

                    {/* {Object.keys(dobErr).map((key) => {
                      return <span className="error">{dobErr[key]}</span>;
                    })}
                  </div> */}

                  {/* <p
                    className="mpara mt-15 ml-10 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    अगर आप 18 साल से कम के हैं तो आपको अपने पेरेंट्स या गार्डियन
                    की तरफ से वन टाइम ऑथराइजेशन (OTA) चाहिए होगा।{" "}
                  </p> */}

                  <div
                    className="mt-30 mb-15"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {/* <NavLink to="/parent-signup" className="btnset m-auto blue-shadow "><span>आगे बढ़ेंं  </span> </NavLink> */}
                    <button type="submit" className="btnset m-auto blue-shadow">
                      <span>आगे बढ़ेंं </span>
                    </button>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="90%"
                      src="assets/img/general/sign-up.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
            </div>
          </section>
        </form>


      </main>

      <div
        className="button-fixed termsc wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" justify-content-start">

              <ul className="list-style-set list-circle mt-1">
                <li> <NavLink to="/terms-and-conditions">Terms & Conditions </NavLink>	</li>
                <li> <NavLink to="/privacy-policy">Privacy Policy </NavLink>	</li>
              </ul>


              </div>
            </div>
          </div>
        </div>
      </div>




    </>
  );
};

export default FacilatorSignup;
