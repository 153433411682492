import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo05Activity01Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
           <Name/>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="h1 mb-20 mt-30">खेलो </h1>
                  <div
                    className="karo-activity-barabri wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h4>चक्का घुमाओ</h4>
                    <div id="wrapper">
                      <div id="wheel">
                        <div id="inner-wheel">
                          <div className="sec">
                            <span className="fa " />
                          </div>
                          <div className="sec">
                            <span className="fa " />
                          </div>
                          <div className="sec">
                            <span className="fa " />
                          </div>
                          <div className="sec">
                            <span className="fa " />
                          </div>
                          <div className="sec">
                            <span className="fa" />
                          </div>
                          <div className="sec">
                            <span className="fa " />
                          </div>
                        </div>
                        <div id="spin">
                          <div id="inner-spin" />
                        </div>
                        <div id="shine" />
                      </div>
                      <div id="txt" />
                    </div>
                    <div>
                      <NavLink
                        to="/khelo-05-activity-02-screen"
                        className="btnset radius-set m-auto"
                      >
                        <span>चुनें</span>{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="100%"
                      src="assets/img/dekho/cg-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo05Activity01Screen;
