import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo11Activity02Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
          <Name/>
            <div className="container-fluid cf-set">

            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-7">
                  <h1
                    className="h1 mb-20 mt-m-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    क्या यह ठीक है? आओ पता लगाएँ {" "}
                  </h1>
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    इस एक्टिविटी में हमें यह बताना है कि कौन सी बातें हमें सही
                    लगती हैं और कौन सी बातें हमें सही नहीं लगती। हमें अपनी राय
                    देनी है और हम यह सारी बातें अपने दोस्तों और परिवार वालों से
                    भी साझा कर सकते हैं।{" "}
                  </p>
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    राय: राय का मतलब है अपनी सोच जो हम किसी तर्क पर आधारित करते
                    हैं। किसी भी चीज़ का सही या गलत प्रभाव देखते हुए उसके बारे
                    में अपनी राय बनाते हैं।
                  </p>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="/kyakrna-11-khelo-activity" className="btnset ">
                      <span>शुरू करें </span>{" "}
                    </NavLink>
                  </div>
                  <div
                    className="gkw-img d-flex mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >

                    <div className="mr-20">
                      <NavLink to="/kyakrna-11-khelo-activity">
                        <center>
                          <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                        </center>
                        <p className="side-btn">क्या करना है?</p>
                      </NavLink>
                    </div>
                    <div className="">
                      <NavLink to="/khelo-11-activity-01-screen">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">वापस जाएं</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/kythapl-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo11Activity02Screen;
