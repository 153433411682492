import React from 'react'
import { NavLink } from 'react-router-dom'
import BaseURL,{ AdminUrl } from "../common/Baseurl";

const Kyakrna13KheloActivity = () => {

  const videoPause = ()=>{
    var video = document.getElementById("videoPlaydek");
    video.pause();
  }

  return (
    <>





<div className="modal videoModal" id="videoModal" tabIndex={-1} role="dialog">
  <div className="modal-dialog" role="document">
    <div
      className="modal-content"
      style={{ background: "transparent", border: "none" }}
    >
      <div className="modal-body">
        <button
          type="button"
          className="close video-close btnclosemodal"
          data-dismiss="modal"
          aria-label="Close"
          onClick={videoPause}
        >
          <span aria-hidden="true">×</span>
        </button>
        <div className="embed-responsive embed-responsive-16by9">
          {/* <iframe
            src="https://www.youtube.com/watch?v=71lcSnXpQ90"
            id="vimeoIframe"
            className="embed-responsive-item"
            webkitallowfullscreen=""
            mozallowfullscreen=""
            allowFullScreen=""
          /> */}



          <video className="set-video" id="videoPlaydek" muted=""  loop="" controls="true">
                      <source
                       src={AdminUrl + "/khelo13.mp4"}

                        type="video/mp4"
                      />
                    </video>




        </div>
      </div>
    </div>
  </div>
</div>


        <main>
  <section className="bg-flexible khelo-bg">
    <div className="fixed-header">
      <div className="container-fluid cf-set">
        <div className="row align-items-center justify-content-between pt-20">
          <div className="col col-lg-7">
            <h2 className="h2 kkh-text">
              {" "}
              <NavLink to="/board-screen">
              <img
                className="mr-20 kkh-home-img"
                src="assets/img/icon/home.png"
                alt=""
              />
              </NavLink>
             क्या करना है?

            </h2>
          </div>
          <div className="col col-lg-5">
            <NavLink
              className="float-right width-auto"
              to="/khelo-13-activity-02-screen"
            >
              {" "}
              <img
                className="close-icon"
                src="assets/img/icon/close.svg"
                alt=""
              />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid cf-set">
      <div className="row align-items-top justify-content-between">
        <div className="col-lg-7">
          <p
            className="mpara mt-m-1 large-para fs-30 mb-0 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
          >
            सबसे पहले जानते हैं कि सुरक्षित स्पर्श और असुरक्षित स्पर्श क्या होता है।{" "}
          </p>
          <p
            className="mpara large-para fs-30 mb-0 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
          >
           सुरक्षित स्पर्श - यानी वह स्पर्श जोकि हमें ख़ुशी देता है और हमें अच्छा महसूस करता है।

          </p>
          <p
            className="mpara large-para fs-30 mb-0 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
          >
           असुरक्षित स्पर्श - ऐसा स्पर्श जिससे हमें बुरा और असुरक्षित महसूस हो, डर लगे और पसंद न आए।


          </p>
          <p
            className="mpara large-para fs-30 mb-0 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
          >
            इनके बारे में और जानकारी के लिए नीचे दिया गया वीडियो भी देख सकतें हैं
          </p>
          <div
            className="mt-15 mb-15 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <NavLink
              className="theme_btn theme_btn_bg chnage-d shadow-blue"
              to="#"
              data-toggle="modal"
              data-target="#videoModal"
            >
              वीडियो देखें{" "}
            </NavLink>
          </div>
          <p
            className="mpara large-para fs-30 mb-0 red wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
          >
            इस एक्टिविटी में 2 राउंड होंगे{" "}
          </p>
          <p
            className=" large-para fs-30 mb-0  wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
          >
            <span className="red"> राउंड 1</span> आपको एक मानव शरीर का एक चित्र दिखाया जायेगा, आपको इस चित्र में चिन्ह लगा कर बताना होगा कि कहाँ किसी और का स्पर्श सही लग सकता है और कहाँ नहीं ?
          </p>
          <div className="mt-15 mb-15">
            <NavLink to="/khelo-13-activity-03-screen" className="btnset ">
              <span>शुरू करें </span>{" "}
            </NavLink>
          </div>
        </div>
        <div
          className="col-lg-5 wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <p
            className=" large-para fs-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
          >
            <span className="red"> राउंड 2</span> - आपको कुछ सवालों के जवाब देने
            होंगे
          </p>
          <div className="six-tare">
            <p className="mpara large-para fs-30 mb-0 ">
            इस एक्टिविटी को पूरा करने पर आपको 10 तारे मिलेंगे।

            </p>
            <p>
            इस एक्टिविटी में कुछ सही या गलत नहीं है
            </p>
            <ul className="ul-r align-left">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>X 10</h4>
              </li>
            </ul>
          </div>
          <center>
            <img
              className="mt-30"
              width="18%"
              src="assets/img/khelo/humanbody.svg"
              alt=""
            />
          </center>
        </div>
      </div>
    </div>
  </section>
</main>

    </>
  )
}

export default Kyakrna13KheloActivity