import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FlashMessage from "../FlashMessage";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const ForgotUsernameOtp = () => {
  const forgetdetails = JSON.parse(
    localStorage.getItem("forgot_password_details")
  );
  const navigate = useNavigate();
  const [inputValue, setIputValue] = useState();
  const [inputValueErr, setinputValueErr] = useState({});

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  console.log(forgetdetails);
  const matchOtp = (e) => {
    e.preventDefault();
    const isValid = formValidation();

    const data = JSON.stringify({
      message_id: forgetdetails.message_id,
      otp: inputValue,
    });

    var config = {
      method: "post",
      url: BaseURL+"/forgotPassword_OTP_Match_userid",
      headers: {
        "Content-Type": "application/json",

         "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTIONS"
      },
      data: data,
    };

    axios(config)
      .then(function (response) {


          if(response.data.success === true)
          {
            setMessage(response.data.message);
            // setMessage('UserId Sent To Your Registered Email Id Or Phone Number');
            setType("success");

            // navigate('/reset-username');

          }else{
            setMessage(response.data.message);
            setType("danger");
          }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   // form validation
  const formValidation = () => {
    const inputvalueErr = {};

    const inputValue1 = inputValue === undefined ? "" : inputValue;

    let isValid = true;

    // console.log(mobEmail.length);
    if (inputValue1.length === 0) {
      inputvalueErr.required = "ओटीपी की आवश्यकता है";
      isValid = false;
    }

    setinputValueErr(inputvalueErr);

    return isValid;
  };

  //   console.log(forgetdetails.message);

  return (
    <>
      <form method="" onSubmit={matchOtp}>
        <main>
          <section className="bg-flexible">

          <HomeMenuOuter  />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              {/* <FlashMessage type={type} message={message} /> */}
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                <FlashMessage type={type} message={message} />
                  <h3
                    className="main-headding wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    ओ.टी.पी.
                  </h3>

                  <div
                    className="input-group wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    {/* <FlashMessage type="success" message={forgetdetails.message} /> */}
                    <label
                      className="levelset "
                      style={{ "line-height": "2vw" }}
                    >
                      आपके ई-मेल/मोबाईल नंबर पर ओ.टी.पी. भेजा गया है | निचे दिए
                      गए कॉलम में ओ.टी.पी. डालें <span className="error">*</span>
                    </label>
                    <div className="input right">
                      <input
                        type="text"
                        className="set-input"
                        placeholder=" "
                        value={inputValue}
                        onChange={(e) => {
                          setIputValue(e.target.value);
                        }}
                      />
                    </div>

                    {Object.keys(inputValueErr).map((key) => {
                      return (
                        <span className="error">{inputValueErr[key]}</span>
                      );
                    })}
                  </div>

                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <button type="submit" className="btnset blue-shadow ">
                      {" "}
                      <span>आगे बढ़ेंं </span>
                    </button>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mt-50"
                      width="95%"
                      src="assets/img/general/sign-up2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};

export default ForgotUsernameOtp;
