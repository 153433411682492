import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna10kheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20">
                <div className="col-lg-6 ">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    कैसे खेलें ?
                  </h2>
                </div>
                <div className="col-lg-6">
                  <NavLink
                    className="float-right width-auto"
                    to="/khelo-10-activity-02-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                   दिए गए विकल्पों में से सही विकल्पों पर क्लिक करें ! <br></br> हम देखेंगे कि हम किस से और किस बारे में बात करने से ज़्यादा डरते हैं।


                  </p>
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                  आज हम कोशिश और अभ्यास करेंगे कि इन लोगों से कैसे बात करें और डरें नहीं। किसी से भी बात करते समय सम्मान दिखाएँ, इससे वे भी हमारा सम्मान करेंगें और जानेंगे कि हमारा व्यक्तिव किस प्रकार का है ।


                  </p>

                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                 इससे वे भी हमारा सम्मान करेंगे और जानेंगे कि हमारा भी अपना व्यक्तित्व है।

                  </p>

                  <div
                    className="mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d"
                      to="/khelo-10-activity-03-screen"
                    >
                     शुरू करें
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-4 offset-lg-1">
                  <div
                    className="six-tare wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p>आपको पूरी एक्टिविटी करने पर 10 तारे मिलेंगे।</p>
                    <ul className="ul-r align-left">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>X 10</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna10kheloActivity;
