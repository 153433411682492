import React from 'react'
import { NavLink } from 'react-router-dom'

const Kyakrna04ActivityScreen = () => {
  return (
    <>
          <main>

        <section className="bg-boarding bg-flexible pt-m-2 karo">
           <div className="container-fluid cf-set">


               <div className="row align-items-center justify-content-center ">
                   <div className="col col-lg-6">
                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />क्या करना है ?</h2>
                   </div>
                   <div className="col col-lg-5">
                       <NavLink className="float-right width-auto" to="/karo-04-activity-03-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div>
               </div>


               <div className="row align-items-center justify-content-center mt-10">
                   <div className="col-lg-6">
                       <p className="mpara large-para fs-30 mt-40 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">आज हम यह जानेंगे की आपकी रूचि सबसे ज़्यादा किसमें है और आपको क्या करना सबसे ज़्यादा पसंद है </p>
                       <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">दी गई लिस्ट में से आपको चुननी होंगी अपनी मनपसंद एक्टिविटीज़, आप एक से ज़्यादा भी चुन सकते हैं  </p>

                       <div className="six-tare wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <p>इस एक्टिविटी को पूरा करने पर आपको 10 तारे मिलेंगे। </p>
                           <div>
                           <ul className="ul-r align-left">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt=""/></li>
                               <li><h4>X 10</h4></li>
                           </ul>
                           </div>
                       </div>

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d" to="/karo-04-activity-04-screen">शुरू करें</NavLink>
                       </div>





                   </div>




                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <center><img draggable="false" className="mb-15" width="100%" src="assets/img/general/shaki-ke-liye-jaldi-kya.svg" /></center>
                   </div>
               </div>
           </div>
        </section>



   </main>


    </>
  )
}

export default Kyakrna04ActivityScreen