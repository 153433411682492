import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna04KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-boarding bg-flexible khelo-bg pt-30">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col col-lg-7 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  कैसे खेलें ?{" "}
                </h2>
              </div>
              <div className="col col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/khelo-04-activity-02-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-30">
              <div className="col-lg-6">
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {" "}
                  अनुमान लगाओ। आओ जाने प्रमुख खिलाड़ियों के बारे में।
                </p>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  क्या आप जानते हैं इस खिलाड़ी को? आपको एक तस्वीर दिखाई जाएगी।
                  आपको 5 विकल्प दिए जाएँगें । अनुमान लगाना है कि वह कौन है ।{" "}


                </p>
                <div className="row">
                  <div className="col-lg-7">
                    <div className="question-box">
                      <center>
                        <img src="assets/img/khelo/question.svg" alt="" />
                      </center>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <ul>
                      <li>
                        <button className="option-btn">विकल्प 1</button>
                      </li>
                      <li>
                        <button className="option-btn">विकल्प 2</button>
                      </li>
                      <li>
                        <button className="option-btn">विकल्प 3</button>
                      </li>
                      <li>
                        <button className="option-btn">विकल्प 4</button>
                      </li>
                      <li>
                        <button className="option-btn">विकल्प 5</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <div
                  className="six-tare wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p>
                    यदि आप अटक गए तो आप 3 हिंट का इस्तेमाल कर सकते हैं। हर स्तर
                    में आपको केवल 3 हिंट ही मिलेंगे
                  </p>
                  <p>
                    हर सही जवाब पर आपको 2 तारे मिलेंगे और एक्टिविटी को पूरा करने
                    पर आपको 4 तारे मिलेंगे।{" "}
                  </p>
                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 4</h4>
                    </li>
                  </ul>
                </div>
                <div
                  className="mt-15 mt-m-2 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/khelo-04-activity-03-screen" className="btnset ">
                    <span>शुरू करें </span>{" "}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna04KheloActivity;
