import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";


import BaseURL, { AdminUrl } from "../common/Baseurl";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";


import ProfileMenu from "../common/ProfileMenu";
import Swal from "sweetalert2";




const Suno02Activity03Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-6");
  const [counter, setCounter] = useState(0);
  const [checkBlankData, setBlankData] = useState("blank");
  const [sunoFacilatorUrl, setSunoFacilatorUrl] = useState([]);
  const sunoIndex = localStorage.getItem('suno');

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {
    setTimeout(() => setCounter(counter + 1), 1000);
  }, [counter]);

  const submitData = () => {

    var data = JSON.stringify({
      "user_id": user_id,
      "answers": {
        "ques1": {
          "ques_id": "1",
          "duration": counter
        }
      }
    });

    var config = {
      method: 'post',
      url: BaseURL + '/sunoActivity02_1',
      headers: {
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {


        let sum = parseInt(sunoIndex) + parseInt(1);
        if (userType == 'group') {
          localStorage.setItem('suno', sum);
          navigate(sunoFacilatorUrl[sum].url);
        } else {
          navigate('/suno-03-activity-01-screen');
        }


      })
      .catch(function (error) {
        console.log(error);
      });


    // /suno-03-activity-01-screen
  };



  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      setBlankData('filled')
      // navigate("/gyanbox-05-activity-screen");
    }, 46000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

    // retry

    const retryActivity = ()=>{
      Swal.fire({
        title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
        text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "हाँ, इसे हटा दें!"
      }).then((result) => {
        if (result.isConfirmed) {
          retryActivityApi();
          navigate('/suno-02-activity-01-screen');
        }
      })
    }



    const retryActivityApi = ()=>{

          let data = JSON.stringify({
            "user_id": user_id,
            "activity_type": "suno",
            "activity_key_no": "suno02",
            "activity_no": "2"
          });

        const config = {
            method: "POST",
            url: BaseURL + "/retryActivity",
            headers: {
              'Authorization': `Bearer ${user_details.token} `,
              'type': `${userType} `,
              'Content-Type': 'application/json'
            },
            data: data,
          };


        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

    }


    var data1 = JSON.stringify({
      user_id: user_id,
    });

    const facilatorUrl = ()=>{
      var config = {
        method: "post",
        url: BaseURL+"/sunoFetchAllActivity_web",
        headers: {
          'Authorization': `Bearer ${user_details.token} `,
          'type':`${userType}`,
          'Content-Type': 'application/json'
        },
        data: data1,
      };

      axios(config)
        .then(function (response112) {

          if(userType == 'group')
          {
            setSunoFacilatorUrl(response112.data.facilator_permission)
          }


          // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
        })
        .catch(function (error) {
          console.log(error);
        });
    }






  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div
             className="mr-20"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
          >
             <NavLink to="#" className="btnset "  onClick={submitData}>
                  <span>आगे</span>{" "}
                </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
           className="mr-20"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
          >
            <button class="btnset m-auto disabled-btn" type="button" disabled>
            आगे
            </button>
          </div>
        </>
      );
    }
  };



  return (
    <>
      <main>



        <div className="bg-flexible video-nav">
          <ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />




          <div className="btn-fixed-video">
            <div className="gkw-img mt-10 d-flex video-para">
              <div className="mr-20">


                <NavLink onClick={retryActivity} to="#">
                <center>
                  <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                </center>
                <p className="side-btn">दुबारा कोशिश   करें</p>
              </NavLink>

              </div>
              <div className="mr-20">
                {/* <NavLink to="#" className="btnset "  onClick={submitData}>
                  <span>आगे</span>{" "}
                </NavLink> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>


          <div className="wrapper">

          <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-9">
                    <video className="set-video" muted="" autoPlay="true" loop="" controls="true">
                      <source
                        src={AdminUrl + "sunovideo.mp4"}
                        type="video/mp4"
                      />
                    </video>
                </div>
              </div>
            </div>






          </div>
        </div>




        {/* <div class="footer-back">
    <div class="container-fluid cf-set">
        <div class="row align-items-center justify-content-between ">
            <div class="col-lg-4 d-flex">

            </div>
        </div>
    </div>
</div> */}

      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">


                <div className="row align-items-center justify-content-between mt-50">
                  <div
                    className="col-lg-8 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p className="mpara large-para fs-30 ">
                      कोई हम पर हावी हो जाए यह हमें बिल्कुल पसंद नहीं आता, है न? बिलकुल, और आए
                      भी क्यों? यह  तो ज़बरदस्ती वाली बात हो गई। कई जगह पे दृढ़ता ज़रूरी है, खास
                      कर वहां जहां लोग हमें वो करने के लिए कहते हैं जो कि हमारा दिल नहीं मानता।
                      कभी-कभी बातचीत करने से दूसरे व्यक्ति को आपके दृष्टिकोण को समझने में भी मदद
                      मिलती है। अगर आपने अपने किसी सपने को पूरा करने का दृढ़ निश्चय किया है तो
                      आपको आगे बढ़कर उसे पूरा करने की कोशिश करनी चाहिए । जिसके लिए निरंतर बातचीत
                      करने की प्रक्रिया जारी रखना जरुरी है।
                    </p>

                  </div>
                  <div
                    className="col-lg-4"
                  >
                    <NavLink to="#">
                      <center>
                        <img
                          draggable="false"
                          className="mb-15"
                          width="100%"
                          src="assets/img/suno/kabhi-han-kabhi-na.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>



              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>


    </>
  );
};

export default Suno02Activity03Screen;
