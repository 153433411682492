import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";

const Khelo03Activity02Screen = () => {

  const [secondLevel,setSecondLevel] = useState(false);
  const user_details = JSON.parse(localStorage.getItem('user_details'));

    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center mt-10"
    );
    const [faqsize, setfaqsize] = useState("col-lg-6");


    useEffect(() => {
      var data = JSON.stringify({
        user_id: user_details.user_id,
        tablename:"khelo_03_activities_user_ans",
        ques_id: "2",
      });

      var config = {
        method: "post",
        url: BaseURL + "/getActivityByQues",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.success)
          if (response.data.success === true) {

            setSecondLevel(true);


          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);



    const SecondLevelComp = ()=>{
      if(secondLevel == false)
      {
        return(
          <>
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/star-two.svg" />
                </label>
              </li>
          </>
        )
      }else{

        return(
          <>
          <NavLink to="/khelo-03-activity-06-screen">
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/Group2.png" />
                </label>
              </li>
              </NavLink>
          </>
        )


      }
    }



  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />

          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between">
              <div className="col-lg-8">
                <h1
                  className="h1 mb-20 mt-30 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  आज मैं ऊपर
                </h1>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  अपने सबसे पसंदीदा जानवर के बारे में सोचो कि वह क्या-क्या कर
                  सकता है और उसकी खूबी क्या है। जैसे चीता सबसे तेज़ दौड़ता है,
                  जिराफ़ की गर्दन सबसे लंबी होती है ताकि वह आसानी से पेड़ों से पत्ते
                  तोड़ पाए। इन जानवरों की तरह ही हम सब में भी अपनी अलग खूबी होती
                  है। इस एक्टिविटी में अपनी सबसे ख़ास खूबी के बारे में सोचो,
                  जिसमें आप सबसे अच्छे हो। आप कैसे उस खूबी से लोगों की मदद करते
                  हो और आप बड़े हो कर क्या बनना चाहते हो? आइए साथ मिल कर अपनी
                  खूबियों और सपनों के बारे में जानें।{" "}
                </p>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink to="/kyakrna-03-khelo-activity-screen" className="btnset ">
                    <span>शुरू करें </span>{" "}
                  </NavLink>
                </div>
                <div
                  className="gkw-img d-flex mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  {/* <div class="mr-20">
                          <a href="khkn-gyaan-box.htmll">
                              <center><img src="assets/img/icon/gyan-box.svg" alt=""></center>
                              <p class="side-btn">ज्ञान बॉक्स</p>
                          </a>
                      </div> */}
                  <div className="mr-20">
                    <NavLink to="/kyakrna-03-khelo-activity-screen">
                      <center>
                        <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                      </center>
                      <p className="side-btn">क्या करना है?</p>
                    </NavLink>
                  </div>
                  <div className="">
                    <NavLink to="/khelo-03-activity-01-screen">
                      <center>
                        <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                      </center>
                      <p className="side-btn">वापस जाएं</p>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h1
                  className="h1 mb-20 mt-30 mt-m-1 ml-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  स्तर चुनें{" "}
                </h1>
                <ul className="checkbox-effect">
                  <li
                    className="wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <input type="checkbox" id="myCheckbox1" />
                    <label htmlFor="myCheckbox1">
                      <img src="assets/img/khelo/star-one.svg" />
                    </label>
                  </li>

                  <SecondLevelComp />

                </ul>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo03Activity02Screen;
