import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BaseURL from "./Baseurl";
import axios from "axios";
import { HOMEROUTE } from "../Constants";

const BoardName1 = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [avtar, setAvtar] = useState();
  const [totalStars, setTotalStars] = useState();

  // const user_details = JSON.parse(localStorage.getItem("user_details"));

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  if (user_details === null) {
    return navigate('/')

  }

  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_details.user_id,
      url: location.pathname,
    });

    var config = {
      method: "post",
      url: BaseURL + "/resumePages",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);




  useEffect(() => {
    getAlerts()

    // setInterval(() => getAlerts(), 10000)
}, [])

const getAlerts= ()=>{
  var data = JSON.stringify({
    token:
    `${user_details.token}`,
  });

  var config = {
    method: "post",
    url: BaseURL+"/get_user",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {



      if(response.status === "Token is Expired")
      {
        window.localStorage.clear();
        navigate('/welcome')
      }

      if(response.data.message === "Token Invalid")
      {
        window.localStorage.clear();
        navigate('/welcome')
      }


        if(response.data === '' || response.data === null)
        {
          window.localStorage.clear();
          navigate('/welcome')
        }
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log(error);
    });
}



  useEffect(() => {
    if (user_details) {
      setName(user_details.logUsername);
    }

    var data = JSON.stringify({
      user_id: user_details.user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/getAvtarByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setAvtar(response.data.data.avtar);
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const user_details = JSON.parse(localStorage.getItem("user_details"));
    if (user_details) {
      setName(user_details.logUsername);
    }

    var data = JSON.stringify({
      user_id: user_details.user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setTotalStars(response.data.data.total_stars);
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const goToFaq = () => {
    const pathdata = location.pathname;
    localStorage.setItem("previous_link", JSON.stringify(pathdata));
    navigate("/faq2");
  };

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "22vw";
  };

  return (
    <>
      <div className="fixed-header">
        <div className="container-fluid cf-set">
          <div className="d-flex flex-column flex-md-row align-items-center pt-30">



            <div class="my-0 mr-md-auto font-weight-norma d-flex my-profile">
              <div className="d-flex my-profile">
                <NavLink to={HOMEROUTE} className="width-auto">
                  <img
                    className="home-icon"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                </NavLink>
                <NavLink to="/profile">
                  <div className="anu">
                    <img className="anu-img" src={avtar} />
                    <p className="anu-para">{name}</p>
                    <img
                      className="anu-star"
                      src="assets/img/general/star.svg"
                    />
                    <p className="anu-para">{totalStars}</p>
                  </div>
                </NavLink>
              </div>
            </div>
            <nav className="my-2 my-md-0">
              <ul className="list-navi float-right">
                <li>
                <button onClick={goToFaq} className="help" title="Faq">
                    <img src="assets/img/icon/ques.svg" />
                  </button>
                </li>
                {/* <li>
                  <NavLink to="#">
                    <img src="assets/img/icon/book.svg" />
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="#" onClick={openNav} title="Faq">
                    <img src="assets/img/icon/settings.svg" />
                  </NavLink>
                </li>
              </ul>
            </nav>

          </div>
        </div>
      </div>
    </>
  );
};

export default BoardName1;
