import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna07KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20">
                <div className="col-lg-6 ">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    कैसे खेलें ?
                  </h2>
                </div>
                <div className="col-lg-6">
                  <NavLink
                    className="float-right width-auto"
                    to="/khelo-07-activity-02-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {" "}
                    यहाँ  पर एक लिस्ट दी गई है अगर आपको लगता है कि  स्टेटमेंट  मिथ्या है तो मिथ्या पर क्लिक करें अगर आपको लगता है कि  ये स्टेटमेंट सत्य है तो सत्य पर क्लिक करें
                  </p>
                  <div className="row">
                    <div className="col-lg-6">


                      <div className="row cursor-remove mt-30 align-items-center justify-content-between">
                        <div
                          className="col-lg-6 wow fadeInDown"
                          data-wow-duration="1s"
                          data-wow-delay="0.1s"
                        >
                          <div className="activity-box invisible-checkboxes red-bg sh-sg">

                            <label
                              className="checkbox-alias red-bg-s"
                              htmlFor="b2"
                            >
                              <h4 className="slider-h burai-text"> मिथ्या</h4>
                            </label>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 wow fadeInDown"
                          data-wow-duration="1s"
                          data-wow-delay="0.1s"
                        >
                          <div className="activity-box invisible-checkboxes green-bg sh-sg">
                            <input
                              type="radio"
                              name="rGroup"
                              defaultValue={1}
                              disabled
                              id="t1"
                            />
                            <label
                              className="checkbox-alias green-bg-s"
                              htmlFor="t1"
                            >
                              <h4 className="slider-h burai-text">सत्य</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p
                    className="mpara large-para fs-30 mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {" "}
                    कोशिश करें कि  हम अपनी राय बताएँ और किसी और की मदद न लें ।
                    जवाब देते समय अपने या अपने किसी जानकार के साथ हुई घटनाओं के
                    अनुभवों को याद कर सकते हैं।
                  </p>
                </div>
                <div className="col-lg-4 offset-lg-1">
                  <div
                    className="six-tare wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <p>इस एक्टिविटी को पूरा करने पर आपको 28 तारे मिलेंगे। </p>
                    <ul className="ul-r align-left">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>X 28</h4>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d"
                      to="/khelo-07-activity-03-screen"
                    >
                      शुरू करें
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna07KheloActivity;
