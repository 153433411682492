import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import swal from "sweetalert";

const AccountList = () => {
  const users = JSON.parse(localStorage.getItem("user_details"));
  const [accountStatus,setAccountStatus] = useState();
  const navigate = useNavigate();

  useEffect(() => {

    var data = JSON.stringify({
      user_id: users.user_id,
      account: "",
      status: "",
    });

    var config = {
      method: "post",
      url: BaseURL + "/accountStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
          if(response.data.success === true)
          {
            setAccountStatus(response.data.data.status)
          }
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const AccountDeactivate = (e) => {



    const user_id = users.user_id;

    var data = JSON.stringify({
      user_id: user_id,
      account: "deactivate",
      status: "0",
    });




    var config = {
      method: "post",
      url: BaseURL + "/accountStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {


        // if (response.data.success === true) {
            // setAccountStatus(response.data.data.status)

            window.localStorage.clear();

            navigate('/student-facilator');
            const msg = response.data.message;
          // swal(
          //   msg,
          //   "You clicked the button!",
          //   "success"
          // );
        // }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const AccountDelete = (e) => {

    const user_id = users.user_id;

    var data = JSON.stringify({
      user_id: user_id,
      account: "delete",
      status: "0",
    });

    var config = {
      method: "post",
      url: BaseURL + "/accountStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {

          localStorage.removeItem('user_details');
           navigate("/student-facilator");

          setAccountStatus(response.data.data.status)
          const msg = response.data.message;
          // navigate('/account-delete');

        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const AccountDeactivated = ()=>{
    swal(
        "Account Already Deactivated",
        "You clicked the button!",
        "warning"
      );
  }

  const ActivateAccount = ()=>{
      if(accountStatus === "0")
      {
          return(
              <>
                  <NavLink
                      to="#"
                      // onClick={AccountDeactivated}
                      dataStatus="1"
                      className="btnset m-auto blue-shadow width-auto btn-danger"
                    >
                      <span>डीएक्टिवेट </span>{" "}
                    </NavLink>
              </>
          )
      }else{
          return(
              <>
                  <NavLink
                      to="#"
                      onClick={AccountDeactivate}
                      dataValue="deactivate"
                      dataStatus="0"
                      className="btnset m-auto blue-shadow width-auto "
                    >
                      <span>डीएक्टिवेट </span>{" "}
                    </NavLink>
              </>
          )
    }
  }





  return (
    <>
      <main>
        <section className="bg-flexible general-bg">
          <div className="">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 ">
                <div className="col-lg-7">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    अकाउंट{" "}
                  </h2>
                </div>
                <div className="col-lg-5">
                  <NavLink
                    className="float-right width-auto"
                    to="/board-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row mt-30 align-items-center justify-content-between ">
                <div className="col-lg-3">
                  <h4
                    className="recording wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    अकाउंट को डीएक्टिवेट करें
                  </h4>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                   <ActivateAccount/>
                  </div>
                </div>
                <div className="col-lg-3">
                  <h4
                    className="recording wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    अकाउंट को डिलीट करें
                  </h4>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink
                      to=""
                      onClick={AccountDelete}
                      className="btnset m-auto blue-shadow width-auto"
                    >
                      <span>डिलीट </span>{" "}
                    </NavLink>

                     {/* <NavLink
                      to="#"

                      className="btnset m-auto blue-shadow width-auto"
                    >
                      <span>डिलीट </span>{" "}
                    </NavLink> */}



                  </div>
                </div>
                <div className="col-lg-3">
                  <h4
                    className="recording wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    पासवर्ड चेंज करें{" "}
                  </h4>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink
                      to="/change-password"
                      className="btnset m-auto blue-shadow width-auto"
                    >
                      <span>करें </span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="row mt-30 align-items-center justify-content-center">
                <div
                  className="col-lg-4 text-center wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AccountList;
