import React from "react";
import { Route, Routes,BrowserRouter } from "react-router-dom";
import Protected from "./components/Protected";
import PageNotFound from "./components/404-page";

import About from "./components/about/About";
import Home from './components/home/Home';
import Static from "./components/Auth/Static";
import WelcomeFacilatotor from "./components/Facilator/WelcomeFacilatotor";
import IntroVideo from "./components/home/IntroVideo";

import GroupList from "./components/Facilator/GroupList";
import AddGroup from "./components/Facilator/AddGroup";

import StudentFacilator from "./components/Auth/StudentFacilator";
import PrivacyPolicy from "./components/Auth/PrivacyPolicy";
import TermsAndConditions from "./components/Auth/TermsAndConditions";
import Login from "./components/Auth/Login";
import FacilatorLogin from "./components/Facilator/FacilatorLogin"
import ForgotPassword from "./components/Auth/ForgotPassword";
import FacilatorForgotPassword from "./components/Auth/FacilatorForgotPassword";
import Signup from "./components/Auth/Signup";
import FacilatorSignup from "./components/Facilator/FacilatorSignup";

import Signin from "./components/Auth/Signin";
import ParentSignUp from "./components/Auth/ParentSignUp";
import StudentSignUp from "./components/Auth/StudentSignUp";
import Otp from "./components/Auth/Otp";
import OtpAccountActivate from "./components/Auth/OtpAccountActivate";
import SignUpOtp from "./components/Auth/SignUpOtp";
import CertificateDownloadOtp from "./components/Auth/CertificateDownloadOtp";

import ResetPassword from "./components/Auth/ResetPassword";
import ResetUsername from "./components/Auth/ResetUsername";
import ForgotUsernameOtp from "./components/Auth/ForgotUsernameOtp";
import ForgotUsername from "./components/Auth/ForgotUsername";
import FacilatorForgotUsername from "./components/Auth/FacilatorForgotUsername";

import Anumati from "./components/Anumati";
import Acceptance from "./components/Acceptance";
import RegisterSucess from "./components/RegisterSucess";
import Avtar from "./components/Avtar";
import ChooseDays from "./components/ChooseDays";
import Notification from "./components/Notification";
import FlashScreen from "./components/FlashScreen";
import GeneralScreen from "./components/GeneralScreen";
import GeneralScreenTimer from "./components/GeneralScreenTimer";




import GeneralScreenResult from "./components/GeneralScreenResult";
import GeneralScreen2 from "./components/GeneralScreen2";
import BoardScreen from "./components/BoardScreen";
import UserProfile from "./components/UserProfile";
import ShabdkoshList from "./components/dictionary/ShabdkoshList";
import ShabdkoshDetails from "./components/dictionary/ShabdkoshDetails";
import AccountList from "./components/account/AccountList";
import AccountDeactivate from "./components/account/AccountDeactivate";
import AccountDelete from "./components/account/AccountDelete";
import ChangePassword from "./components/account/ChangePassword";
import TermsAndCond from "./components/account/TermsAndCond";

import Faq1 from "./components/faq/Faq1";
import Faq2 from "./components/faq/Faq2";
import Karo01ActivityScreen from "./components/karo-01-activity/Karo01ActivityScreen";
import Karo02ActivityScreen from "./components/karo-01-activity/Karo02ActivityScreen";
import Karo03ActivityScreen from "./components/karo-01-activity/Karo03ActivityScreen";
import Karo04ActivityScreen from "./components/karo-01-activity/Karo04ActivityScreen";
import Karo05ActivityScreen from "./components/karo-01-activity/Karo05ActivityScreen";
import Karo01ResultScreen from "./components/karo-01-activity/Karo01ResultScreen";
import Karo01SahiJawabScreen from "./components/karo-01-activity/Karo01SahiJawabScreen";
import Karo01GalatJawabScreen from "./components/karo-01-activity/Karo01GalatJawabScreen";
import Kyakrna01ActivityScreen from './components/karo-01-activity/KyaKrna01ActivityScreen';
import Gyanbox01ActivityScreen from "./components/karo-01-activity/Gyanbox01ActivityScreen";

import Karo02Activity01Screen from "./components/karo-02-activity/Karo02Activity01Screen";
import Karo02Activity02Screen from "./components/karo-02-activity/Karo02Activity02Screen";
import Karo02Activity03Screen from "./components/karo-02-activity/Karo02Activity03Screen";
import Karo02Activity04Screen from "./components/karo-02-activity/Karo02Activity04Screen";
import KyaKrna02ActivityScreen from "./components/karo-02-activity/KyaKrna02ActivityScreen";
import Gyanbox02ActivityScreen from "./components/karo-02-activity/Gyanbox02ActivityScreen";
import Karo02Activity05Screen from "./components/karo-02-activity/Karo02Activity05Screen";
import Karo02ActivityHint from "./components/karo-02-activity/Karo02ActivityHint";
import Karo03Activity01Screen from "./components/karo-03-activity/Karo03Activity01Screen";
import Karo03Activity02Screen from "./components/karo-03-activity/Karo03Activity02Screen";
import Karo03Activity03Screen from "./components/karo-03-activity/Karo03Activity03Screen";

import Kyakrna03ActivityScreen from "./components/karo-03-activity/Kyakrna03ActivityScreen";
import Kyakrna03_1ActivityScreen from "./components/karo-03-activity/Kyakrna03_1ActivityScreen";
import Gyanbox03ActivityScreen from "./components/karo-03-activity/Gyanbox03ActivityScreen";
import Karo03Activity04Screen from "./components/karo-03-activity/Karo03Activity04Screen";
import Karo03Activity05Screen from "./components/karo-03-activity/Karo03Activity05Screen";
import Karo03Activity06Screen from "./components/karo-03-activity/Karo03Activity06Screen";
import Karo03Activity07Screen from "./components/karo-03-activity/Karo03Activity07Screen";
import Karo03Activity08Screen from "./components/karo-03-activity/Karo03Activity08Screen";
import Karo03Activity09Screen from "./components/karo-03-activity/Karo03Activity09Screen";
import Karo04Activity01Screen from "./components/karo-04-activity/Karo04Activity01Screen";
import Karo04Activity02Screen from "./components/karo-04-activity/Karo04Activity02Screen";
import Karo04Activity03Screen from "./components/karo-04-activity/Karo04Activity03Screen";
import Karo04Activity04Screen from "./components/karo-04-activity/Karo04Activity04Screen";
import Kyakrna04ActivityScreen from "./components/karo-04-activity/Kyakrna04ActivityScreen";
import Gyanbox04ActivityScreen from "./components/karo-04-activity/Gyanbox04ActivityScreen";
import Karo04Activity05Screen from "./components/karo-04-activity/Karo04Activity05Screen";
import Karo04Activity06Screen from "./components/karo-04-activity/Karo04Activity06Screen";
import Karo04Activity07Screen from "./components/karo-04-activity/Karo04Activity07Screen";
import Karo04Activity08Screen from "./components/karo-04-activity/Karo04Activity08Screen";

import Karo05Activity01Screen from "./components/karo-05-activity/Karo05Activity01Screen";
import Karo05Activity02Screen from "./components/karo-05-activity/Karo05Activity02Screen";
import Kyakrna05ActivityScreen from "./components/karo-05-activity/Kyakrna05ActivityScreen";
import Gyanbox05ActivityScreen from "./components/karo-05-activity/Gyanbox05ActivityScreen";
import Karo05Activity03Screen from "./components/karo-05-activity/Karo05Activity03Screen";
import Karo05Activity04Screen from "./components/karo-05-activity/Karo05Activity04Screen";
import Karo05Activity05Screen from "./components/karo-05-activity/Karo05Activity05Screen";
import Karo05Activity06Screen from "./components/karo-05-activity/Karo05Activity06Screen";
import Karo06Activity01Screen from "./components/karo-06-activity/Karo06Activity01Screen";
import Karo06Activity02Screen from "./components/karo-06-activity/Karo06Activity02Screen";
import Karo06Activity03Screen from "./components/karo-06-activity/Karo06Activity03Screen";
import Karo06Activity04Screen from "./components/karo-06-activity/Karo06Activity04Screen";
import Karo06Activity05Screen from "./components/karo-06-activity/Karo06Activity05Screen";
import Kyakrna06ActivityScreen from "./components/karo-06-activity/Kyakrna06ActivityScreen";
import Gyanbox06ActivityScreen from "./components/karo-06-activity/Gyanbox06ActivityScreen";
import Karo07Activity01Screen from "./components/karo-07-activity/Karo07Activity01Screen";
import Karo07Activity02Screen from "./components/karo-07-activity/Karo07Activity02Screen";
import Karo07Activity03Screen from "./components/karo-07-activity/Karo07Activity03Screen";
import Karo07Activity04Screen from "./components/karo-07-activity/Karo07Activity04Screen";
import Karo07Activity05Screen from "./components/karo-07-activity/Karo07Activity05Screen";
import Karo07Activity06Screen from "./components/karo-07-activity/Karo07Activity06Screen";
import Kyakrna07ActivityScreen from "./components/karo-07-activity/Kyakrna07ActivityScreen";
import Gyanbox07ActivityScreen from "./components/karo-07-activity/Gyanbox07ActivityScreen";

// khelo
import Khelo01Activity01Screen from "./components/khelo-01-activity/Khelo01Activity01Screen";
import Khelo01Activity02Screen from "./components/khelo-01-activity/Khelo01Activity02Screen";
import Gyanbox01KheloActivityScreen from "./components/khelo-01-activity/Gyanbox01KheloActivityScreen";
import Kyakrna01KheloActivity from "./components/khelo-01-activity/Kyakrna01KheloActivity";
import Khelo01Activity03Screen from "./components/khelo-01-activity/Khelo01Activity03Screen";
import Khelo01Activity04Screen from "./components/khelo-01-activity/Khelo01Activity04Screen";
import Khelo01Activity04_1Screen from "./components/khelo-01-activity/Khelo01Activiry04_1Screen";

import Khelo01Activity05Screen from "./components/khelo-01-activity/Khelo01Activity05Screen";
import Khelo01Activity06Screen from "./components/khelo-01-activity/Khelo01Activity06Screen";
import Khelo01Activity06_1Screen from "./components/khelo-01-activity/Khelo01Activity06_1Screen";
import Khelo01Activity07Screen from "./components/khelo-01-activity/Khelo01Activity07Screen";
import Khelo01Activity08Screen from "./components/khelo-01-activity/Khelo01Activity08Screen";
import Khelo01Activity08_1Screen from "./components/khelo-01-activity/Khelo01Activity08_1Screen";

import Khelo01Activity09Screen from "./components/khelo-01-activity/Khelo01Activity09Screen";
import Khelo01Activity10Screen from "./components/khelo-01-activity/Khelo01Activity10Screen";
import Khelo01Activity10_1Screen from "./components/khelo-01-activity/Khelo01Activity10_1Screen";

import Khelo01Activity11Screen from "./components/khelo-01-activity/Khelo01Activity11Screen";
import Khelo01Activity12Screen from "./components/khelo-01-activity/Khelo01Activity12Screen";
import Khelo01Activity12_1Screen from "./components/khelo-01-activity/Khelo01Activity12_1Screen";

import Khelo01Activity13Screen from "./components/khelo-01-activity/Khelo01Activity13Screen";
import Khelo02Activity01Screen from "./components/khelo-02-activity/Khelo02Activity01Screen";
import Khelo02Activity02Screen from "./components/khelo-02-activity/Khelo02Activity02Screen";
import Khelo02Activity03Screen from "./components/khelo-02-activity/Khelo02Activity03Screen";
import Gyanbox02KheloActivityScreen from "./components/khelo-02-activity/Gyanbox02KheloActivityScreen";
import Kyakrna02KheloActivity from "./components/khelo-02-activity/Kyakrna02KheloActivity";
import Khelo02Activity04Screen from "./components/khelo-02-activity/Khelo02Activity04Screen";

// dekho

import Dekho01Activity01Screen from "./components/dekho-01-activity/Dekho01Activity01Screen";
import Dekho01Activity02Screen from "./components/dekho-01-activity/Dekho01Activity02Screen";
import Dekho01Activity03Screen from "./components/dekho-01-activity/Dekho01Activity03Screen";
import Dekho01Activity04Screen from "./components/dekho-01-activity/Dekho01Activity04Screen";
import Dekho01Activity05Screen from "./components/dekho-01-activity/Dekho01Activity05Screen";
import Dekho01Activity06Screen from "./components/dekho-01-activity/Dekho01Activity06Screen";
import Dekho01Activity07Screen from "./components/dekho-01-activity/Dekho01Activity07Screen";
import Dekho01Activity08Screen from "./components/dekho-01-activity/Dekho01Activity08Screen";
import Dekho01Activity09Screen from "./components/dekho-01-activity/Dekho01Activity09Screen";
import Gyanbox01DekhoActivityScreen from "./components/dekho-01-activity/Gyanbox01DekhoActivityScreen";
import Kyakrna01DekhoActivity from "./components/dekho-01-activity/Kyakrna01DekhoActivity";

//suno


import Suno01Activity01Screen from "./components/suno-01-activity/Suno01Activity01Screen";
import Kyakrna01SunoActivity from "./components/suno-01-activity/Kyakrna01SunoActivity";
import Suno01Activity02Screen from "./components/suno-01-activity/Suno01Activity02Screen";
import Suno01Activity03Screen from "./components/suno-01-activity/Suno01Activity03Screen";
import Suno01Activity04Screen from "./components/suno-01-activity/Suno01Activity04Screen";
import Suno01Activity04_1Screen from "./components/suno-01-activity/Suno01Activity04_1Screen";
import Suno01Activity04_2Screen from "./components/suno-01-activity/Suno01Activity04_2Screen";

import Suno01Activity05Screen from "./components/suno-01-activity/Suno01Activity05Screen";
import Suno01Activity06Screen from "./components/suno-01-activity/Suno01Activity06Screen";

import Suno02Activity01Screen from "./components/suno-02-activity/Suno02Activity01Screen";
import Suno02Activity02Screen from "./components/suno-02-activity/Suno02Activity02Screen";
import Kyakrna02SunoActivity from "./components/suno-02-activity/Kyakrna02SunoActivity";
import Suno02Activity03Screen from "./components/suno-02-activity/Suno02Activity03Screen";

import Suno03Activity01Screen from "./components/suno-03-activity/Suno03Activity01Screen";
import Suno03Activity02Screen from "./components/suno-03-activity/Suno03Activity02Screen";
import Suno03Activity03Screen from "./components/suno-03-activity/Suno03Activity03Screen";
import Suno03Activity03_1Screen from "./components/suno-03-activity/Suno03Activity03_1Screen";
import Suno03Activity04Screen from "./components/suno-03-activity/Suno03Activity04Screen";
import Suno03Activity05Screen from "./components/suno-03-activity/Suno03Activity05Screen";
import Suno03Activity06Screen from "./components/suno-03-activity/Suno03Activity06Screen";
import Suno03Activity07Screen from "./components/suno-03-activity/Suno03Activity07Screen";
import Kyakrna03SunoActivity from "./components/suno-03-activity/Kyakrna03SunoActivity";

//khelo
import Khelo03Activity01Screen from "./components/khelo-03-activity/Khelo03Activity01Screen";
import Khelo03Activity02Screen from "./components/khelo-03-activity/Khelo03Activity02Screen";
import Khelo03Activity03Screen from "./components/khelo-03-activity/Khelo03Activity03Screen";
import Kyakrna03KheloActivity from "./components/khelo-03-activity/Kyakrna03KheloActivity";

import Khelo03Activity04Screen from "./components/khelo-03-activity/Khelo03Activity04Screen";
import Khelo03Activity05Screen from "./components/khelo-03-activity/Khelo03Activity05Screen";
import Khelo03Activity06Screen from "./components/khelo-03-activity/Khelo03Activity06Screen";
import Khelo03Activity07Screen from "./components/khelo-03-activity/Khelo03Activity07Screen";
import Khelo03Activity08Screen from "./components/khelo-03-activity/Khelo03Activity08Screen";
import Khelo03Activity09Screen from "./components/khelo-03-activity/Khelo03Activity09Screen";

import Khelo04Activity01Screen from "./components/khelo-04-activity/Khelo04Activity01Screen";
import Khelo04Activity02Screen from "./components/khelo-04-activity/Khelo04Activity02Screen";
import Khelo04Activity03Screen from "./components/khelo-04-activity/Khelo04Activity03Screen";
import Kyakrna04KheloActivity from "./components/khelo-04-activity/Kyakrna04KheloActivity";
import Khelo04Activity04Screen from "./components/khelo-04-activity/Khelo04Activity04Screen";
import Khelo04Activity05Screen from "./components/khelo-04-activity/Khelo04Activity05Screen";
import Khelo04Activity06Screen from "./components/khelo-04-activity/Khelo04Activity06Screen";
import Khelo04Activity07Screen from "./components/khelo-04-activity/Khelo04Activity07Screen";
import Khelo04Activity08Screen from "./components/khelo-04-activity/Khelo04Activity08Screen";
import Khelo04Activity09Screen from "./components/khelo-04-activity/Khelo04Activity09Screen";
import Khelo04Activity10Screen from "./components/khelo-04-activity/Khelo04Activity10Screen";
import Khelo04Activity11Screen from "./components/khelo-04-activity/Khelo04Activity11Screen";
import Khelo05Activity01Screen from "./components/khelo-05-activity/Khelo05Activity01Screen";
import Khelo05Activity02Screen from "./components/khelo-05-activity/Khelo05Activity02Screen";
import Kyakrna05kheloActivity from "./components/khelo-05-activity/Kyakrna05kheloActivity";
import Khelo05Activity03Screen from "./components/khelo-05-activity/Khelo05Activity03Screen";
import Khelo05Activity04Screen from "./components/khelo-05-activity/Khelo05Activity04Screen";
import Khelo05Activity05Screen from "./components/khelo-05-activity/Khelo05Activity05Screen";
import Khelo05Activity06Screen from "./components/khelo-05-activity/Khelo05Activity06Screen";
import Khelo05Activity07Screen from "./components/khelo-05-activity/Khelo05Activity07Screen";

import Khelo06Activity01Screen from "./components/khelo-06-activity/Khelo06Activity01Screen";
import Khelo06Activity02Screen from "./components/khelo-06-activity/Khelo06Activity02Screen";
import Kyakrna06KheloActivity from "./components/khelo-06-activity/Kyakrna06KheloActivity";
import Khelo06Activity03Screen from "./components/khelo-06-activity/Khelo06Activity03Screen";
import Khelo06Activity04Screen from "./components/khelo-06-activity/Khelo06Activity04Screen";
import Khelo06Activity05Screen from "./components/khelo-06-activity/Khelo06Activity05Screen";
import Khelo06Activity06Screen from "./components/khelo-06-activity/Khelo06Activity06Screen";
import Khelo06Activity07Screen from "./components/khelo-06-activity/Khelo06Activity07Screen";

import Khelo07Activity01Screen from "./components/khelo-07-activity/Khelo07Activity01Screen";
import Khelo07Activity02Screen from "./components/khelo-07-activity/Khelo07Activity02Screen";
import Kyakrna07KheloActivity from "./components/khelo-07-activity/Kyakrna07KheloActivity";
import Khelo07Activity03Screen from "./components/khelo-07-activity/Khelo07Activity03Screen";
import Khelo07Activity04Screen from "./components/khelo-07-activity/Khelo07Activity04Screen";
import Khelo07Activity05Screen from "./components/khelo-07-activity/Khelo07Activity05Screen";
import Khelo07Activity06Screen from "./components/khelo-07-activity/Khelo07Activity06Screen";
import Khelo07Activity07Screen from "./components/khelo-07-activity/Khelo07Activity07Screen";
import Khelo07Activity08Screen from "./components/khelo-07-activity/Khelo07Activity08Screen";
import Khelo07Activity09Screen from "./components/khelo-07-activity/Khelo07Activity09Screen";
import Khelo07Activity10Screen from "./components/khelo-07-activity/Khelo07Activity10Screen";
import Khelo07Activity11Screen from "./components/khelo-07-activity/Khelo07Activity11Screen";
import Khelo07Activity12Screen from "./components/khelo-07-activity/Khelo07Activity12Screen";
import Khelo07Activity13Screen from "./components/khelo-07-activity/Khelo07Activity13Screen";
import Khelo07Activity14Screen from "./components/khelo-07-activity/Khelo07Activity14Screen";
import Khelo07Activity15Screen from "./components/khelo-07-activity/Khelo07Activity15Screen";
import Khelo07Activity16Screen from "./components/khelo-07-activity/Khelo07Activity16Screen";
import Khelo07Activity17Screen from "./components/khelo-07-activity/Khelo07Activity17Screen";

import Khelo08Activity01Screen from "./components/khelo-08-activity/Khelo08Activity01Screen";
import Khelo08Activity02Screen from "./components/khelo-08-activity/Khelo08Activity02Screen";
import Khelo08Activity03Screen from "./components/khelo-08-activity/Khelo08Activity03Screen";
import Khelo08Activity04Screen from "./components/khelo-08-activity/Khelo08Activity04Screen";
import Kyakrna08KheloActivity from "./components/khelo-08-activity/Kyakrna08KheloActivity";
import Khelo08Activity05Screen from "./components/khelo-08-activity/Khelo08Activity05Screen";
import Khelo08Activity06Screen from "./components/khelo-08-activity/Khelo08Activity06Screen";
import Khelo08Activity07Screen from "./components/khelo-08-activity/Khelo08Activity07Screen";
import Khelo08Activity08Screen from "./components/khelo-08-activity/Khelo08Activity08Screen";
import Khelo08Activity09Screen from "./components/khelo-08-activity/Khelo08Activity09Screen";
import Khelo08Activity10Screen from "./components/khelo-08-activity/Khelo08Activity10Screen";
import Khelo08Activity11Screen from "./components/khelo-08-activity/Khelo08Activity11Screen";
import Khelo08Activity12Screen from "./components/khelo-08-activity/Khelo08Activity12Screen";
import Khelo08Activity13Screen from "./components/khelo-08-activity/Khelo08Activity13Screen";
import Khelo08Activity14Screen from "./components/khelo-08-activity/Khelo08Activity14Screen";
import Khelo08Activity15Screen from "./components/khelo-08-activity/Khelo08Activity15Screen";
import Khelo08Activity16Screen from "./components/khelo-08-activity/Khelo08Activity16Screen";
import Khelo08Activity17Screen from "./components/khelo-08-activity/Khelo08Activity17Screen";
import Khelo08Activity18Screen from "./components/khelo-08-activity/Khelo08Activity18Screen";
import Khelo08Activity19Screen from "./components/khelo-08-activity/Khelo08Activity19Screen";
import Khelo08Activity20Screen from "./components/khelo-08-activity/Khelo08Activity20Screen";
import Khelo08Activity21Screen from "./components/khelo-08-activity/Khelo08Activity21Screen";
import Khelo08Activity22Screen from "./components/khelo-08-activity/Khelo08Activity22Screen";
import Khelo08Activity23Screen from "./components/khelo-08-activity/Khelo08Activity23Screen";
import Khelo08Activity24Screen from "./components/khelo-08-activity/Khelo08Activity24Screen";
import Khelo08Activity25Screen from "./components/khelo-08-activity/Khelo08Activity25Screen";
import Khelo08Activity26Screen from "./components/khelo-08-activity/Khelo08Activity26Screen";
import Khelo08Activity27Screen from "./components/khelo-08-activity/Khelo08Activity27Screen";
import Khelo08Activity28Screen from "./components/khelo-08-activity/Khelo08Activity28Screen";
import Khelo08Activity29Screen from "./components/khelo-08-activity/Khelo08Activity29Screen";
import Khelo08Activity30Screen from "./components/khelo-08-activity/Khelo08Activity30Screen";
import Khelo08Activity31Screen from "./components/khelo-08-activity/Khelo08Activity31Screen";
import Khelo09Activity01Screen from "./components/khelo-09-activity/Khelo09Activity01Screen";
import Khelo09Activity02Screen from "./components/khelo-09-activity/Khelo09Activity02Screen";
import Kyakrna09KheloActivity from "./components/khelo-09-activity/Kyakrna09KheloActivity";
import Khelo09Activity03Screen from "./components/khelo-09-activity/Khelo09Activity03Screen";
import Khelo09Activity04Screen from "./components/khelo-09-activity/Khelo09Activity04Screen";
import Khelo09Activity05Screen from "./components/khelo-09-activity/Khelo09Activity05Screen";
import Khelo09Activity06Screen from "./components/khelo-09-activity/Khelo09Activity06Screen";
import Khelo09Activity07Screen from "./components/khelo-09-activity/Khelo09Activity07Screen";
import Khelo09Activity08Screen from "./components/khelo-09-activity/Khelo09Activity08Screen";
import Khelo09Activity09Screen from "./components/khelo-09-activity/Khelo09Activity09Screen";
import Khelo09Activity10Screen from "./components/khelo-09-activity/Khelo09Activity10Screen";
import Khelo09Activity11Screen from "./components/khelo-09-activity/Khelo09Activity11Screen";
import Khelo09Activity12Screen from "./components/khelo-09-activity/Khelo09Activity12Screen";
import Khelo09Activity13Screen from "./components/khelo-09-activity/Khelo09Activity13Screen";
import Khelo09Activity14Screen from "./components/khelo-09-activity/Khelo09Activity14Screen";
import Khelo09Activity15Screen from "./components/khelo-09-activity/Khelo09Activity15Screen";

import Khelo10Activity01Screen from "./components/khelo-10-activity/Khelo10Activity01Screen";
import Khelo10Activity02Screen from "./components/khelo-10-activity/Khelo10Activity02Screen";
import Khelo10Activity03Screen from "./components/khelo-10-activity/Khelo10Activity03Screen";
import Khelo10Activity04Screen from "./components/khelo-10-activity/Khelo10Activity04Screen";
import Khelo10Activity05Screen from "./components/khelo-10-activity/Khelo10Activity05Screen";
import Khelo10Activity06Screen from "./components/khelo-10-activity/Khelo10Activity06Screen";
import Khelo10Activity07Screen from "./components/khelo-10-activity/Khelo10Activity07Screen";
import Khelo10Activity08Screen from "./components/khelo-10-activity/Khelo10Activity08Screen";
import Kyakrna10kheloActivity from "./components/khelo-10-activity/Kyakrna10kheloActivity";

import Khelo11Activity01Screen from "./components/khelo-11-activity/Khelo11Activity01Screen";
import Khelo11Activity02Screen from "./components/khelo-11-activity/Khelo11Activity02Screen";
import Kyakrna11KheloActivity from "./components/khelo-11-activity/Kyakrna11KheloActivity";
import Khelo11Activity03Screen from "./components/khelo-11-activity/Khelo11Activity03Screen";
import Khelo11Activity04Screen from "./components/khelo-11-activity/Khelo11Activity04Screen";
import Khelo11Activity05Screen from "./components/khelo-11-activity/Khelo11Activity05Screen";
import Khelo11Activity06Screen from "./components/khelo-11-activity/Khelo11Activity06Screen";
import Khelo11Activity07Screen from "./components/khelo-11-activity/Khelo11Activity07Screen";
import Khelo11Activity08Screen from "./components/khelo-11-activity/Khelo11Activity08Screen";
import Khelo11Activity09Screen from "./components/khelo-11-activity/Khelo11Activity09Screen";
import Khelo11Activity10Screen from "./components/khelo-11-activity/Khelo11Activity10Screen";
import Khelo11Activity11Screen from "./components/khelo-11-activity/Khelo11Activity11Screen";
import Khelo11Activity12Screen from "./components/khelo-11-activity/Khelo11Activity12Screen";
import Khelo11Activity13Screen from "./components/khelo-11-activity/Khelo11Activity13Screen";
import Khelo11Activity14Screen from "./components/khelo-11-activity/Khelo11Activity14Screen";
import Khelo11Activity15Screen from "./components/khelo-11-activity/Khelo11Activity15Screen";
import Khelo11Activity16Screen from "./components/khelo-11-activity/Khelo11Activity16Screen";
import Khelo11Activity17Screen from "./components/khelo-11-activity/Khelo11Activity17Screen";
import Khelo11Activity18Screen from "./components/khelo-11-activity/Khelo11Activity18Screen";
import Khelo11Activity19Screen from "./components/khelo-11-activity/Khelo11Activity19Screen";
import Khelo11Activity20Screen from "./components/khelo-11-activity/Khelo11Activity20Screen";
import Khelo11Activity21Screen from "./components/khelo-11-activity/Khelo11Activity21Screen";
import Khelo11Activity22Screen from "./components/khelo-11-activity/Khelo11Activity22Screen";
import Khelo11Activity23Screen from "./components/khelo-11-activity/Khelo11Activity23Screen";
import Khelo11Activity24Screen from "./components/khelo-11-activity/Khelo11Activity24Screen";
import Khelo11Activity25Screen from "./components/khelo-11-activity/Khelo11Activity25Screen";
import Khelo11Activity26Screen from "./components/khelo-11-activity/Khelo11Activity26Screen";
import Khelo11activity27Screen from "./components/khelo-11-activity/Khelo11activity27Screen";
import Khelo12activity01Screen from "./components/khelo-12-activity/Khelo12Activity01Screen";
import Khelo12activity02Screen from "./components/khelo-12-activity/Khelo12Activity02Screen";
import Kyakrna12KheloActivity from "./components/khelo-12-activity/Kyakrna12KheloActivity";
import Khelo12activity03Screen from "./components/khelo-12-activity/Khelo12Activity03Screen";
import Khelo12activity04Screen from "./components/khelo-12-activity/Khelo12Activity04Screen";
import Khelo12activity05Screen from "./components/khelo-12-activity/Khelo12Activity05Screen";
import Khelo12activity06Screen from "./components/khelo-12-activity/Khelo12Activity06Screen";
import Khelo12activity07Screen from "./components/khelo-12-activity/Khelo12Activity07Screen";
import Khelo12activity08Screen from "./components/khelo-12-activity/Khelo12Activity08Screen";
import Khelo12activity09Screen from "./components/khelo-12-activity/Khelo12Activity09Screen";
import Khelo12activity10Screen from "./components/khelo-12-activity/Khelo12Activity10Screen";
import Khelo12activity11Screen from "./components/khelo-12-activity/Khelo12Activity11Screen";
import Khelo13Activity01Screen from "./components/Khelo-13-activity/Khelo13Activity01Screen";
import Khelo13Activity02Screen from "./components/Khelo-13-activity/Khelo13Activity02Screen";
import Kyakrna13KheloActivity from "./components/Khelo-13-activity/Kyakrna13KheloActivity";
import Khelo13Activity03Screen from "./components/Khelo-13-activity/Khelo13Activity03Screen";
import Khelo13Activity03_2Screen from "./components/Khelo-13-activity/Khelo13Activity03_2Screen";

import Khelo13Activity04Screen from "./components/Khelo-13-activity/Khelo13Activity04Screen";
import Khelo13Activity05Screen from "./components/Khelo-13-activity/Khelo13Activity05Screen";
import Khelo13Activity06Screen from "./components/Khelo-13-activity/Khelo13Activity06Screen";
import Khelo13Activity07Screen from "./components/Khelo-13-activity/Khelo13Activity07Screen";
import Khelo13Activity08Screen from "./components/Khelo-13-activity/Khelo13Activity08Screen";
import Khelo13Activity09Screen from "./components/Khelo-13-activity/Khelo13Activity09Screen";
import Khelo13Activity10Screen from "./components/Khelo-13-activity/Khelo13Activity10Screen";
import Khelo13Activity11Screen from "./components/Khelo-13-activity/Khelo13Activity11Screen";
import Khelo13Activity12Screen from "./components/Khelo-13-activity/Khelo13Activity12Screen";
import Khelo13Activity13Screen from "./components/Khelo-13-activity/Khelo13Activity13Screen";

import Support from "./components/account/Support";

import Certificate from "./components/Certificate";
import Childpolicy from "./components/Auth/Childpolicy";

const AppRoute = () => {
  return (
    <>

      <Routes>

      {/* <Redirect from="/" to="/home" exact/> */}
        <Route  path="/" element={<Home />}></Route>
        <Route path="*" element={PageNotFound} />

        <Route exact path="/support" element={<Support />}></Route>
        <Route exact path="/intro-video" element={<IntroVideo />}></Route>


        <Route exact path="/shabdkosh" element={<ShabdkoshList />}></Route>
        <Route  path="/shabdkosh-details/:id" element={<ShabdkoshDetails />}></Route>
        <Route exact path="/account-list" element={<AccountList />}></Route>
        <Route exact path="/account-deactivate" element={<AccountDeactivate />}></Route>
        <Route exact path="/account-delete" element={<AccountDelete />}></Route>
        <Route exact path="/change-password" element={<ChangePassword />}></Route>
        <Route exact path="/terms-conditions" element={<TermsAndCond />}></Route>

        <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/child-policy" element={<Childpolicy />}></Route>

        <Route path="/certificate" element={<Certificate />}></Route>



        <Route exact path="/welcome" element={<Static />}></Route>
        <Route exact path="/welcome-facilator" element={<WelcomeFacilatotor />}></Route>

        <Route exact path="/student-facilator" element={<StudentFacilator />}></Route>

        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/signup" element={<Signup />}></Route>
        <Route exact path="/facilator-signup" element={<FacilatorSignup />}></Route>

        <Route exact path="/signin" element={<Signin />}></Route>
        <Route exact path="/parent-signup" element={<ParentSignUp />}></Route>
        <Route exact path="/student-signup" element={<StudentSignUp />}></Route>
        <Route exact path="/facilator-login" element={<FacilatorLogin />}></Route>
        <Route exact path="/login" element={<Login />}></Route>

        <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route exact path="/facilator-forgot-password" element={<FacilatorForgotPassword />}></Route>
        <Route exact path="/otp" element={<Otp />}></Route>
        <Route exact path="/otp-account-activate" element={<OtpAccountActivate />}></Route>
        <Route exact path="/signup-otp" element={<SignUpOtp />}></Route>
        <Route exact path="/certificate-download-otp" element={<CertificateDownloadOtp />}></Route>
        <Route exact path="/reset-password" element={<ResetPassword />}></Route>
        <Route exact path="/forgot-username" element={<ForgotUsername />}></Route>
        <Route exact path="/facilator-forgot-username" element={<FacilatorForgotUsername />}></Route>

        <Route exact path="/reset-username" element={<ResetUsername />}></Route>
        <Route exact path="/forgot-username-otp" element={<ForgotUsernameOtp />}></Route>
        <Route exact path="/faq1" element={<Faq1 />}></Route>
        <Route exact path="/faq2" element={<Faq2 />}></Route>



        <Route
          path="/anumati"
          element={<Anumati />}

        ></Route>



          <Route
          exact
          path="/group-list"
          element={<GroupList />}
        ></Route>

        <Route
        exact
          path="/add-group"
          element={<AddGroup />}
        ></Route>







        <Route
          exact
          path="/acceptance"
          element={<Acceptance />}

        ></Route>
        <Route
          exact
          path="/register-success"
          element={<RegisterSucess />}
        ></Route>
        <Route
          exact
          path="/avtar"
          element={<Avtar />}
        ></Route>
        <Route exact path="/choose-days" element={<ChooseDays />}></Route>
        <Route
          exact
          path="/notification"
          element={<Notification/>}
        ></Route>
        <Route
          exact
          path="/flash-screen"
          element={<FlashScreen />}
        ></Route>
        <Route
          exact
          path="/general-screen"
          element={<GeneralScreen />}
        ></Route>



        <Route
          exact
          path="/general-screen-result"
          element={<GeneralScreenResult />}
        ></Route>
        <Route
          exact
          path="/general-screen2"
          element={<GeneralScreen2 />}
        ></Route>

        <Route
          exact
          path="/general-screen-timer"
          element={<GeneralScreenTimer />}
        ></Route>




<Route
          path="/profile"
          element={<Protected Component={UserProfile} />}
        ></Route>

        <Route
          exact
          path="/certificate"
          element={<Protected Component={Certificate} />}
        ></Route>

        <Route
          exact
          path="/board-screen"
          element={<Protected Component={BoardScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-01-activity-screen"
          element={<Protected Component={Karo01ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-02-activity-screen"
          element={<Protected Component={Karo02ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-03-activity-screen"
          element={<Protected Component={Karo03ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-04-activity-screen"
          element={<Protected Component={Karo04ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-05-activity-screen"
          element={<Protected Component={Karo05ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-01-result"
          element={<Protected Component={Karo01ResultScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-01-shi-jawab"
          element={<Protected Component={Karo01SahiJawabScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-01-galat-jawab"
          element={<Protected Component={Karo01GalatJawabScreen} />}
        ></Route>
         <Route
          exact
          path="/kyakrna-01-activity-screen"
          element={<Protected Component={Kyakrna01ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/gyanbox-01-activity-screen"
          element={<Protected Component={Gyanbox01ActivityScreen} />}
        ></Route>

        <Route
          exact
          path="/karo-02-activity-01-screen"
          element={<Protected Component={Karo02Activity01Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-02-activity-02-screen"
          element={<Protected Component={Karo02Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-02-activity-03-screen"
          element={<Protected Component={Karo02Activity03Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-02-activity-04-screen"
          element={<Protected Component={Karo02Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/kya-krna-02-activity-screen"
          element={<Protected Component={KyaKrna02ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/gyanbox-02-activity-screen"
          element={<Protected Component={Gyanbox02ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-02-activity-05-screen"
          element={<Protected Component={Karo02Activity05Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-02-activity-hint"
          element={<Protected Component={Karo02ActivityHint} />}
        ></Route>


        <Route
          exact
          path="/karo-03-activity-01-screen"
          element={<Protected Component={Karo03Activity01Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-03-activity-02-screen"
          element={<Protected Component={Karo03Activity02Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-03-activity-03-screen"
          element={<Protected Component={Karo03Activity03Screen} />}
        ></Route>

        <Route
          exact
          path="/kyakrna-03-activity-screen"
          element={<Protected Component={Kyakrna03ActivityScreen} />}
        ></Route>

        <Route
          exact
          path="/kyakrna-03_1-activity-screen"
          element={<Protected Component={Kyakrna03_1ActivityScreen} />}
        ></Route>



        <Route
          exact
          path="/gyanbox-03-activity-screen"
          element={<Protected Component={Gyanbox03ActivityScreen} />}
        ></Route>

         <Route
          exact
          path="/karo-03-activity-04-screen"
          element={<Protected Component={Karo03Activity04Screen} />}
        ></Route>

         <Route
          exact
          path="/karo-03-activity-05-screen"
          element={<Protected Component={Karo03Activity05Screen} />}
        ></Route>

         <Route
          exact
          path="/karo-03-activity-06-screen"
          element={<Protected Component={Karo03Activity06Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-03-activity-07-screen"
          element={<Protected Component={Karo03Activity07Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-03-activity-08-screen"
          element={<Protected Component={Karo03Activity08Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-03-activity-09-screen"
          element={<Protected Component={Karo03Activity09Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-04-activity-01-screen"
          element={<Protected Component={Karo04Activity01Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-04-activity-02-screen"
          element={<Protected Component={Karo04Activity02Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-04-activity-03-screen"
          element={<Protected Component={Karo04Activity03Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-04-activity-04-screen"
          element={<Protected Component={Karo04Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-04-activity-05-screen"
          element={<Protected Component={Karo04Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-04-activity-06-screen"
          element={<Protected Component={Karo04Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-04-activity-07-screen"
          element={<Protected Component={Karo04Activity07Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-04-activity-08-screen"
          element={<Protected Component={Karo04Activity08Screen} />}
        ></Route>

        <Route
          exact
          path="/kyakrna-04-activity-screen"
          element={<Protected Component={Kyakrna04ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/gyanbox-04-activity-screen"
          element={<Protected Component={Gyanbox04ActivityScreen} />}
        ></Route>

        <Route
          exact
          path="/karo-05-activity-01-screen"
          element={<Protected Component={Karo05Activity01Screen} />}
        ></Route>
         <Route
          exact
          path="/karo-05-activity-02-screen"
          element={<Protected Component={Karo05Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/kyakrna-05-activity-screen"
          element={<Protected Component={Kyakrna05ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/gyanbox-05-activity-screen"
          element={<Protected Component={Gyanbox05ActivityScreen} />}
        ></Route>

         <Route
          exact
          path="/karo-05-activity-03-screen"
          element={<Protected Component={Karo05Activity03Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-05-activity-04-screen"
          element={<Protected Component={Karo05Activity04Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-05-activity-05-screen"
          element={<Protected Component={Karo05Activity05Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-05-activity-06-screen"
          element={<Protected Component={Karo05Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-06-activity-01-screen"
          element={<Protected Component={Karo06Activity01Screen} />}
        ></Route>
          <Route
          exact
          path="/karo-06-activity-02-screen"
          element={<Protected Component={Karo06Activity02Screen} />}
        ></Route>
         <Route
          exact
          path="/karo-06-activity-03-screen"
          element={<Protected Component={Karo06Activity03Screen} />}
        ></Route>
         <Route
          exact
          path="/karo-06-activity-04-screen"
          element={<Protected Component={Karo06Activity04Screen} />}
        ></Route>
         <Route
          exact
          path="/kyakrna-06-activity-screen"
          element={<Protected Component={Kyakrna06ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/gyanbox-06-activity-screen"
          element={<Protected Component={Gyanbox06ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/karo-06-activity-05-screen"
          element={<Protected Component={Karo06Activity05Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-07-activity-01-screen"
          element={<Protected Component={Karo07Activity01Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-07-activity-02-screen"
          element={<Protected Component={Karo07Activity02Screen} />}
        ></Route>

        <Route
          exact
          path="/karo-07-activity-03-screen"
          element={<Protected Component={Karo07Activity03Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-07-activity-04-screen"
          element={<Protected Component={Karo07Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-07-activity-05-screen"
          element={<Protected Component={Karo07Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/karo-07-activity-06-screen"
          element={<Protected Component={Karo07Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/kyakrna-07-activity-screen"
          element={<Protected Component={Kyakrna07ActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/gyanbox-07-activity-screen"
          element={<Protected Component={Gyanbox07ActivityScreen} />}
        ></Route>

      {/* khelo activity */}
        <Route
          exact
          path="/khelo-01-activity-01-screen"
          element={<Protected Component={Khelo01Activity01Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-01-activity-02-screen"
          element={<Protected Component={Khelo01Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/gyanbox-01-khelo-activity-screen"
          element={<Protected Component={Gyanbox01KheloActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/kyakrna-01-khelo-activity-screen"
          element={<Protected Component={Kyakrna01KheloActivity} />}
        ></Route>
          <Route
          exact
          path="/khelo-01-activity-03-screen"
          element={<Protected Component={Khelo01Activity03Screen} />}
        ></Route>

          <Route
          exact
          path="/khelo-01-activity-04-screen"
          element={<Protected Component={Khelo01Activity04Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-01-activity-04_1-screen"
          element={<Protected Component={Khelo01Activity04_1Screen} />}
        ></Route>


          <Route
          exact
          path="/khelo-01-activity-05-screen"
          element={<Protected Component={Khelo01Activity05Screen} />}
        ></Route>

          <Route
          exact
          path="/khelo-01-activity-06-screen"
          element={<Protected Component={Khelo01Activity06Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-01-activity-06_1-screen"
          element={<Protected Component={Khelo01Activity06_1Screen} />}
        ></Route>


          <Route
          exact
          path="/khelo-01-activity-07-screen"
          element={<Protected Component={Khelo01Activity07Screen} />}
        ></Route>


          <Route
          exact
          path="/khelo-01-activity-08-screen"
          element={<Protected Component={Khelo01Activity08Screen} />}
        ></Route>


          <Route
          exact
          path="/khelo-01-activity-08_1-screen"
          element={<Protected Component={Khelo01Activity08_1Screen} />}
        ></Route>



          <Route
          exact
          path="/khelo-01-activity-09-screen"
          element={<Protected Component={Khelo01Activity09Screen} />}
        ></Route>

          <Route
          exact
          path="/khelo-01-activity-10-screen"
          element={<Protected Component={Khelo01Activity10Screen} />}
        ></Route>

<Route
          exact
          path="/khelo-01-activity-10_1-screen"
          element={<Protected Component={Khelo01Activity10_1Screen} />}
        ></Route>

          <Route
          exact
          path="/khelo-01-activity-11-screen"
          element={<Protected Component={Khelo01Activity11Screen} />}
        ></Route>

          <Route
          exact
          path="/khelo-01-activity-12-screen"
          element={<Protected Component={Khelo01Activity12Screen} />}
        ></Route>

      <Route
          exact
          path="/khelo-01-activity-12_1-screen"
          element={<Protected Component={Khelo01Activity12_1Screen} />}
        ></Route>


          <Route
          exact
          path="/khelo-01-activity-13-screen"
          element={<Protected Component={Khelo01Activity13Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-02-activity-01-screen"
          element={<Protected Component={Khelo02Activity01Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-02-activity-02-screen"
          element={<Protected Component={Khelo02Activity02Screen} />}
        ></Route>
         <Route
          exact
          path="/gyanbox-02-khelo-activity-screen"
          element={<Protected Component={Gyanbox02KheloActivityScreen} />}
        ></Route>
        <Route
          exact
          path="/kyakrna-02-khelo-activity-screen"
          element={<Protected Component={Kyakrna02KheloActivity} />}
        ></Route>

          <Route
          exact
          path="/khelo-02-activity-03-screen"
          element={<Protected Component={Khelo02Activity03Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-02-activity-04-screen"
          element={<Protected Component={Khelo02Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-03-activity-01-screen"
          element={<Protected Component={Khelo03Activity01Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-03-activity-02-screen"
          element={<Protected Component={Khelo03Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-03-activity-03-screen"
          element={<Protected Component={Khelo03Activity03Screen} />}
        ></Route>

        <Route
          exact
          path="/kyakrna-03-khelo-activity-screen"
          element={<Protected Component={Kyakrna03KheloActivity} />}
        ></Route>

        <Route
          exact
          path="/khelo-03-activity-04-screen"
          element={<Protected Component={Khelo03Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-03-activity-05-screen"
          element={<Protected Component={Khelo03Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-03-activity-06-screen"
          element={<Protected Component={Khelo03Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-03-activity-07-screen"
          element={<Protected Component={Khelo03Activity07Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-03-activity-08-screen"
          element={<Protected Component={Khelo03Activity08Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-03-activity-09-screen"
          element={<Protected Component={Khelo03Activity09Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-04-activity-01-screen"
          element={<Protected Component={Khelo04Activity01Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-04-activity-02-screen"
          element={<Protected Component={Khelo04Activity02Screen} />}
        ></Route>

        <Route
          exact
          path="/kyakrna-04-khelo-activity-screen"
          element={<Protected Component={Kyakrna04KheloActivity} />}
        ></Route>

        <Route
          exact
          path="/khelo-04-activity-03-screen"
          element={<Protected Component={Khelo04Activity03Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-04-activity-04-screen"
          element={<Protected Component={Khelo04Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-04-activity-05-screen"
          element={<Protected Component={Khelo04Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-04-activity-06-screen"
          element={<Protected Component={Khelo04Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-04-activity-07-screen"
          element={<Protected Component={Khelo04Activity07Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-04-activity-08-screen"
          element={<Protected Component={Khelo04Activity08Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-04-activity-09-screen"
          element={<Protected Component={Khelo04Activity09Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-04-activity-10-screen"
          element={<Protected Component={Khelo04Activity10Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-04-activity-11-screen"
          element={<Protected Component={Khelo04Activity11Screen} />}
        ></Route>


        <Route
          exact
          path="/khelo-05-activity-01-screen"
          element={<Protected Component={Khelo05Activity01Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-05-activity-02-screen"
          element={<Protected Component={Khelo05Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/kyakrna-05-khelo-activity"
          element={<Protected Component={Kyakrna05kheloActivity} />}
        ></Route>

        <Route
          exact
          path="/khelo-05-activity-03-screen"
          element={<Protected Component={Khelo05Activity03Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-05-activity-04-screen"
          element={<Protected Component={Khelo05Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-05-activity-05-screen"
          element={<Protected Component={Khelo05Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-05-activity-06-screen"
          element={<Protected Component={Khelo05Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-05-activity-07-screen"
          element={<Protected Component={Khelo05Activity07Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-06-activity-01-screen"
          element={<Protected Component={Khelo06Activity01Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-06-activity-02-screen"
          element={<Protected Component={Khelo06Activity02Screen} />}
        ></Route>
           <Route
          exact
          path="/kyakrna-06-khelo-activity-screen"
          element={<Protected Component={Kyakrna06KheloActivity} />}
        ></Route>

        <Route
          exact
          path="/khelo-06-activity-03-screen"
          element={<Protected Component={Khelo06Activity03Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-06-activity-04-screen"
          element={<Protected Component={Khelo06Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-06-activity-05-screen"
          element={<Protected Component={Khelo06Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-06-activity-06-screen"
          element={<Protected Component={Khelo06Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-06-activity-07-screen"
          element={<Protected Component={Khelo06Activity07Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-07-activity-01-screen"
          element={<Protected Component={Khelo07Activity01Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-02-screen"
          element={<Protected Component={Khelo07Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-03-screen"
          element={<Protected Component={Khelo07Activity03Screen} />}
        ></Route>
         <Route
          exact
          path="/kyakrna-07-khelo-activity-screen"
          element={<Protected Component={Kyakrna07KheloActivity} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-04-screen"
          element={<Protected Component={Khelo07Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-05-screen"
          element={<Protected Component={Khelo07Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-06-screen"
          element={<Protected Component={Khelo07Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-07-screen"
          element={<Protected Component={Khelo07Activity07Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-08-screen"
          element={<Protected Component={Khelo07Activity08Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-08-screen"
          element={<Protected Component={Khelo07Activity08Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-09-screen"
          element={<Protected Component={Khelo07Activity09Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-10-screen"
          element={<Protected Component={Khelo07Activity10Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-11-screen"
          element={<Protected Component={Khelo07Activity11Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-12-screen"
          element={<Protected Component={Khelo07Activity12Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-13-screen"
          element={<Protected Component={Khelo07Activity13Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-14-screen"
          element={<Protected Component={Khelo07Activity14Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-15-screen"
          element={<Protected Component={Khelo07Activity15Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-16-screen"
          element={<Protected Component={Khelo07Activity16Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-07-activity-17-screen"
          element={<Protected Component={Khelo07Activity17Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-01-screen"
          element={<Protected Component={Khelo08Activity01Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-02-screen"
          element={<Protected Component={Khelo08Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-03-screen"
          element={<Protected Component={Khelo08Activity03Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-04-screen"
          element={<Protected Component={Khelo08Activity04Screen} />}
        ></Route>
         <Route
          exact
          path="/kyakrna-08-khelo-activity-screen"
          element={<Protected Component={Kyakrna08KheloActivity} />}
        ></Route>

        <Route
          exact
          path="/khelo-08-activity-05-screen"
          element={<Protected Component={Khelo08Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-06-screen"
          element={<Protected Component={Khelo08Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-07-screen"
          element={<Protected Component={Khelo08Activity07Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-08-screen"
          element={<Protected Component={Khelo08Activity08Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-09-screen"
          element={<Protected Component={Khelo08Activity09Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-10-screen"
          element={<Protected Component={Khelo08Activity10Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-11-screen"
          element={<Protected Component={Khelo08Activity11Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-12-screen"
          element={<Protected Component={Khelo08Activity12Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-13-screen"
          element={<Protected Component={Khelo08Activity13Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-14-screen"
          element={<Protected Component={Khelo08Activity14Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-15-screen"
          element={<Protected Component={Khelo08Activity15Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-16-screen"
          element={<Protected Component={Khelo08Activity16Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-17-screen"
          element={<Protected Component={Khelo08Activity17Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-18-screen"
          element={<Protected Component={Khelo08Activity18Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-08-activity-19-screen"
          element={<Protected Component={Khelo08Activity19Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-08-activity-20-screen"
          element={<Protected Component={Khelo08Activity20Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-21-screen"
          element={<Protected Component={Khelo08Activity21Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-22-screen"
          element={<Protected Component={Khelo08Activity22Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-08-activity-23-screen"
          element={<Protected Component={Khelo08Activity23Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-08-activity-24-screen"
          element={<Protected Component={Khelo08Activity24Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-25-screen"
          element={<Protected Component={Khelo08Activity25Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-26-screen"
          element={<Protected Component={Khelo08Activity26Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-08-activity-27-screen"
          element={<Protected Component={Khelo08Activity27Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-08-activity-28-screen"
          element={<Protected Component={Khelo08Activity28Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-29-screen"
          element={<Protected Component={Khelo08Activity29Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-30-screen"
          element={<Protected Component={Khelo08Activity30Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-08-activity-31-screen"
          element={<Protected Component={Khelo08Activity31Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-09-activity-01-screen"
          element={<Protected Component={Khelo09Activity01Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-09-activity-02-screen"
          element={<Protected Component={Khelo09Activity02Screen} />}
        ></Route>
         <Route
          exact
          path="/kyakrna-09-khelo-activity-screen"
          element={<Protected Component={Kyakrna09KheloActivity} />}
        ></Route>
          <Route
          exact
          path="/khelo-09-activity-03-screen"
          element={<Protected Component={Khelo09Activity03Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-09-activity-04-screen"
          element={<Protected Component={Khelo09Activity04Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-09-activity-05-screen"
          element={<Protected Component={Khelo09Activity05Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-09-activity-06-screen"
          element={<Protected Component={Khelo09Activity06Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-09-activity-07-screen"
          element={<Protected Component={Khelo09Activity07Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-09-activity-08-screen"
          element={<Protected Component={Khelo09Activity08Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-09-activity-09-screen"
          element={<Protected Component={Khelo09Activity09Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-09-activity-10-screen"
          element={<Protected Component={Khelo09Activity10Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-09-activity-11-screen"
          element={<Protected Component={Khelo09Activity11Screen} />}
        ></Route>


        <Route
          exact
          path="/khelo-09-activity-12-screen"
          element={<Protected Component={Khelo09Activity12Screen} />}
        ></Route>


        <Route
          exact
          path="/khelo-09-activity-13-screen"
          element={<Protected Component={Khelo09Activity13Screen} />}
        ></Route>


      <Route
          exact
          path="/khelo-09-activity-14-screen"
          element={<Protected Component={Khelo09Activity14Screen} />}
        ></Route>


        <Route
          exact
          path="/khelo-09-activity-15-screen"
          element={<Protected Component={Khelo09Activity15Screen} />}
        ></Route>


          <Route
          exact
          path="/khelo-10-activity-01-screen"
          element={<Protected Component={Khelo10Activity01Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-10-activity-02-screen"
          element={<Protected Component={Khelo10Activity02Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-10-activity-03-screen"
          element={<Protected Component={Khelo10Activity03Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-10-activity-04-screen"
          element={<Protected Component={Khelo10Activity04Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-10-activity-05-screen"
          element={<Protected Component={Khelo10Activity05Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-10-activity-06-screen"
          element={<Protected Component={Khelo10Activity06Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-10-activity-07-screen"
          element={<Protected Component={Khelo10Activity07Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-10-activity-08-screen"
          element={<Protected Component={Khelo10Activity08Screen} />}
        ></Route>

        <Route
          exact
          path="/kyakrna-10-khelo-activity"
          element={<Protected Component={Kyakrna10kheloActivity} />}
        ></Route>


          <Route
          exact
          path="/khelo-11-activity-01-screen"
          element={<Protected Component={Khelo11Activity01Screen} />}
        ></Route>
          <Route
          exact
          path="/khelo-11-activity-02-screen"
          element={<Protected Component={Khelo11Activity02Screen} />}
        ></Route>

          <Route
          exact
          path="/kyakrna-11-khelo-activity"
          element={<Protected Component={Kyakrna11KheloActivity} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-03-screen"
          element={<Protected Component={Khelo11Activity03Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-04-screen"
          element={<Protected Component={Khelo11Activity04Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-05-screen"
          element={<Protected Component={Khelo11Activity05Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-06-screen"
          element={<Protected Component={Khelo11Activity06Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-07-screen"
          element={<Protected Component={Khelo11Activity07Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-08-screen"
          element={<Protected Component={Khelo11Activity08Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-09-screen"
          element={<Protected Component={Khelo11Activity09Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-10-screen"
          element={<Protected Component={Khelo11Activity10Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-11-screen"
          element={<Protected Component={Khelo11Activity11Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-12-screen"
          element={<Protected Component={Khelo11Activity12Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-13-screen"
          element={<Protected Component={Khelo11Activity13Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-14-screen"
          element={<Protected Component={Khelo11Activity14Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-15-screen"
          element={<Protected Component={Khelo11Activity15Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-16-screen"
          element={<Protected Component={Khelo11Activity16Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-17-screen"
          element={<Protected Component={Khelo11Activity17Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-18-screen"
          element={<Protected Component={Khelo11Activity18Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-19-screen"
          element={<Protected Component={Khelo11Activity19Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-20-screen"
          element={<Protected Component={Khelo11Activity20Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-21-screen"
          element={<Protected Component={Khelo11Activity21Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-22-screen"
          element={<Protected Component={Khelo11Activity22Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-23-screen"
          element={<Protected Component={Khelo11Activity23Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-24-screen"
          element={<Protected Component={Khelo11Activity24Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-25-screen"
          element={<Protected Component={Khelo11Activity25Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-26-screen"
          element={<Protected Component={Khelo11Activity26Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-11-activity-27-screen"
          element={<Protected Component={Khelo11activity27Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-12-activity-01-screen"
          element={<Protected Component={Khelo12activity01Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-12-activity-02-screen"
          element={<Protected Component={Khelo12activity02Screen} />}
        ></Route>
         <Route
          exact
          path="/kyakrna-12-khelo-activity"
          element={<Protected Component={Kyakrna12KheloActivity} />}
        ></Route>
         <Route
          exact
          path="/khelo-12-activity-03-screen"
          element={<Protected Component={Khelo12activity03Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-12-activity-04-screen"
          element={<Protected Component={Khelo12activity04Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-12-activity-05-screen"
          element={<Protected Component={Khelo12activity05Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-12-activity-06-screen"
          element={<Protected Component={Khelo12activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-12-activity-07-screen"
          element={<Protected Component={Khelo12activity07Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-12-activity-08-screen"
          element={<Protected Component={Khelo12activity08Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-12-activity-09-screen"
          element={<Protected Component={Khelo12activity09Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-12-activity-10-screen"
          element={<Protected Component={Khelo12activity10Screen} />}
        ></Route>
        <Route
          exact
          path="/khelo-12-activity-11-screen"
          element={<Protected Component={Khelo12activity11Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-13-activity-01-screen"
          element={<Protected Component={Khelo13Activity01Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-13-activity-02-screen"
          element={<Protected Component={Khelo13Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/kyakrna-13-khelo-activity"
          element={<Protected Component={Kyakrna13KheloActivity} />}
        ></Route>

         <Route
          exact
          path="/khelo-13-activity-03-screen"
          element={<Protected Component={Khelo13Activity03Screen} />}
        ></Route>

        <Route
          exact
          path="/khelo-13-activity-03_2-screen"
          element={<Protected Component={Khelo13Activity03_2Screen} />}
        ></Route>



         <Route
          exact
          path="/khelo-13-activity-04-screen"
          element={<Protected Component={Khelo13Activity04Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-05-screen"
          element={<Protected Component={Khelo13Activity05Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-06-screen"
          element={<Protected Component={Khelo13Activity06Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-07-screen"
          element={<Protected Component={Khelo13Activity07Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-08-screen"
          element={<Protected Component={Khelo13Activity08Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-09-screen"
          element={<Protected Component={Khelo13Activity09Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-10-screen"
          element={<Protected Component={Khelo13Activity10Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-11-screen"
          element={<Protected Component={Khelo13Activity11Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-12-screen"
          element={<Protected Component={Khelo13Activity12Screen} />}
        ></Route>
         <Route
          exact
          path="/khelo-13-activity-13-screen"
          element={<Protected Component={Khelo13Activity13Screen} />}
        ></Route>













        {/* dekho */}
        <Route
          exact
          path="/dekho-01-activity-01-screen"
          element={<Protected Component={Dekho01Activity01Screen} />}
        ></Route>

        <Route
          exact
          path="/dekho-01-activity-02-screen"
          element={<Protected Component={Dekho01Activity02Screen} />}
        ></Route>
        <Route
          exact
          path="/dekho-01-activity-03-screen"
          element={<Protected Component={Dekho01Activity03Screen} />}
        ></Route>

        <Route
          exact
          path="/dekho-01-activity-04-screen"
          element={<Protected Component={Dekho01Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/dekho-01-activity-05-screen"
          element={<Protected Component={Dekho01Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/dekho-01-activity-06-screen"
          element={<Protected Component={Dekho01Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/dekho-01-activity-07-screen"
          element={<Protected Component={Dekho01Activity07Screen} />}
        ></Route>
        <Route
          exact
          path="/dekho-01-activity-08-screen"
          element={<Protected Component={Dekho01Activity08Screen} />}
        ></Route>
        <Route
          exact
          path="/dekho-01-activity-09-screen"
          element={<Protected Component={Dekho01Activity09Screen} />}
        ></Route>

          <Route
          exact
          path="/gyanbox-01-dekho-activity-screen"
          element={<Protected Component={Gyanbox01DekhoActivityScreen} />}
        ></Route>

  <Route
          exact
          path="/kyakrna-03-dekho-activity-screen"
          element={<Protected Component={Kyakrna01DekhoActivity} />}
        ></Route>



          <Route
          exact
          path="/suno-01-activity-01-screen"
          element={<Protected Component={Suno01Activity01Screen} />}
        ></Route>
          <Route
          exact
          path="/suno-01-activity-02-screen"
          element={<Protected Component={Suno01Activity02Screen} />}
        ></Route>
          <Route
          exact
          path="/kyakrna-01-suno-activity-screen"
          element={<Protected Component={Kyakrna01SunoActivity} />}
        ></Route>
         <Route
          exact
          path="/suno-01-activity-03-screen"
          element={<Protected Component={Suno01Activity03Screen} />}
        ></Route>

         <Route
          exact
          path="/suno-01-activity-04-screen"
          element={<Protected Component={Suno01Activity04Screen} />}
        ></Route>

        <Route
          exact
          path="/suno-01-activity-04_1-screen"
          element={<Protected Component={Suno01Activity04_1Screen} />}
        ></Route>

        <Route
          exact
          path="/suno-01-activity-04_2-screen"
          element={<Protected Component={Suno01Activity04_2Screen} />}
        ></Route>



         <Route
          exact
          path="/suno-01-activity-05-screen"
          element={<Protected Component={Suno01Activity05Screen} />}
        ></Route>

          <Route
          exact
          path="/suno-01-activity-06-screen"
          element={<Protected Component={Suno01Activity06Screen} />}
        ></Route>


         <Route
          exact
          path="/suno-02-activity-01-screen"
          element={<Protected Component={Suno02Activity01Screen} />}
        ></Route>
         <Route
          exact
          path="/suno-02-activity-02-screen"
          element={<Protected Component={Suno02Activity02Screen} />}
        ></Route>
         <Route
          exact
          path="/suno-02-activity-03-screen"
          element={<Protected Component={Suno02Activity03Screen} />}
        ></Route>
         <Route
          exact
          path="/kyakrna-02-suno-activity-screen"
          element={<Protected Component={Kyakrna02SunoActivity} />}
        ></Route>

         <Route
          exact
          path="/suno-03-activity-01-screen"
          element={<Protected Component={Suno03Activity01Screen} />}
        ></Route>
         <Route
          exact
          path="/suno-03-activity-02-screen"
          element={<Protected Component={Suno03Activity02Screen} />}
        ></Route>

         <Route
          exact
          path="/suno-03-activity-03-screen"
          element={<Protected Component={Suno03Activity03Screen} />}
        ></Route>

        <Route
          exact
          path="/suno-03-activity-03_1-screen"
          element={<Protected Component={Suno03Activity03_1Screen} />}
        ></Route>

         <Route
          exact
          path="/suno-03-activity-04-screen"
          element={<Protected Component={Suno03Activity04Screen} />}
        ></Route>
        <Route
          exact
          path="/suno-03-activity-05-screen"
          element={<Protected Component={Suno03Activity05Screen} />}
        ></Route>
        <Route
          exact
          path="/suno-03-activity-06-screen"
          element={<Protected Component={Suno03Activity06Screen} />}
        ></Route>
        <Route
          exact
          path="/suno-03-activity-07-screen"
          element={<Protected Component={Suno03Activity07Screen} />}
        ></Route>
         <Route
          exact
          path="/kyakrna-03-suno-activity-screen"
          element={<Protected Component={Kyakrna03SunoActivity} />}
        ></Route>























      </Routes>
    </>
  );
};

export default AppRoute;
