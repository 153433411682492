import React, { useState } from "react";
import { useNavigate,NavLink } from "react-router-dom";

import BaseURL from "../common/Baseurl";
import axios from "axios";
import FlashMessage from "../FlashMessage";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const ForgotPassword = () => {
    const navigate = useNavigate();
  const [inputValue, setIputValue] = useState();
  const [inputValueErr, setinputValueErr] = useState({});

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const sendOtp = (e) => {

    e.preventDefault();
    const isValid = formValidation();


    var data = JSON.stringify({
      emailMobUser: inputValue,
    });

    var config = {
      method: "post",
      url: BaseURL+"/forgotPassword",
      headers: {
        'type': 'user',
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        if(response.data.success === true)
        {

            const id = response.data.data.id;
            let message_id = '';
            let phone = '';
            let email = '';
            let email_message_id = '';

            if(response.data.data.email_message_id)
            {
              message_id = response.data.data.email_message_id;
              email_message_id = response.data.data.email_message_id;
              email = response.data.data.email;
            }else{

              message_id = response.data.data.message_id;
              phone = response.data.data.phone;
            }


            const forgot_password_details = {"id":id,"email":email,"email_message_id":email_message_id,'message':message,'message_id':message_id};



            localStorage.setItem('forgot_password_details',JSON.stringify(forgot_password_details));
            navigate('/otp');
        }else{
            setMessage(response.data.message);
            setType("danger");
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
        // setMessage(error);
        // setType("danger");
      });
  };

  //   // form validation
  const formValidation = () => {
    const inputvalueErr = {};

    const inputValue1 = inputValue === undefined ? "" : inputValue;

    let isValid = true;

    // console.log(mobEmail.length);
    if (inputValue1.length === 0) {
      inputvalueErr.required = "ईमेल या मोबाइल आवश्यक है";
      isValid = false;
    }

    setinputValueErr(inputvalueErr);

    return isValid;
  };

  return (
    <>
      <form method="" onSubmit={sendOtp}>
        <main>
          <section className="bg-flexible">

          <HomeMenuOuter  />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              {/* <HomeMenu /> */}
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
              <FlashMessage type={type} message={message} />
                  <h3
                    className="main-headding wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    पासवर्ड ?
                  </h3>

                  <div
                    className="input-group wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <label className="levelset ">
                      ई-मेल / मोबाईल नंबर / यूजर नाम  <span className="error">*</span>
                    </label>
                    <div className="input right">
                      <input
                        type="text"
                        className="set-input"
                        placeholder=" "
                        value={inputValue}
                        onChange={(e) => {
                          setIputValue(e.target.value);
                        }}
                      />
                    </div>

                    {Object.keys(inputValueErr).map((key) => {
                      return (
                        <span className="error">{inputValueErr[key]}</span>
                      );
                    })}
                  </div>

                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <button type="submit" className="btnset blue-shadow ">
                      {" "}
                      <span>आगे बढ़ेंं </span>
                    </button>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mt-50"
                      width="95%"
                      src="assets/img/general/sign-up2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
            </div>
          </section>
        </main>
      </form>

      <div
        className="button-fixed termsc wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" justify-content-start">

              <ul className="list-style-set list-circle mt-1">
                <li> <NavLink to="/terms-and-conditions">Terms & Conditions </NavLink>	</li>
                <li> <NavLink to="/privacy-policy">Privacy Policy </NavLink>	</li>
              </ul>


              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ForgotPassword;
