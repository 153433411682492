import React, { useEffect, useState } from "react";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Name from "../common/Name";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Khelo11activity27Screen = () => {

  const navigate = useNavigate();
  const [totalStar, setTotalStar] = useState();

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');


  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "khelo",
      "activity_no": "11"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setTotalStar(response.data.data.total_stars);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-11-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "khelo",
          "activity_key_no": "khelo11",
          "activity_no": "11"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            "type":`${userType}`,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }



  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = ()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    axios(config)
      .then(function (response112) {

        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{

    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      // localStorage.setItem('khelo', sum);
      // navigate(kheloFacilatorUrl[sum].url);

      if(kheloFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('khelo', sum);

        navigate(kheloFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    } else {
      navigate('/khelo-12-activity-01-screen')
    }

  }



  return (
    <>
      <main>
        <section className="bg-flexible F5F0FF">
          <div className="fixed-header">
            <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <h1
                    className="bahut-khub fs-m-4 mt-30 mt-m-1 green wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    बहुत खूब!
                  </h1>
                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1 ">आपकी राय जान कर अच्छा लगा </h1>
                    <p className="text-center">तारे इकट्ठेे हुए </p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStar}</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex"

                      >


                        <button onClick={retryActivity} to="#">
                                  <center>
                                    <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                                  </center>
                                  <p className="side-btn">दुबारा कोशिश   करें</p>
                                </button>


                      </div>
                    </div>
                    <div>
                      <div className="">
                        <button onClick={nextUrlLink}  className="btnset ">
                          <span>अगली एक्टिविटी </span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/kythapl-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup pt-m-3">
              <div className="">

                <div className="container-fluid cf-set">
                  <div className="row align-items-center justify-content-center ">
                    <div
                      className="col-lg-6 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.1s"
                    >
                      <p className="mpara large-para fs-30">
                        कोई भी ऐसी बात जो कि खुद को या किसी और को नुकसान करे या
                        दुःख पहुँचाए वो गलत है। जो बात किसी को नीचा दिखाने के
                        लिए की जाए या उनके आत्मसम्मान पर ठेस पहुँचाए, वो भी गलत
                        है। जिस बात से किसी का या अपना नुकसान न हो, कोई तकलीफ
                        न हो, कोई ज़बरदस्ती नहीं हो, वो बात सही है!
                      </p>
                    </div>
                    <div
                      className="col-lg-4 offset-lg-1 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      <center>
                        <img
                          draggable="false"
                          width="95%"
                          src="assets/img/khelo/kythapl-main-img.svg"
                        />
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo11activity27Screen;
