import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import Name from "../common/Name";


const Suno02Activity02Screen = () => {

    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-5");

  return (
    <>
      <main>
        <section className="bg-flexible jaja-baba-bg">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-7">
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  कभी हाँ कभी ना
                </h1>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  क्या आपको कहानियाँ अच्छी लगती हैं? मुझे याद है बचपन में मेरी
                  दादी मुझे बहुत सारी कहानियाँ सुनाया करती थी। तो आज हम आपको एक
                  कहानी सुनाएँगे, ध्यान से सुनिएगा। आप चाहे तो उसे बार-बार सुन
                  सकते हैं ।{" "}
                </p>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink to="/kyakrna-02-suno-activity-screen" className="btnset ">
                    <span>शुरू करें </span>{" "}
                  </NavLink>
                </div>
                <div
                  className="gkw-img d-flex mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  {/* <div className="mr-20">
                    <a href="khkn-gyaan-box.htmll">
                      <center>
                        <img src="assets/img/icon/gyan-box.svg" alt="" />
                      </center>
                      <p className="side-btn">ज्ञान बॉक्स</p>
                    </a>
                  </div> */}
                  <div className="mr-20">
                    <NavLink to="/kyakrna-02-suno-activity-screen">
                      <center>
                        <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                      </center>
                      <p className="side-btn">क्या करना है?</p>
                    </NavLink>
                  </div>
                  <div className="">
                    <NavLink to="/suno-02-activity-01-screen">
                      <center>
                        <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                      </center>
                      <p className="side-btn">वापस जाएं</p>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="100%"
                    src="assets/img/suno/suno-img.svg"
                  />
                </center>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno02Activity02Screen;
