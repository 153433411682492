import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import swal from "sweetalert";
import axios from "axios";
import BaseURL from "../common/Baseurl";

const Khelo03Activity07Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-10"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [checkBlankData, setCheckBlankData] = useState("blank");

  const [footballPlayerChecked, setFootbalPlayer] = useState();
  const [policeChecked, setPolice] = useState();
  const [gotakhorChecked, setGotakhor] = useState();
  const [doctorChecked, setDoctor] = useState();
  const [sportsmanChecked, setSportsman] = useState();
  const [mukhotaChecked, setMukhota] = useState();
  const [drivingChecked, setDriving] = useState();
  const [lecturerChecked, setLecturer] = useState();
  const [shefChecked, setShef] = useState();
  const [teacherChecked, setTeacher] = useState();

  //for show message
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const [itemElem, setItemElem] = useState([]);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const itemArr = [];
  const selectItem = (e) => {
    const item = e.target.value;
    const checked = e.target.checked;

    setCheckBlankData("filled");
    if (checked === true) {
      itemArr.push(item);
      const __selectedItem = itemArr.join();
      setItemElem([...itemElem, __selectedItem]);
      const checkeitem = "checked";
      checkVar(item,checkeitem);
      // setItemElem(__selectedItem)
    }

    if (checked === false) {
      const newarr = itemElem.filter((el) => {
        return el !== item;
      });

      setItemElem(newarr);
      if(itemElem.length > 1)
      {

        setCheckBlankData("filled");
      }else{

        setCheckBlankData("blank");

      }
      const checkeitem = "";
      checkVar(item, checkeitem);
    }

    // console.log(itemElem);
  };

  const submitKhubiya = () => {
      console.log(itemElem);
    if (itemElem.length > 3) {
      swal("आप सिर्फ 3 ही खूबियाँ चुन सकते हैं!", "", "warning");
      return false;
    }

    var data = JSON.stringify({
      user_id: user_id,
      ans: itemElem.join(),
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity03",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        navigate("/khelo-03-activity-08-screen");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity03ByUser",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const ans = response.data.data.ans;
          console.log(ans);
          const __ans = ans.split(",");
          setItemElem(__ans);
          __ans.forEach((el) => {
            const checkeitem = "checked";
            checkVar(el, checkeitem);
          });

          setCheckBlankData("filled");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const checkVar = (el, checkeitem) => {
    if (el === "footbal_player") {
      setFootbalPlayer(checkeitem);
    }
    if (el === "police") {
      setPolice(checkeitem);
    }

    if (el === "gotakhor") {
      setGotakhor(checkeitem);
    }
    if (el === "doctor") {
      setDoctor(checkeitem);
    }
    if (el === "sportsman") {
      setSportsman(checkeitem);
    }
    if (el === "mukhota") {
      setMukhota(checkeitem);
    }
    if (el === "driving") {
      setDriving(checkeitem);
    }
    if (el === "lecturer") {
      setLecturer(checkeitem);
    }
    if (el === "shef") {
      setShef(checkeitem);
    }
    if (el === "teacher") {
      setTeacher(checkeitem);
    }
  };

  const ImageChange = ()=>{
    if(itemElem.length == 1)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="70%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 2)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="74%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 3)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="78%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 4)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="84%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 5)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="86%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 6)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="88%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 7)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="90%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 8)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="92%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 9)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="94%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 10)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="96%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 11)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="98%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else {

      return (
        <>
              <img
                    draggable="false"
                    width="68%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )

    }
  }



  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div className="mt-15 mb-15">
              <button
                className="theme_btn theme_btn_bg chnage-d purple-shadow m-auto"
                onClick={submitKhubiya}
              >
                आगे बढ़ेंं{" "}
              </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-10 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                    <span>आगे बढ़ेंं </span>{" "}
                  </NavLink> */}

            <button class="btnset  disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible EEFFE8">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />

          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center">
              <div className="col-lg-7">
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                अपनी 3 इच्छाएँ चुनें
                </h1>
                <ul
                  className="btn-effect amu-btn-shadow-none absn-img  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <li>
                    <input
                      type="checkbox"
                      id="cb1"
                      className="graphic"
                      value="footbal_player"
                      checked={footballPlayerChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb1">
                      <img
                        src="assets/img/khelo/icon/dusra-star-1.png"
                        alt=""
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb2"
                      className="graphic"
                      value="police"
                      checked={policeChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb2">
                      <img
                        src="assets/img/khelo/icon/dusra-star-2.png"
                        alt=""
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb3"
                      className="graphic"
                      value="gotakhor"
                      checked={gotakhorChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb3">
                      <img
                        src="assets/img/khelo/icon/dusra-star-3.png"
                        alt=""
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb4"
                      className="graphic"
                      value="doctor"
                      checked={doctorChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb4">
                      <img
                        src="assets/img/khelo/icon/dusra-star-4.png"
                        alt=""
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb5"
                      className="graphic"
                      value="sportsman"
                      checked={sportsmanChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb5">
                      <img
                        src="assets/img/khelo/icon/dusra-star-5.png"
                        alt=""
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb6"
                      className="graphic"
                      value="mukhota"
                      checked={mukhotaChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb6">
                      <img
                        src="assets/img/khelo/icon/dusra-star-6.png"
                        alt=""
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb7"
                      className="graphic"
                      value="driving"
                      checked={drivingChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb7">
                      <img
                        src="assets/img/khelo/icon/dusra-star-7.png"
                        alt=""
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb8"
                      className="graphic"
                      value="lecturer"
                      checked={lecturerChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb8">
                      <img
                        src="assets/img/khelo/icon/dusra-star-8.png"
                        alt=""
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb9"
                      className="graphic"
                      value="shef"
                      checked={shefChecked}
                      onClick={selectItem}

                    />
                    <label htmlFor="cb9">
                      <img
                        src="assets/img/khelo/icon/dusra-star-9.png"
                        alt=""
                      />{" "}
                    </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb10"
                      className="graphic"
                      value="teacher"
                      checked={teacherChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb10">
                      <img
                        src="assets/img/khelo/icon/dusra-star-10.png"
                        alt=""
                      />
                    </label>
                  </li>
                </ul>
                <BtnDisabled />
              </div>
              <div
                className="col-lg-4  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <NavLink to="#">
                   <ImageChange/>

                </NavLink>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo03Activity07Screen;
