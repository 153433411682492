import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HomeMenu from "./common/HomeMenu";
// import FlashMessage from "../FlashMessage";
import axios from "axios";
import BaseURL from "./common/Baseurl";
import HomeMenuOuter from "././common/HomeMenuOuter";
const Acceptance = () => {
  const navigate = useNavigate();
  const [isShowMore, setIsShowMore] = useState(false);
  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  const [message, setMessage] = useState();
  const [type, setType] = useState();
  const student_signup_details = JSON.parse(
    localStorage.getItem("student_signup_details")
  );

  const sendOtp = (e) => {
    // console.log(e);
    e.preventDefault();
    var data = JSON.stringify({
      emailMob: student_signup_details.mobemail,
      userName: student_signup_details.username1,
      registration_type:student_signup_details.registration_type
    });
    var config = {
      method: "post",
      url: BaseURL + "/registerSentOtp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.warn(response);
        if (response.data.success === true) {
          var forgot_password_details = JSON.stringify({
            fullName: student_signup_details.fullName,
            dob: student_signup_details.dob,
            mobemail: student_signup_details.mobemail,
            email: student_signup_details.email,
            username: student_signup_details.username,
            username1: student_signup_details.username1,
            password: student_signup_details.password,
            type: "student",
            gender: student_signup_details.gender,
            auth_provider: "without_google",
            email_message_id: response.data.data.message_id,
            registration_type:student_signup_details.registration_type
          });
          localStorage.setItem(
            "student_signup_details",
            forgot_password_details
          );
          navigate("/signup-otp");
          return false;
          // navigate("/acceptance");
        } else {

          setMessage(response.data.message);
          setType("danger");
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(mobEmail);
    // remove html tag
  };
  //   console.log(extContent);
  return (
    <>
      <main>
        <section className="bg-flexible">
          {/* <HomeMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} /> */}
          <HomeMenuOuter />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-7">
                  <div class="text-wrapper manage-div">
                    <h4>बाल सुरक्षा निति </h4>

                    <p>
                   ब्रेकथ्रू अपनी बाल सुरक्षा निति के अंतर्गत सभी बच्चों को सुरक्षित, देखभाल और कल्याणकारी वातावरण प्रदान करता है और अपने सभी कार्यक्रमों एवं संस्था के सभी स्तरों पर बच्चों और किशोर - किशोरियों की भावनाओं, आवश्यकताओं, संस्कृति, धर्म, जाती और भाषा के सभी सन्दर्भों को ध्यान में रखते हुए उनकी सुरक्षा सुनिश्चित करने के लिए प्रतिबद्ध है|
                    </p>
                    <p><b>फैसलिटेटर को TKT फैसलिटेशन के दौरान निचे दिए गए मानकों का पालन करना अनिवार्य है</b></p>
                    {isShowMore && (


                      <ul style={{"color":"black"}}>
                          <li>फैसलिटेटर बाल सुरक्षा के प्रति अपनी जिम्मेदारी के बारे में सजग रहें, और यह सुनिश्चित करें की वो सभी बच्चों को हर प्रकार के दुर्व्यवहार और शोषण से सुरक्षित रखने में अपनी भूमिका निभाएंगे</li>

                          <li>
                          बच्चों के साथ सम्मानजनक व्यवहार करना और उनकी ऐसे व्यक्ति के रूप में पहचान करना, जिनकी अपनी विशेष आवश्यकताएं और अधिकार है उनकी बात सुनना उनके अधिकारों को महत्व देना और उन्हें गंभीरता से लेना
                          </li>
                          <li>
                          फैसिलिटेटर के लिए जरूरी है की वो समावेशी दृस्टिकोण रखे और यह सुनिश्चित करें की सभी बच्चों को उनकी किसी भी प्रकार की विकलांगता की परवाह किए बिना समान शैक्षिक अवसर प्रदान करे
                          </li>

                          <li>
                          	बच्चों को उनके अधिकारों के बारे में जानकारी दें और उन्हें ये भी बताये की यदि उनके अधिकारों का उल्लघन होता है तो उन्हें कैसे और कहाँ रिपोर्ट करें
                          </li>
                          <li>
                          	खुलेपन की संस्कृति को बढ़ावा दें जहां मुद्दों और चिंताओं को उठाया जा सके और उन पर चर्चा की जा सके
                          </li>
                          <li>
                          किसी भी बच्चे की व्यक्तिगत फोटो या वीडियोग्राफी बिना उसके माता - पिता या अभिभावक की पूर्व लिखित अनुमिति के नहीं ली जाएगी और यह सुनिश्चित करें कि वे सम्मानजनक हों, बच्चों ने पर्याप्त कपड़े पहने हों, कोई भी फोटो या वीडियो किसी के भी द्वारा अपने सोशल मीडिया प्लेटफॉर्म पर साझा नहीं की जयेगी
                          </li>

                          <li>
                          किसी भी बच्चे के साथ अपना पर्सनल फ़ोन नम्बर साझा न करे और न ही उन्हें सोशल मीडिया प्लेटफॉर्म्स के थ्रू कॉन्टेक्ट करने की कोशिस करे और न ही उनका फ्रैंड रेक़ुएस्ट स्वीकार करें
                          </li>
                          <li>
                          	बच्चों के साथ TKT किसी ओपन सप्सेस में ही करें जहां लोग आपको और बच्चे को बातचीत करते हुए देख सके, किसी बंद कमरे में या ऐसी जगह जहां पर आप बच्चों के साथ बातचीत करते हुए दिखाई न दे ऐसी जगह से बचे विशेष रूप से जब समुदाय में TKT कर रहे हो
                          </li>
                          <li>
                          बच्चों के साथ मारपिट या शारीरिक शोषण सहित किसी भी प्रकार की शारीरिक "दंड" का प्रयोग न करें
                          </li>

                          <li>
                          बच्चों के साथ किसी भी तरह का यौन संबंध न बनाएं
                          </li>
                          <li>
                          	किसी भी प्रकार के अनुपयुक्त शारीरिक व्यवहार में संलग्न न होना, जैसे चूमना, गले लगाना या बच्चे को छूना
                          </li>
                          <li>
                          	बच्चों के साथ अपमानजनक या शोषणकारी संबंध विकसित न करना तथा अनुचित, शारीरिक या यौन उत्तेजक भाषा का प्रयोग न करें
                          </li>
                          <li>
                          	बच्चों के साथ किसी प्रकार का शोषण न करें जैसे अपमानित करना, नीचा दिखाना या भावनात्मक शोषण करना
                          </li>
                          <li>
                          	एक बच्चे को वरीयता देने वाले व्यवहार के माध्यम से भेदभाव न करें जैसे कि उपहार, प्रायोजन, पैसा
                          </li>
                          <li>
                          	किसी एक बच्चे को दूसरों से अलग करके अकेले में ज्यादा समय न बिताएं
                          </li>
                          <a href="https://admintkt.genderonline.org/BreakthroughChildSafeguardingPolicy.pdf" target="_blank"><b>पीडीएफ डाउनलोड करे</b></a>
                        </ul>


                    )}
                  </div>
                  <button className="mpara" onClick={toggleReadMoreLess}>
                    {isShowMore ? "Read Less" : "Read More"}
                  </button>
                  {/* <div class="readmore-btn mpara">Read more</div> */}

                  <span className="text-danger">{message}</span>
                  <div className="d-flex">
                    <div
                      className="mt-30 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <NavLink
                        onClick={sendOtp}
                        to="/signup-otp"
                        className="btnset"
                      >
                        <span>स्वीकार है </span>{" "}
                      </NavLink>
                    </div>
                    <div className=" mt-30 m-4" >
                      <NavLink to="/welcome" className="btnset">
                        <span>अस्वीकार हैं</span>{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mb-15"
                      width="100%"
                      src="assets/img/general/suikar.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Acceptance;
