import React, { useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import HomeMenu from "./common/HomeMenu";
import axios from "axios";
import BaseURL from "./common/Baseurl";
import HomeMenuOuter from "././common/HomeMenuOuter";

const Anumati = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-6");

  return (
    <>
      <main>
        <section className="bg-flexible fixed-bg">
        <HomeMenuOuter  />
            {/* <HomeMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} /> */}

          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-12">
                <h3
                  className="main-headding ml-0 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  साइन अप{" "}
                </h3>
                <h5
                  className="h5 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  आदरणीय माता-पिता/ गार्डियन
                </h5>
                <p
                  className="mpara large-para wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                 आपने अपने बच्चे के लिए ब्रेकथ्रू डिजिटल टी.के.टी. मोबाइल ऐप / वेबसाइट का उपयोग करने के लिए अपनी सहमति से खाता बनाया है।  यह सहमति अनिश्चित काल के लिए है जब तक कि आप खाता बंद  या डिलीट नहीं कर देते।
                  ब्रेकथ्रू डिजिटल टी.के.टी. मोबाइल एप्लिकेशन पर, आपका बच्चा ब्रेकथ्रू डिजिटल टी.के.टी. सामग्री (पाठ, दस्तावेज़/फ़ाइलें, चित्र, ऑडियो, वीडियो, गेम) का इस्तेमाल कर सकता है और ये सामग्री 100% निःशुल्क है। स्वीकृति देने के लिए कृपया नीचे दिए गए बटन (अनुमति दें ) पर क्लिक करें। अपने बच्चे के खाते को मंजूरी देकर, आप हमारी उपयोग की शर्तों और गोपनीयता नीति से सहमत होते हैं।

                </p>
              </div>

              <div className="col-lg-5">
                <div className="d-flex">
                <div
                  className="mt-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/acceptance" className="btnset">
                    <span>अनुमति दें </span>{" "}
                  </NavLink>


                </div>

                <div
                  className="mt-15 m-2"

                >
                  <NavLink to="/welcome" className="btnset">
                    <span>अनुमति न दें </span>{" "}
                  </NavLink>

                  {/* <button className="btnset" onClick={parentAcceptance}>
                  <span>अनुमति दें </span>{" "}
                  </button> */}
                </div>
                </div>



              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Anumati;
