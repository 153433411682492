import React, { useState,useEffect } from "react";
import { NavLink,Navigate, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import FlashScreen from "../FlashScreen";

const Karo02Activity04Screen = () => {
    const navigate  = useNavigate();

    const [lg,setLg] = useState("col-lg-9");
    const [rowAllgn, setrowAllgn] = useState(
        "row align-items-center justify-content-center"
      );
      const [faqsize, setfaqsize] = useState('col-lg-3');


  const [heroName, setHeroName] = useState();
  const [AllHeroKhubiya1, setAllHeroKhubiya1] = useState();
  const [AllHeroKhubiya2, setAllHeroKhubiya2] = useState();
  const [AllHeroKhubiya3, setAllHeroKhubiya3] = useState();
  const [AllHeroKhubiya4, setAllHeroKhubiya4] = useState();
  const [AllHeroKhubiya5, setAllHeroKhubiya5] = useState();

  const [AllApniKhubiya1, setAllApniKhubiya1] = useState();
  const [AllApniKhubiya2, setAllApniKhubiya2] = useState();
  const [AllApniKhubiya3, setAllApniKhubiya3] = useState();
  const [AllApniKhubiya4,setAllApniKhubiya4] = useState();
  const [AllApniKhubiya5, setAllApniKhubiya5] = useState();
  const [apnaName, setApnaName] = useState();

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  // for error

  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL+"/karoActivity02Result",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config).then(function (response) {

      const heroAnsData = response.data.data.activities[0].ans;
      const __heroAnsData = heroAnsData.split(',');
      setAllHeroKhubiya1(__heroAnsData[0]);
      setAllHeroKhubiya2(__heroAnsData[1]);
      setAllHeroKhubiya3(__heroAnsData[2]);
      setAllHeroKhubiya4(__heroAnsData[3]);
      setAllHeroKhubiya5(__heroAnsData[4]);
      const heroNamedata = response.data.data.activities[0].name;
      setHeroName(heroNamedata);

      const heroAnsData1 = response.data.data.activities[1].ans;
      const __heroAnsData1 = heroAnsData1.split(',');
      setAllApniKhubiya1(__heroAnsData1[0]);
      setAllApniKhubiya2(__heroAnsData1[1]);
      setAllApniKhubiya3(__heroAnsData1[2]);
      setAllApniKhubiya4(__heroAnsData1[3]);
      setAllApniKhubiya5(__heroAnsData1[4]);
      const heroNamedata1 = response.data.data.activities[1].name;
      setApnaName(heroNamedata1);


      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  const karo02ActivityForm = (e) => {
    e.preventDefault();



    var data = JSON.stringify({
      "user_id": user_id,
      "answers": {
        "ques1": {
          "ans": AllHeroKhubiya1+","+AllHeroKhubiya2+","+AllHeroKhubiya3+","+AllHeroKhubiya4+","+AllHeroKhubiya5,
          "name": heroName,
          "ques_id": "1"
        },
        "ques2": {
          "ans": AllApniKhubiya1+","+AllApniKhubiya2+","+AllApniKhubiya3+","+AllApniKhubiya4+","+AllApniKhubiya5,
          "name": apnaName,
          "ques_id": "2"
        }
      }
    });


    var config = {
      method: "post",
      url: BaseURL+"/karoActivity02_1",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config).then(function (response) {
        if(response.data.success === true)
        {
            navigate('/karo-02-activity-05-screen')
        }

      }).catch(function (error) {
        console.log(error);
      });
  };





  return (
    <>
      <main>
        <form action="#" onSubmit={karo02ActivityForm}>
          <section className="bg-flexible gerua">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
            <div className="container-fluid cf-set">



              <div className="row align-items-center justify-content-between">
                <div className="col-lg-12">
                  <p className="mpara large-para fs-30 mt-40">
                    आपका मनपसंद हीरो/हीरोइन/आदर्श कोई भी हो सकता है, लड़का भी
                    या लड़की भी।
                  </p>
                  <p className="mpara large-para fs-30 ">
                    अच्छे से सोच लें कि आपको यह क्यों पसंद है या आपको यह क्यों
                    सुन्दर लगते हैं और इसी तरह अपने बारे में भी सोच लें और फिर
                    दोनों हिस्सों में 5-5 खूबियाँ लिख दें।
                  </p>

                  <div className="row align-items-center justify-content-between">
                    <div
                      className="col-lg-6 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <div className="border-box gerua-change">
                        <div className="content">
                          <p className="mpara large-para fs-30">
                            मनपसंद हीरो/हीरोइन/आदर्श और खूबियाँ
                          </p>
                          <div className="row pading-set-row">
                            <div className="col-lg-4">
                              <div className="input right new-design-input">
                                <input type="text" className="set-input" value={heroName}  required onChange={(e)=>setHeroName(e.target.value)} placeholder="कलाकार का नाम" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input">
                                <input type="text" className="set-input" value={AllHeroKhubiya1} required onChange={(e)=>setAllHeroKhubiya1(e.target.value)} placeholder="खूबियाँ" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input">
                                <input type="text" className="set-input" value={AllHeroKhubiya2} required onChange={(e)=>setAllHeroKhubiya2(e.target.value)} placeholder="खूबियाँ" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input">
                                <input type="text" className="set-input" value={AllHeroKhubiya3} required onChange={(e)=>setAllHeroKhubiya3(e.target.value)} placeholder="खूबियाँ" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input">
                                <input type="text" className="set-input" value={AllHeroKhubiya4} required onChange={(e)=>setAllHeroKhubiya4(e.target.value)} placeholder="खूबियाँ" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input">
                                <input type="text" className="set-input" value={AllHeroKhubiya5} required onChange={(e)=>setAllHeroKhubiya5(e.target.value)} placeholder="खूबियाँ" />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <span className="shapes one-s" />
                        <span className="shapes sec-s" /> */}
                      </div>
                    </div>
                    <div
                      className="col-lg-6 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <div className="border-box gerua-change gbg-change">
                        <div className="content">
                          <p className="mpara large-para fs-30">अपना नाम और अपनी खूबियाँ</p>
                          <div className="row pading-set-row">
                            <div className="col-lg-4">
                              <div className="input right new-design-input ndi-color">
                                <input type="text" className="set-input" value={apnaName} required onChange={(e)=>setApnaName(e.target.value)} placeholder="आपका नाम" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input ndi-color">
                                <input type="text" className="set-input" value={AllApniKhubiya1} required onChange={(e)=>setAllApniKhubiya1(e.target.value)}  placeholder="अपनी खूबियाँ" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input ndi-color">
                                <input type="text" className="set-input" value={AllApniKhubiya2} required onChange={(e)=>setAllApniKhubiya2(e.target.value)}  placeholder="अपनी खूबियाँ" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input ndi-color">
                                <input type="text" className="set-input" value={AllApniKhubiya3} required onChange={(e)=>setAllApniKhubiya3(e.target.value)}  placeholder="अपनी खूबियाँ" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input ndi-color">
                                <input type="text" className="set-input" value={AllApniKhubiya4} required onChange={(e)=>setAllApniKhubiya4(e.target.value)}  placeholder="अपनी खूबियाँ" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input right new-design-input ndi-color">
                                <input type="text" className="set-input" value={AllApniKhubiya5} required  onChange={(e)=>setAllApniKhubiya5(e.target.value)}  placeholder="अपनी खूबियाँ" />
                              </div>
                            </div>
                          </div>
                          {/* <textarea class="fsetn mtext-area" id="w3review" name="w3review" rows="4" cols="50" placeholder=" खूबियाँ "></textarea> */}
                        </div>

                      </div>
                    </div>
                  </div>


              {/* new */}

                  <div className="d-flex">
                    <div
                      className="mt-10 mb-15 mr-15 wow fadeInDown "
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      {/* <NavLink
                      to="/karo-02-activity-05-screen"
                      className="theme_btn theme_btn_bg chnage-d mt-15"
                    >
                      आगे बढ़ें{" "}
                    </NavLink> */}

                      <button
                        type="submit"
                        className="theme_btn theme_btn_bg chnage-d mt-15"
                      >
                        आगे बढ़े{" "}
                      </button>
                    </div>

                    <div
                      className="mt-15 mb-15 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <NavLink
                        to="#"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                      >
                        <div className="theme_btn theme_btn_bg chnage-d mt-15 hint-p">
                          <img src="assets/img/general/hint.svg" alt="" />
                        </div>
                        <br></br>
                        <p className="text-center hint-font">हिंट</p>
                      </NavLink>
                    </div>
                  </div>

                  {/* <!-- Modal --> */}
                </div>
                </div>

                {/* <!--
                   <div className="col-lg-5">

                       <center><img draggable="false" className="mt-0" width="90%" src="assets/img/karo/pinky-pankaj-activity.svg" /></center>
                   </div> --> */}
              </div>
            </div>
          </section>
        </form>
      </main>

      <div
        className="modal fade bd-example-modal-lg modalmenage"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
              <button
                type="button"
                className="close close-my close-a2"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="border-box popop-set">
              <div className="content">
                       <h2>खूबियाँ</h2>
                       <div className="row item-align-center myrow " >
                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/mazedaar.gif" alt="" />
                                       <h6>मज़ेदार</h6>
                                   </div>

                               </div>
                           </div>

                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/sahasi.gif" alt="" />
                                       <h6>साहसी</h6>
                                   </div>
                               </div>
                           </div>

                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/mehenti.gif" alt="" />
                                       <h6>मेहनती</h6>
                                   </div>
                               </div>
                           </div>

                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/pratibhashali.gif" alt="" />
                                       <h6>प्रतिभाशाली</h6>
                                   </div>
                               </div>
                           </div>

                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/joshila.gif" alt="" />
                                       <h6>जोशीला</h6>
                                   </div>
                               </div>
                           </div>
                       </div>

                       <div className="row myrow">
                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/sunder-or-smart.gif" alt="" />
                                       <h6>सुन्दर और स्मार्ट </h6>
                                   </div>
                               </div>
                           </div>

                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/shakti-shali.gif" alt="" />
                                       <h6>शक्तिशाली</h6>
                                   </div>
                               </div>
                           </div>

                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/chatur.gif" alt="" />
                                       <h6>चतुर</h6>
                                   </div>
                               </div>
                           </div>

                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/bhudhiman.gif" alt="" />
                                       <h6>बुद्धिमान</h6>
                                   </div>
                               </div>
                           </div>

                           <div className="col">
                               <div className="border-box hit-round">
                                   <div className="content">
                                       <img src="assets/img/karo/gif/bharosamand.gif" alt="" />
                                       <h6>भरोसेमंद</h6>
                                   </div>
                               </div>
                           </div>

                       </div>

                   </div>

              </div>

              <div
                className="mt-15 mb-15 text-center wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Karo02Activity04Screen;
