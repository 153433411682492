import React, { useEffect, useState } from 'react'
import Name from './common/Name'
import BaseURL from './common/Baseurl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const Certificate = () => {
	const navigate = useNavigate();
	const [name, setName] = useState();
	const [otpMatchStatus, setOtpMatchStatus] = useState(0);

	localStorage.setItem('name', JSON.stringify({ "name": name }))

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	// alert(user_details.role_id);

	const otp_match_status = JSON.parse(
		localStorage.getItem("otp_match_status")
	);


	const [user, SetUserDetails] = useState([]);

	// console.warn(user.phone);

	let mobemail = '';
	if (user.phone == '' || user.phone == undefined) {
		mobemail = user.email;
	} else {
		mobemail = user.phone;
	}

	// console.log(user.certificate_download);
	const [age, SetAge] = useState();
	let gender = 'करूँगा';
	if (user.gender === 'female') {
		gender = 'करुँगी';
	}


	let rawHTML = '';
	if (user_details.role_id == '16') {
		rawHTML =
			`<div style="margin-top:130px;margin-left:130px;">
			<div style="
			height: 760px;
    background-image: url(assets/img/certificate.png);
    background-size: cover;
    width: 537px;display:flex;flex-flow:column;
	padding:40px 50px 0px 50px !important;justify-content:center;margin-top:15px;align-items:center;text-align:center;
					">

					<h3 style=" font-size: 16px;;
					margin-top: 30px;;text-align:center;position:relative;top:60px;">प्रशस्ति पत्र<h3>

				<h1   style="font-size: 23px;;
						text-align: center;
						margin-bottom: 0;
						line-height: 29px;;
						text-align:center;
						position:relative;
						top:18px;

						">
					यह प्रमाणित किया जाता है कि
				</h1>

				<p style="    position:relative;top:15px;text-align: center;
				font-size: 25px;
				margin-left: 149px
		px;"> <b>नाम:</b>&nbsp; <u>${user.name}</</u></p>

				<h1 style="
				font-size: 23px;;
						text-align: center;
						margin-bottom: 0;
						line-height: 33px;;">
					ने तारों की टोली - जेंडर समता (इक्विटी) पाठयक्रम में सफलतापूर्वक भाग लिया है। हम उनके उत्कृष्ट प्रदर्शन और समर्पण की सराहना
					करते हैं ।

				</h1>


			</div>
		</div>
    `;

	} else {

		rawHTML =
			`<div style="margin-top:130px;margin-left:130px;">
			<div style="
			height: 760px;
    background-image: url(assets/img/certificate.png);
    background-size: cover;
    width: 537px;display:flex;flex-flow:column;
	padding:40px 50px 0px 50px !important;justify-content:center;margin-top:15px;align-items:center;text-align:center;
					">

					<h3 style=" font-size: 16px;;
					margin-top: 30px;;text-align:center;position:relative;top:60px;">प्रशस्ति पत्र<h3>

				<h1   style="font-size: 23px;;
						text-align: center;
						margin-bottom: 0;
						line-height: 29px;;
						text-align:center;
						position:relative;
						top:18px;

						">
					यह प्रमाणित किया जाता है कि
				</h1>

				<p style="    position:relative;top:15px;text-align: center;
				font-size: 25px;
				margin-left: 149px
		px;"> <b>नाम:</b>&nbsp; <u>${name}</</u></p>

				<h1 style="
				font-size: 23px;;
						text-align: center;
						margin-bottom: 0;
						line-height: 33px;;">
					ने तारों की टोली - जेंडर समता (इक्विटी) पाठयक्रम में सफलतापूर्वक भाग लिया है। हम उनके उत्कृष्ट प्रदर्शन और समर्पण की सराहना
					करते हैं ।

				</h1>


			</div>
		</div>
    `;


	}





	const sendOtp = (e) => {
		// console.log(e);
		e.preventDefault();
		var data = JSON.stringify({
			emailMob: mobemail,
			userName: user.logUsername,
		});


		var config = {
			method: "post",
			url: BaseURL + "/certificateDownloadSentOtp",
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};
		axios(config)
			.then(function (response) {
				console.warn(response);
				if (response.data.success === true) {

					var otp_match_status = JSON.stringify({

						otp_match_status: 0,

					});
					localStorage.setItem(
						"otp_match_status",
						otp_match_status
					);

					var message_det = JSON.stringify({

						email_message_id: response.data.data.message_id,

					});
					localStorage.setItem(
						"certificate_down_otp",
						message_det
					);
					navigate("/certificate-download-otp");
					return false;
					// navigate("/acceptance");
				} else {


				}
				// console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
		// console.log(mobEmail);
		// remove html tag
	};

	const pdfDownload = () => {


		const user_id = user_details.user_id;

		var data = JSON.stringify({
			user_id: user_id,

			download_status: "1",
		});

		var config = {
			method: "post",
			url: BaseURL + "/isDownloaded",
			headers: {
				'Authorization': `Bearer ${user_details.token} `,
				'Content-Type': 'application/json'
			},
			data: data,
		};

		axios(config)
			.then(function (response) {


				// console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});

		var iframe = document.createElement('iframe');
		iframe.setAttribute("style", "width:0px !important;height:0px !important");
		var printPDF = document.getElementById('printPDF');
		printPDF.appendChild(iframe);
		setTimeout(function () {
			var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
			iframedoc.body.innerHTML = rawHTML;
			html2canvas(iframedoc.body).then(canvas => {
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF({
					orientation: 'p', // landscape
					unit: 'pt', // points, pixels won't work properly
					format: [1600, 1200] // set needed dimensions for any element
				});
				pdf.addImage(imgData, 'PNG', 0, 0);
				pdf.save('tkt-cetificate.pdf');
				// Remove
				document.querySelectorAll('iframe').forEach(
					function (elem) {
						elem.parentNode.removeChild(elem);
					});
				iframe.setAttribute("style", "display:none;");
			});
		}, 10);
	};
	useEffect(() => {
		const data1 = JSON.stringify({
			token: user_details.token,
		});
		const config1 = {
			method: "post",
			url: BaseURL + "/get_user",
			headers: {
				"Content-Type": "application/json",
			},
			data: data1,
		};
		//   console.log(config1);
		axios(config1)
			.then(function (response) {
				if (response.data.success === true) {
					SetUserDetails(response.data.data)
					let dob1 = response.data.data.dob;
					var today = new Date();
					var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
					var age_now = today.getFullYear() - birthDate.getFullYear();
					var m = today.getMonth() - birthDate.getMonth();
					if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
						age_now--;
					}
					SetAge(age_now);
					// console.log(response.data.data);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);


	const PdfDownloadBtn = () => {


		if (user_details.role_id == '15') {
			if (otp_match_status != null) {

				if (otp_match_status.otp_match_status == 1) {
					return (
						<>
							<button className="mr-40 d-flex" onClick={pdfDownload}>

								{" "}
								<img src="assets/img/karo/certificate-btn.svg" alt="" />
								<span className="download-btn">
									&nbsp; सर्टिफिकेट डाउनलोड करें
								</span>
							</button>

						</>
					)
				} else {

					return (
						<>

							<button className="mr-40 d-flex" onClick={sendOtp}>
								{" "}
								<img src="assets/img/karo/certificate-btn.svg" alt="" />
								<span className="download-btn">
									&nbsp; सर्टिफिकेट डाउनलोड करें
								</span>
							</button>

						</>
					)

				}
			} else {
				return (
					<>

						<button className="mr-40 d-flex" onClick={sendOtp}>
							{" "}
							<img src="assets/img/karo/certificate-btn.svg" alt="" />
							<span className="download-btn">
								&nbsp; सर्टिफिकेट डाउनलोड करें
							</span>
						</button>

					</>
				)
			}

		} else {
			return (
				<>
					<button className="mr-40 d-flex" onClick={pdfDownload}>

						{" "}
						<img src="assets/img/karo/certificate-btn.svg" alt="" />
						<span className="download-btn">
							&nbsp; सर्टिफिकेट डाउनलोड करें
						</span>
					</button>

				</>
			)
		}

	}



	const InputBoxName = () => {

		if (user_details.role_id == '15') {
			return (
				<>

					<div className="pset-cer naamset-certificate">
						<label>नाम:</label>
						<input
							type="text"
							name=""
							className="new-cer-input"
							required=""
							placeholder="मेरा नाम-  "
							onChange={(e) => {
								setName(e.target.value);
							}}
							value={name}
						/>
					</div>

				</>
			)
		} else {
			return (
				<>

					<div className="pset-cer naamset-certificate">
						<label>नाम:</label>
						<input
							type="text"
							name=""
							className="new-cer-input"
							required=""
							placeholder="मेरा नाम-  "

							disabled="true"
						/>
					</div>





				</>
			)
		}

	}

	return (
		<>
			<main>
				<section className="EEECE2">
					<div className="container-fluid cf-set">
						<Name />
						<div className="row align-items-center justify-content-center mt-3">
							<div className="col-4">
								<div id="pdfview" className="new-certificate new-certificate2">
									<div className="content-certi">
										<h5>प्रशस्ति पत्र</h5>
										<h3 className="certificate-h3">
											यह प्रमाणित किया जाता है कि
										</h3>

										<div className="row d-flex">
											<div className="col-sm ">


												<InputBoxName />
											</div>
										</div>

										<h3 className="certificate-h3 mt-2">
											ने तारों की टोली - जेंडर समता (इक्विटी) पाठयक्रम में सफलतापूर्वक भाग लिया है। हम उनके उत्कृष्ट प्रदर्शन और समर्पण की सराहना
											करते हैं ।
										</h3>

									</div>
								</div>
								<div className="gkw-img d-flex">
									<PdfDownloadBtn />

								</div>
							</div>
							<div
								className="col-lg-4 offset-lg-1 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<center>
									<img
										draggable="false"
										width="100%"
										src="assets/img/karo/kisse-hai-meri-pehchan.svg"
									/>
								</center>
							</div>
						</div>
					</div>
				</section>
				<div id="printPDF">
				</div>
			</main>
		</>
	)
}
export default Certificate
