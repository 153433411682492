import React, { useState } from 'react';
import { NavLink} from 'react-router-dom';
import InnerFooter from '../common/InnerFooter';
import HomeMenu from '../common/HomeMenu';
import HomeMenuOuter from '../common/HomeMenuOuter';


const Static = () => {



  return (
    <>

    <main>
		<InnerFooter/>
 		<section className="fixed-bg">
                <HomeMenuOuter  />
            <div className="container-fluid cf-set">


                <div className="row align-items-top justify-content-between">
                    <div className="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                     <img width="100%" src="assets/img/general/pinki-main-hu.svg" alt="lgo" draggable="false" />

                    </div>

                    <div className="col-lg-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                        <p className="pset text-center mt-60 loginpara">साइन अप का बटन दबाएं,</p>


                        <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                            <NavLink to="/signup" className="btnset m-auto blue-shadow red-sha"><span>साइन अप  </span> </NavLink>
                        </div>

                        <p className="pset mb-15 mt-50 text-center loginpara"> पर यदि आप पहले साइन अप कर, स्वयं को रजिस्टर कर चुके हैं! तब लॉग इन कीजिये</p>


                        <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                            <NavLink to="/login" className="btnset m-auto green-shadow green-sha"><span>लॉग इन  </span> </NavLink>
                        </div>

                        <NavLink to="/terms-and-conditions" style={{"color":"blue"}}>Terms & Conditions </NavLink>&nbsp;&nbsp;
                             <NavLink to="/privacy-policy" style={{"color":"blue"}}>Privacy Policy </NavLink>


                    </div>
                    <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                        <center><img draggable="false" className="mb-15" width="100%" alt="logo" src="assets/img/general/pankaj.svg" /></center>

                    </div>
                </div>
            </div>
         </section>



    </main>


    </>
  )
}

export default Static