import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";


const Khelo13Activity02Screen = () => {

  const [secondLevel,setSecondLevel] = useState(false);
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      tablename:"khelo_13_activities_user_ans",
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getActivityByQues",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.success)
        if (response.data.success === true) {

          setSecondLevel(true);


        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  const SecondLevelComp = ()=>{
    if(secondLevel == false)
    {
      return(
        <>
           <li
              className="wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >

              <input type="checkbox"  />
              <label htmlFor="myCheckbox2">
                <img src="assets/img/khelo/star-two.svg" />
              </label>
            </li>
        </>
      )
    }else{

      return(
        <>
        <NavLink to="/khelo-13-activity-03_2-screen">
           <li
              className="wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >

              <input type="checkbox"  />
              <label htmlFor="myCheckbox2">
                <img src="assets/img/khelo/Group2.png" />
              </label>
            </li>
            </NavLink>
        </>
      )


    }
  }


  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
          <Name/>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center">
                <div className="col-lg-6">
                  <h1
                    className="h1 mt-m-1 mb-20 mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    कुछ अपनी बातें
                  </h1>
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    क्या हम अपने शरीर के बारे में अच्छे से जानते हैं? शायद आप
                    में से सबने शरीर के अंगो के बारे में पढ़ा होगा, पर क्या आपको
                    इसके साथ साथ यह भी पता है कि आपके शरीर पर किसका अधिकार है?
                    आइए आज अपने शरीर के बारे में थोड़ा और विस्तार में जानें ।
                  </p>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="/kyakrna-13-khelo-activity" className="btnset ">
                      <span>शुरू करें </span>{" "}
                    </NavLink>
                  </div>
                  <div
                    className="gkw-img d-flex mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >

                    <div className="mr-20">
                      <NavLink to="/kyakrna-13-khelo-activity">
                        <center>
                          <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                        </center>
                        <p className="side-btn">क्या करना है?</p>
                      </NavLink>
                    </div>
                    <div className="">
                      <NavLink to="/khelo-13-activity-01-screen">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">वापस जाएं</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 offset-lg-1">
                  <h1
                    className="h1 mb-20 mt-30 mt-m-1 ml-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    स्तर चुनें{" "}
                  </h1>
                  <ul className="checkbox-effect  cursor-remove">
                    <li
                      className="wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      <input type="checkbox" />
                      <label htmlFor="myCheckbox1">
                        <img src="assets/img/khelo/star-one.svg" />
                      </label>
                    </li>


                   <SecondLevelComp/>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo13Activity02Screen;
