import React from 'react'
import { NavLink } from 'react-router-dom'

const KyaKrna02ActivityScreen = () => {
  return (
    <>
          <main>

        <section className="fixed-bg karo">
           <div className="container-fluid cf-set">


               <div className="row align-items-center justify-content-between mt-100">
                   <div className="col col-lg-6 ">

                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />क्या करना है ?</h2>
                   </div>
                   <div className="col col-lg-5">
                       <NavLink className="float-right width-auto" to="/karo-02-activity-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div>
               </div>
               <div className="row align-items-top justify-content-center mt-20">
                   <div className="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <p className="mpara mt-m-1 large-para fs-30">आपको 3 अलग-अलग चित्र दिखाये जाएँगे और आपको तीनों में से कोई एक विकल्प चुनना है  जिसमें बराबरी दिखाई गई हो। </p>
                       <div className="six-tare">
                           <p>इस एक्टिविटी को पूरा करने पर आपको 15 तारे मिलेंगे। </p>
                           <div>
                           <ul className="ul-r align-left">
                            <li>
                            <img src="assets/img/icon/star.svg" alt="" />
                            </li>
                            <li>
                            <img src="assets/img/icon/star.svg" alt="" />
                            </li>
                            <li>
                            <img src="assets/img/icon/star.svg" alt="" />
                            </li>
                            <li>
                            <h4>X 15</h4>
                            </li>
                        </ul>
                               {/* <span className="ratting-heading">X 15</span> */}
                           </div>
                       </div>

                       <div className="mt-30 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <NavLink to="/karo-03-activity-screen" className="btnset "><span>शुरू करें</span> </NavLink>
                       </div>
                   </div>




                   <div className="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <center><img draggable="false" className="mb-15" width="100%" src="assets/img/general/samaj-badana.svg"  /></center>
                   </div>
               </div>
           </div>
        </section>



   </main>


    </>
  )
}

export default KyaKrna02ActivityScreen