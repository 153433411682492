import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna11KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20">
                <div className="col col-lg-6 ">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    कैसे खेलें ?
                  </h2>
                </div>
                <div className="col col-lg-6">
                  <NavLink
                    className="float-right width-auto"
                    to="/khelo-11-Activity-02-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                  <p
                    className="mpara mt-m-1 large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {" "}
                    इस एक्टिविटी को पूरा करने के लिए कुछ कथन (स्टेटमेंट्स) दिए
                    गए हैं, आपकी नज़र में जो सही लगता है उस पर सही का बटन दबाएँ
                    और यदि कुछ सही नहीं लगता है तो गलत का बटन दबाएँ।{" "}
                  </p>
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {" "}
                    कोशिश करें कि हम  अपनी राय बताएँ और किसी की मदद न लें।{" "}
                  </p>
                  <div
                    className="mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d"
                      to="/khelo-11-activity-03-screen"
                    >
                     शुरू करें
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-4 offset-lg-1">
                  <div
                    className="six-tare wow mt-m-2 fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <p>इस एक्टिविटी को पूरा करने पर आपको 24 तारे मिलेंगे।</p>
                    <ul className="ul-r align-left">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>X 24</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna11KheloActivity;
