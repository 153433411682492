 const BaseURL = 'https://admintkt.genderonline.org/api';
 export const AdminUrl = 'https://admintkt.genderonline.org/';



// testing server
// const BaseURL = 'https://inbt.edukrypt.app/tkt02/api';
// export const AdminUrl = 'https://inbt.edukrypt.app/tkt02/';

// const BaseURL = 'http://localhost/tkt_admin/api';
// export const AdminUrl = 'http://localhost/tkt_admin';
export default BaseURL
