import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FlashMessage from "../FlashMessage";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";
const OtpAccountActivate = () => {
  const account_activate_details = JSON.parse(
    localStorage.getItem("account_activate_details")
  );
  const encpw = JSON.parse(
    localStorage.getItem("encpw")
  );

  const username = JSON.parse(
    localStorage.getItem("username")
  );




  const navigate = useNavigate();
  const [inputValue, setIputValue] = useState();
  const [inputValueErr, setinputValueErr] = useState({});
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const matchOtp = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    const email = account_activate_details.email;
    const phone = account_activate_details.phone;
    const pass = encpw.encpw;
    let emailMobUser = phone;
    if(email !== undefined)
    {

       emailMobUser = email
    }



    const data1 = JSON.stringify({
      message_id: account_activate_details.message_id,
        otp: inputValue,
        emailMobUser:emailMobUser,
        password:pass,
    });
    var config = {
      method: "post",
      url: BaseURL+"/account_activate_OTP_Match",
      headers: {
        "Content-Type": "application/json",
      },
      data: data1,
    };
    axios(config)
      .then(function (response) {

        setMessage('आपका अकाउंट एक्टिवेट हो गया है');
        return false;
          if(response.data.success === true)
          {
            const token = response.data.data.token;
            const userId = response.data.data.id;
            const name = response.data.data.name;
            const user_unique_id = response.data.data.user_unique_id;
            const userData = { "token": token, "user_id": userId, "name": name, "user_unique_id": user_unique_id };
            localStorage.setItem('user_details', JSON.stringify(userData))
            const pathdata = '/board-screen';
            localStorage.setItem("previous_link", JSON.stringify(pathdata));
               navigate("/board-screen");
          }else{
            setMessage(response.data.message);
            setType("danger");
          }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //   // form validation
  const formValidation = () => {
    const inputvalueErr = {};
    const inputValue1 = inputValue === undefined ? "" : inputValue;
    let isValid = true;
    // console.log(mobEmail.length);
    if (inputValue1.length === 0) {
      inputvalueErr.required = "ओटीपी आवश्यक है";
      isValid = false;
    }
    setinputValueErr(inputvalueErr);
    return isValid;
  };
  //   console.log(forgetdetails.message);
  return (
    <>
      <form method="" onSubmit={matchOtp}>
        <main>
          <section className="bg-flexible">
          <HomeMenuOuter  />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                <FlashMessage type={type} message={message} />
                  <h3
                    className="main-headding wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    ओ.टी.पी.
                  </h3>
                  <div
                    className="input-group wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    {/* <FlashMessage type="success" message={forgetdetails.message} /> */}
                    <label
                      className="levelset "
                      style={{ "line-height": "2vw" }}
                    >
                      आपके ई-मेल/मोबाईल नंबर पर ओ.टी.पी. भेजा गया है | निचे दिए
                      गए कॉलम में ओ.टी.पी. डालें
                    </label>
                    <div className="input right">
                      <input
                        type="text"
                        className="set-input"
                        placeholder=" "
                        value={inputValue}
                        onChange={(e) => {
                          setIputValue(e.target.value);
                        }}
                      />
                    </div>
                    {Object.keys(inputValueErr).map((key) => {
                      return (
                        <span className="error">{inputValueErr[key]}</span>
                      );
                    })}
                  </div>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <button type="submit" className="btnset blue-shadow ">
                      {" "}
                      <span>आगे बढ़ेंं </span>
                    </button>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mt-50"
                      width="95%"
                      src="assets/img/general/sign-up2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};
export default OtpAccountActivate;
