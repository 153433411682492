import React, { useEffect, useState,useRef } from "react";
import { useNavigate,NavLink } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import useRecorder from "../UseRecorder";
import { Modal, Button, Form } from "react-bootstrap";
const Suno01Activity05Screen = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-6");
  let [
    audio,
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    startTime,
    endTime
  ] = useRecorder();
  const [errorMsg, setErrorMsg] = useState();


  const [counter, setCounter] = useState(0);

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const clearTimerRef = useRef();


  const stopFunction = () => {


      //  clearInterval(clearIntervalRef.current)
       clearTimeout(clearTimerRef.current)
      //  setCounter(0);
  }



  const startSetInterval = ()=>{
    // console.log(counter);
    clearTimerRef.current = setTimeout(() => setCounter(counter + 1), 1000);

  }



    useEffect(() => {

      if(counter == 60){
        // console.log('counter'+counter);

        ref.current.click();

        stopFunction()



      }


    }, [counter]);

  const submitAudio = () => {
    if (audioURL !== "") {

      const data12 = {
        "user_id": user_id,
        "ques_id": "1",

      };



      let fileName = Date.now() + "recording.webm";
      let file = new File([audio], fileName);




      const formData = new FormData();
      formData.append('files.file', file);
      formData.append('data', JSON.stringify(data12));



      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL + "/sunoActivity01Audio",
        headers: {
          'type': `${userType} `,
          'Content-Type': 'multipart/form-data'
        },
        data : formData
      };



      // let config = {
      //   header: {

      //     'Content-Type': 'multipart/form-data'
      //   }
      // }

      // console.log(config);

      axios.request(config).then(response => {


        navigate('/suno-01-activity-06-screen')

      }).catch(error => {
        console.log('error', error)
      });


    } else {
      setErrorMsg("Please Record Your Answer");
      // console.log('error');
    }
  };



  const RecordingStart = () => {
    if (isRecording === true) {


      startSetInterval()


      return (
        <>
          <div>
            {" "}
            <button
              onClick={startRecording}
              disabled={isRecording}
              className="recording-btn start"
            >
              रिकॉर्डिंग...
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {" "}
            <button
              onClick={startRecording}
              disabled={isRecording}
              className="recording-btn start"
            >
              शुरू करो
            </button>
          </div>
        </>
      );
    }
  };

  const RecordingStop = () => {

    if (isRecording) {
      return (
        <>
          <div>
            {" "}
            <button
             ref={ref}
              onClick={stopRecording}
              disabled={!isRecording}
              className="recording-btn stop"
            >
              रुकना
            </button>
          </div>
        </>
      );
    }
  };
  //   console.log(audioURL);



  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const handleClose = () =>{

    setShow(false);
     navigate("/suno-02-activity-01-screen");
  }

  return (
    <>
      <main>
        <section className="bg-flexible jaja-baba-bg-blue">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between">
                {/* <div className="col-lg-1" /> */}
                <div
                  className="col-lg-5 text-center wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p className="mpara fs-30 mt-40 text-center d-block text-black">
                    आपको ऐसा क्यों लगा?
                  </p>
                  <p className="mpara large-para fs-18 text-center d-block">
                    अपना जवाब रेकॉर्ड कर के भेजें
                  </p>
                  <span className="showError">{errorMsg}</span>
                  <div
                    className="d-flex justify-content-center  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <div className="mange-audio">
                      <span className="audioTimer">00:{counter}</span>
                      <div className="edit-audio editAudioTimer">
                        <audio src={audioURL} controls />
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div
                    class="d-flex justify-content-center mt-20 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <RecordingStart />
                    <RecordingStop />
                    {/* <div>
                      {" "}
                      <button
                        onClick={stopRecording}
                        disabled={!isRecording}
                        className="recording-btn stop"
                      >
                        stop
                      </button>
                    </div> */}
                  </div>
                  <div
                    className="mt-30 mb-15"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    {/* <NavLink  to="/suno-01-activity-06-screen" className="btnset m-auto">
                  <span>आगे बढ़ेंं </span>{" "}
                </NavLink> */}
                    <button className="btnset m-auto" onClick={submitAudio}>
                      <span>आगे बढ़ेंं </span>
                    </button>
                  </div>
                </div>
                {/* <div className="col-lg-1" /> */}
                <div
                  className="col-lg-5 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <img width="100%" src="assets/img/suno/jaja-baja.svg" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>


    </>
  );
};
export default Suno01Activity05Screen;
