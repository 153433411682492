import React from 'react'
import { NavLink } from 'react-router-dom'

const Kyakrna06ActivityScreen = () => {
  return (
    <>

    <main>

        <section className="bg-boarding bg-flexible pt-m-2 karo">
           <div className="container-fluid cf-set">
               <div className="row align-items-center justify-content-center ">
                   <div className="col col-lg-6">
                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />क्या करना है ?</h2>
                   </div>
                   <div className="col col-lg-5">
                       <NavLink className="float-right width-auto" to="/karo-06-activity-03-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div>
               </div>


               <div className="row align-items-center justify-content-center mt-50">
                   <div className="col-lg-6">


                       <p className="mpara large-para fs-30 mt-0 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">आपको अपने मित्र को एक पत्र लिखना है, इस पत्र में आप उनकी कुछ खूबियों के बारे में, उनके मनपसंद खेल के बारे में, या उनकी सबसे अच्छी आदत के बारे में भी लिख सकते हैं।  </p>
                       <p className="mpara large-para fs-30 mt-0  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">आप उनको यह भी बता सकते हैं कि कभी उनको आपकी ज़रूरत हो तो आप हमेशा उनकी मदद करेंगे। </p>

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d" to="/karo-06-activity-04-screen">आगे बढ़ें </NavLink>
                       </div>


                   </div>




                   <div className="col-lg-4 offset-lg-1">
                       <div className="six-tare wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <p>  इस एक्टिविटी को पूरा करने पर आपको 10 तारे मिलेंगे
                                </p>
                           <ul className="ul-r align-left">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><h4>10</h4></li>
                           </ul>
                       </div>
                   </div>
               </div>
           </div>
        </section>



   </main>


    </>
  )
}

export default Kyakrna06ActivityScreen