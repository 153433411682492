import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ProfileMenu from "../common/ProfileMenu";
import MultiRangeSlider from "../common/MultiRangeSlider";
// import '../components/common/RangeSlider.css';
import axios from "axios";
import BaseURL from "../common/Baseurl";

const GroupList = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [groupLists, setGroupLists] = useState([]);
  const [activegroupId, setActiveGroupId] = useState();


  const user_details = JSON.parse(localStorage.getItem("user_details"));

  useEffect(() => {


    fetchGroupList();
    // resumepageUrl();



  }, []);



  const fetchGroupList = () => {

    var data = JSON.stringify({
      user_id: user_details.user_id,

    });

    var config = {
      method: "post",
      url: BaseURL + "/facilitatorGroupList",
      headers: {
        Authorization: `Bearer ${user_details.token} `,
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(config)
      .then(function (response) {
        // console.warn(response);
        if (response.data.success == true) {
          setGroupLists(response.data.data);
          return false;
        }
        navigate('/add-group')
      })
      .catch(function (error) {
        console.log(error);
      });




  }


  const handleClick = (event) => {


    let groupId = event.currentTarget.getAttribute('data-group-id')

    setActiveGroupId(groupId)

    localStorage.setItem('groupId', groupId)

    var data = JSON.stringify({
      user_id: groupId,

    });

    var config = {
      method: "post",
      url: BaseURL + "/getresumePages",
      headers: {
        'type': "group",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {



        if (response.data.success == true) {

          navigate(response.data.data.url)
        }
        if (response.data.success == false) {

          navigate('/board-screen');
        }




        // console.log(response);

      })
      .catch(function (error) {
        console.log(error);
      });


  };




  return (
    <>
      <main>
        <section className="bg-flexible ">
          <ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust heightset-87">

            <div className="col-lg-6">
            <h6
                  className="h6 mt-m-2  text-center wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  Select Group
                </h6>
              <div className="select_groupt_overflow">


                {
                  groupLists.map((val) => {

                    if (val.group_id == activegroupId) {

                      return (
                        <>


                          <div className="input-group mb-15 mt-15" key={val.group_id} onClick={handleClick} data-group-id={val.group_id}>


                            <div className="input right green-shodow-input text-set active-select ">
                              <div className="d-flex justify-content-between align-items-center " style={{ "justify-content": "space-between !important", "width": "100%", "padding": "0px 30px" }}>
                                <h6 className="m-0">{val.group_name}  ({val.complete_activity_percent}%)</h6>
                                <p>{val.number_student} </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )


                    } else {
                      return (
                        <>


                          <div className="input-group mb-15 mt-15" key={val.group_id} onClick={handleClick} data-group-id={val.group_id}>


                            <div className="input right green-shodow-input text-set ">
                              <div className="d-flex justify-content-between align-items-center " style={{ "justify-content": "space-between !important", "width": "100%", "padding": "0px 30px" }}>
                                <h6 className="m-0">{val.group_name} ({val.complete_activity_percent}% Complete) </h6>
                                <p>{val.number_student}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }


                  })
                }


              </div>


              <NavLink to="/add-group">


                <div className="row mt-2">
                  <div className="col-md-6  m-auto">
                    <div className="">
                      <div className="add-box m-auto">
                        <img src="assets/icons/plus.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>


              <div
                className="d-flex"

              >
                <div
                  className="dispalyset"

                >
                  <NavLink to="/board-screen" className="btnset m-auto ">
                    <span>आगे </span>{" "}
                  </NavLink>
                  {/* <NavLink
        to="#"
        className="baadmain"
        style={{ width: "60%" }}
      >
        बाद मे करें
      </NavLink> */}
                </div>
              </div>
            </div>

          </div>
        </section>
      </main>
    </>
  );
};

export default GroupList;
