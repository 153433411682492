import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";

const Khelo02Activity02Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-6");

  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-top justify-content-between mt-30">
              <div className="col-lg-7">
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  पढ़ो और सीखो{" "}
                </h1>

                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  क्या आपके साथ कभी ऐसा हुआ है कि आप अपनी टीचर का दिया हुआ
                  होमवर्क करना भूल गए या आपकी माँ ने आपको कुछ कहा हो और वो आप
                  करना भूल गए हों? ऐसा हम सब के साथ भी होता है। मगर क्या आपको
                  इसकी वजह मालूम है? इस एक्टिविटी को करने से आपको इसकी वजह पता
                  चल जाएगी।{" "}
                </p>

                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink to="/kyakrna-02-khelo-activity-screen" className="btnset ">
                    <span>शुरू करें </span>{" "}
                  </NavLink>
                </div>

                <div
                  className="gkw-img d-flex mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >

                  <div className="mr-20">
                    <NavLink to="/kyakrna-02-khelo-activity-screen">
                      <center>
                        <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                      </center>
                      <p className="side-btn">क्या करना है?</p>
                    </NavLink>
                  </div>
                  <div className="">
                    <NavLink to="/khelo-02-activity-01-screen">
                      <center>
                        <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                      </center>
                      <p className="side-btn">वापस जाएं</p>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="90%"
                    src="assets/img/khelo/pas-main-img.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo02Activity02Screen;
