import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Karo02Activity05Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [totalStar, setTotalStar] = useState();

  const [karoFacilatorUrl, setKaroFacilatorUrl] = useState([]);
  const karoIndex = localStorage.getItem('karo');


  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "karo",
      "activity_no": "2"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setTotalStar(response.data.data.total_stars);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    facilatorUrl();

    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-02-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);




  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएंगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/karo-02-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "karo",
          "activity_key_no": "karo02",
          "activity_no": "2"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            'type': `${userType} `,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }


  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = ()=>{
    var config = {
      method: "post",
      url: BaseURL+"/karoFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    axios(config)
      .then(function (response112) {

        // let sum  = parseInt(karoIndex) + parseInt(1);
        if(userType == 'group')
        {
          setKaroFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{
    const sum = parseInt(karoIndex)+1;
    if(userType == 'group')
    {
      // localStorage.setItem('karo', sum);
      // navigate(karoFacilatorUrl[sum].url);

      if(karoFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('karo', sum);

        navigate(karoFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    }else{
      navigate('/karo-03-activity-01-screen')
    }




  }




  return (
    <>
      <main>
        <section className="bg-flexible gerua">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-center mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                  >
              <div className="col-lg-4">
                <h4 className="shabash text-left mt-30 mt-m-1">बहुत खूब!</h4>

                <div
                  className="six-tare mt-30"
                >
                  <h1 className="rh1">आपके बारे में जान कर अच्छा लगा </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{totalStar}</h4>
                    </li>
                  </ul>
                </div>

                <div className="d-flex mt-30 gkw-img">
                  <div className="mr-20">
                    <div>
                      <NavLink onClick={retryActivity} to="#">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">दुबारा कोशिश   करें</p>
                      </NavLink>
                    </div>
                  </div>
                  {/* <div className="mr-20">
                               <NavLink to="/gyanbox-02-activity-screen">
                                   <center><img src="assets/img/icon/gyan-box.svg" alt="" /></center>
                                   <p className="side-btn">ज्ञान बॉक्स</p>
                               </NavLink>

                           </div> */}
                  <div>
                    <div>
                      <button

                        onClick={nextUrlLink}
                        className="btnset "
                      >
                        <span>अगली एक्टिविटी </span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 offset-lg-2 "
              >
                <center>
                  <img
                    draggable="false"
                    width="100%"
                    src="assets/img/on-boarding/pinky-and-punkaj.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp "
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div className="row align-items-center justify-content-between mt-0">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <p className="mpara large-para fs-30 mt-40">
                    "आपने अब यह एक्टिविटी पूरी कर ली है, पर ज़रा सोचिए कि क्या जो खूबी आपके मनपसंद हीरो/हीरोइन/आदर्श में है क्या वह आपके अंदर नहीं हो सकती? और क्या आपकी खूबियाँ आपके मनपसंद हीरो/हीरोइन/आदर्श में नहीं हैं? अक्सर हमारे शारीरिक दिखावे को खूबी माना जाता है, हमारा व्यक्तित्व और कौशल हमारे रूप या शारीरिक दिखावे पर निर्भर नहीं होते हैं| खूबसूरत व्यक्तित्व हमें खूबसूरत बनाता है।


                    </p>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <a
                      href="sssa-activity-selection-screen.html"
                      style={{ width: "100%" }}
                    >
                      <center>
                        <img
                          draggable="false"
                          width="90%"
                          src="assets/img/on-boarding/pinky-and-punkaj.svg"
                        />
                      </center>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Karo02Activity05Screen;
