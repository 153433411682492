import React, { useState,useEffect } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";

const Khelo01Activity02Screen = () => {

  const [secondLevel,setSecondLevel] = useState(false);
  const [thirdLevel,setThirdLevel] = useState(false);
  const [fourthLevel,setFouthLevel] = useState(false);
  const [fifthLevel,setFifthLevel] = useState(false);
  const user_details = JSON.parse(localStorage.getItem('user_details'));

    const [lg, setLg] = useState("col-lg-8");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");

    useEffect(() => {

      secondlevelQues();
      thirdlevelQues();
      fourthlevelQues();
      fifthlevelQues();

    }, []);


    const secondlevelQues = ()=>{

      var data = JSON.stringify({
        user_id: user_details.user_id,
        tablename:"khelo_01_activities_user_ans",
        ques_id: "2",
      });

      var config = {
        method: "post",
        url: BaseURL + "/getActivityByQues",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.success)
          if (response.data.success === true) {

            setSecondLevel(true);


          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }

    const thirdlevelQues = ()=>{

      var data = JSON.stringify({
        user_id: user_details.user_id,
        tablename:"khelo_01_activities_user_ans",
        ques_id: "3",
      });

      var config = {
        method: "post",
        url: BaseURL + "/getActivityByQues",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.success)
          if (response.data.success === true) {

            setThirdLevel(true);


          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }

    const fourthlevelQues = ()=>{

      var data = JSON.stringify({
        user_id: user_details.user_id,
        tablename:"khelo_01_activities_user_ans",
        ques_id: "4",
      });

      var config = {
        method: "post",
        url: BaseURL + "/getActivityByQues",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.success)
          if (response.data.success === true) {

            setFouthLevel(true);


          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }


    const fifthlevelQues = ()=>{

      var data = JSON.stringify({
        user_id: user_details.user_id,
        tablename:"khelo_01_activities_user_ans",
        ques_id: "5",
      });

      var config = {
        method: "post",
        url: BaseURL + "/getActivityByQues",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.success)
          if (response.data.success === true) {

            setFifthLevel(true);


          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }


// components

    const SecondLevelComp = ()=>{
      if(secondLevel == false)
      {
        return(
          <>
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/star-two.svg" />
                </label>
              </li>
          </>
        )
      }else{

        return(
          <>
          <NavLink to="/khelo-01-activity-05-screen">
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/Group2.png" />
                </label>
              </li>
              </NavLink>
          </>
        )


      }
    }

    const ThirdLevelComp = ()=>{
      if(thirdLevel == false)
      {
        return(
          <>
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/star-two.svg" />
                </label>
              </li>
          </>
        )
      }else{

        return(
          <>
          <NavLink to="/khelo-01-activity-07-screen">
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/Group3.png" />
                </label>
              </li>
              </NavLink>
          </>
        )


      }
    }

    const FourthLevelComp = ()=>{
      if(fourthLevel == false)
      {
        return(
          <>
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/star-two.svg" />
                </label>
              </li>
          </>
        )
      }else{

        return(
          <>
          <NavLink to="/khelo-01-activity-09-screen">
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/Group4.png" />
                </label>
              </li>
              </NavLink>
          </>
        )


      }
    }


    const FifthLevelComp = ()=>{
      if(fifthLevel == false)
      {
        return(
          <>
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/star-two.svg" />
                </label>
              </li>
          </>
        )
      }else{

        return(
          <>
          <NavLink to="/khelo-01-activity-11-screen">
             <li
                className="wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <input type="checkbox"  />
                <label htmlFor="myCheckbox2">
                  <img src="assets/img/khelo/Group5.png" />
                </label>
              </li>
              </NavLink>
          </>
        )


      }
    }



  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-between mt-50">
              <div className="col-lg-5">
                <h1
                  className="h1 mb-20 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  हमारा नारा
                </h1>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  जैसे ‘तारों की टोली’ हमारी पहचान है वैसे ही हमारा कोई नारा भी
                  होना चाहिए। आज हम अपने लिए एक नारा तैयार करेंगे।
                </p>

                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink to="/kyakrna-01-khelo-activity-screen" className="btnset ">
                    <span>शुरू करें</span>{" "}
                  </NavLink>
                </div>

                <div
                  className="gkw-img d-flex mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >

                  <div className="mr-20">
                    <NavLink to="/kyakrna-01-khelo-activity-screen">
                      <center>
                        <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                      </center>
                      <p className="side-btn">क्या करना है?</p>
                    </NavLink>
                  </div>
                  <div className="">
                    <NavLink to="/khelo-01-activity-01-screen">
                      <center>
                        <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                      </center>
                      <p className="side-btn">वापस जाएं</p>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  स्तर चुनें{" "}
                </h1>
                <ul className="checkbox-effect">
                  <li
                    className="wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <input type="checkbox" id="myCheckbox1" />
                    <label for="myCheckbox1">
                      <img src="assets/img/khelo/star-one.svg" />
                    </label>
                  </li>

                 <SecondLevelComp/>
                 <ThirdLevelComp/>
                 <FourthLevelComp/>

                  <FifthLevelComp/>





                </ul>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo01Activity02Screen;
