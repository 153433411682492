import React, { useEffect } from 'react';
import AppRoute from './AppRoute';
import { Route, Routes,BrowserRouter } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";



// import Footer from './Footer';
const App = () => {

  const navigate = useNavigate();

  var hours = 24; // to clear the localStorage after 1 hour
               // (if someone want to clear after 8hrs simply change hours=8)
var now = new Date().getTime();
var setupTime = localStorage.getItem('setupTime');
if (setupTime == null) {
    localStorage.setItem('setupTime', now)
} else {
    if(now-setupTime > hours*60*60*1000) {
        localStorage.clear()
        localStorage.setItem('setupTime', now);

        useEffect(()=>{

          navigate('/student-facilator');
        },[])
    }
}

  return (
    <>


       <AppRoute/>
    </>

  )
}

export default App