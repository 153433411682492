import React, { useEffect, useState,useRef } from "react";
import BoardName1 from "./common/BoardName1";
import Sidebar from "./common/Sidebar";
import axios from "axios";
import BaseURL from "./common/Baseurl";

import swal from "sweetalert";
import useRecorder from "./UseRecorder";
import { Navigate, NavLink, useNavigate } from "react-router-dom";

const UserProfile = () => {

  const ref = useRef(null);

  const navigate = useNavigate();

  const [imageName,setImageName] = useState('');

  let [
    audio,
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    startTime,
    endTime
  ] = useRecorder();




  const [star, setTotalStar] = useState();
  const [award, setAward] = useState();
  const [userstars, setUserStars] = useState([]);
  const [activityName, setActivityName] = useState();
  const [desc, setDesc] = useState();
  const [yourActivityName, setYourActivityName] = useState();
  const [subject, setSubject] = useState();
  const [maudio, setAudio] = useState();
  const [attachment, setAttachment] = useState();
  const [problem, setProblem] = useState();
  const [comment, setComment] = useState();
  const [allActivity, setAllActivity] = useState([]);

  const [report, setReport] = useState([]);
  const [activi_all, setActivity] = useState([]);
  const users = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = users.user_id;
  if(users.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }



  const [counter, setCounter] = useState(0);

  const clearTimerRef = useRef();


  const stopFunction = () => {
      //  clearInterval(clearIntervalRef.current)
       clearTimeout(clearTimerRef.current)
      //  setCounter(0);
  }



  const startSetInterval = ()=>{
    // console.log(counter);
    clearTimerRef.current = setTimeout(() => setCounter(counter + 1), 1000);

  }



    // useEffect(() => {

    //   if(counter == 60){
    //     // console.log('counter'+counter);

    //     ref.current.click();

    //     stopFunction()

    //   }


    // }, [counter]);




  const RecordingStart = () => {
    if (isRecording === true) {

      startSetInterval();

      return (
        <>
          <div>
            {" "}
            <button
              onClick={startRecording}
              disabled={isRecording}
              className="recording-btn start"
            >
              Recording...
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {" "}

              <img  onClick={startRecording}
              disabled={isRecording}
              className="start" src="assets/img/on-boarding/btn/recording-img.svg" alt=""></img>
              <span className="title">बोल कर अपलोड करें
                                            </span>

          </div>

          {/* <div>
            {" "}

              <img
              className="start" src="assets/img/on-boarding/btn/recording-img.svg" alt=""></img>


          </div> */}

        </>
      );
    }
  };

// console.log(audioURL);


  const RecordingStop = () => {

    if (isRecording) {
      return (
        <>
          <div>
            {" "}
            <button
              onClick={stopRecording}
              disabled={!isRecording}
              className="recording-btn stop"
            >
              रुकना
            </button>
          </div>
        </>
      );
    }
  };


  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/studentProfile",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const item = response.data.data;
          setTotalStar(item.totalstar);
          setAward(item.award);
          setUserStars(item.user_stars);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
    });



      let sendurl = BaseURL + "/getAnalytics";


    var config = {
      method: "post",
      url: sendurl,
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const item1 = response.data.data;
          const item_act = response.data.data.data;
          setReport(item1);
          setActivity(item_act);
        }
        // console.log(JSON.stringify(response.data.data.data));
      })
      .catch(function (error) {
        console.log(error);
      });


  }, []);



  const submitHelp = (e) => {


    e.preventDefault();
    var FormData = require("form-data");
    const data = {
      activity_name: activityName,
      kon_si_activity: yourActivityName,
      subject: subject,
      // ans: audioURL,
      description: desc,

      userType: userType,
      koi_or_samasya: problem,
      comment: comment,
      user_id: users.user_id,




    };

    let fileName = Date.now() + "recording.webm";
    let file = new File([audio], fileName);
    const formData = new FormData();
      formData.append('files.file', file);
      formData.append('files.image', attachment);
      formData.append('data', JSON.stringify(data));

      let config = {
        header: {

          'Content-Type': 'multipart/form-data'
        }
      }


      axios.post(BaseURL + "/helpSave1for_web", formData, config).then(response => {
        // console.log('response', response)
        // return false;
        swal("आपके प्रश्न को भेज दिया गया है", "", "success");
        // navigate('/khelo-05-activity-06-screen')
      }).catch(error => {
        console.log('error', error)
      });


  };

  const uploadAudioFile = (e) => {
    const files = e.target.files;
    setAudio(files[0]);

  }

  const uploadAttachmentFile = (e) => {
    const attachment = e.target.files;

    setImageName(attachment[0].name)
    // console.log(attachment[0].name);
    setAttachment(attachment[0]);
  }

  const getActivity = (e) => {
    const activityName = e.target.value;
    setActivityName(activityName);

    var data = JSON.stringify({
      "type": activityName
    });

    var config = {
      method: 'post',
      url: BaseURL+'/fetchActivity',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        if(response.data.success === true)
        {
          setAllActivity(response.data.data)
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });


  }

const certificateUrl = ()=>{
  var otp_match_status = JSON.stringify({

    otp_match_status:0 ,

    });
    localStorage.setItem(
    "otp_match_status",
    otp_match_status
    );

  navigate("/certificate");
}
  return (
    <>
      <main>
        <Sidebar />
        <section className="bg-flexible general-bg">
          <BoardName1 />
          <div className="data-adjust2">
            <div className="container-fluid cf-set">
              <div className="row">
                <div className="col-md-12 col-xl-12">
                  {/* Nav tabs */}
                  <div className="card">
                    <div className="card-header">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                          >
                            ईनाम
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                          >
                            मेरी रिपोर्ट
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#messages"
                            role="tab"
                          >
                            सहायता
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body p-15">
                      {/* Tab panes */}
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="home"
                          role="tabpanel"
                        >
                          <div className="row ">
                            <div className="col-md-6 col-lg-4  box-equal">
                              <div className="profile-enam padding-set-a">
                                <div className=" d-flex align-items-center">
                                  <div className="content-profile ">
                                    <h2 className="font-weight-bold inam-box-headding">
                                      {star}
                                      <span>
                                        <img
                                          src="assets/img/general/start-p.svg"
                                          alt=""
                                        />
                                      </span>
                                    </h2>
                                    <h6 className="inam-box-headding-6">
                                      तारे इकट्ठा किए
                                    </h6>
                                    <NavLink
                                      to="/board-screen"
                                      className="btnset btn-of-profile-t"
                                    >
                                      <span>और इकट्ठा करें </span>{" "}
                                    </NavLink>
                                  </div>
                                  <div className="img-profile">
                                    <img
                                      src="assets/img/general/start-p.svg"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-4  box-equal">
                              <div className="profile-enam profile-color-b padding-set-a">
                                <div className=" d-flex align-items-center">
                                  <div className="content-profile">
                                    <small className="start-box-h">
                                      स्तर :
                                    </small>
                                    <h2 className="inam-box-headding">
                                      {" "}
                                      {award}{" "}
                                    </h2>

                                  </div>
                                  <div className="img-profile">
                                    <img
                                      src="assets/img/general/seeld.svg"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-4 box-equal">
                              <div className="profile-enam profile-color-c">
                                <h4 className="text-white text-center sthan-headding">
                                  स्थान
                                </h4>
                                <table className="table profile-table">
                                  <tbody>
                                    {userstars.map((item) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>
                                              {" "}
                                              <img
                                                className="seeld-img"
                                                src="assets/img/general/seeld.svg"
                                                alt=""
                                              />{" "}
                                            </td>
                                            <td>
                                              <div className="name-pro-table d-flex">
                                                <div className="name-icon">
                                                  C
                                                </div>
                                                <div>{item.name}</div>
                                              </div>
                                            </td>
                                            <td>
                                              <center>
                                                <img
                                                  style={{ width: "1.5vw" }}
                                                  src="assets/img/general/start-p.svg"
                                                  alt=""
                                                />
                                              </center>
                                            </td>
                                            <td>{item.total_stars}</td>
                                            <td>S</td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="profile" role="tabpanel">
                          <div className="bg-activity">
                            <div className="row align-items-center justify-content-center">
                              <div className="col-md-2 text-center">
                                <div className="set-activity">
                                  <h4>{report.total_complete_activity}</h4>
                                  <p>एक्टिविटी पूरी हुई </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-lg-3 text-center">

                                      <div className="">
                                        <div className="card d-flex">
                                          <div className="percent">
                                            <svg>
                                              <circle
                                                cx="105"
                                                cy="105"
                                                r="80"
                                              ></circle>
                                              <circle
                                                cx="105"
                                                cy="105"
                                                r="80"
                                                style={{
                                                  "--percent":
                                                    report.complete_activity_percent,
                                                }}
                                              ></circle>
                                            </svg>
                                            <div className="number">
                                              <h3 className="percentage-design">
                                                {
                                                  report.complete_activity_percent
                                                }
                                                <span>%</span>
                                              </h3>
                                              <p className="carikulam">
                                                करीकुलम पूरा हुआ
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                              </div>
                              <div className="col-md-2 text-center">
                                <div className="set-activity">
                                  <h4 className="red">
                                    {report.total_incomplete_activity}
                                  </h4>
                                  <p>एक्टिविटी बची हैं</p>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-2 text-center">
                                <NavLink to="/avtar" className="btnset m-auto myreport-btn">
                                प्रोफ़ाइल सेट करें{" "}
                                </NavLink>
                              </div>
                              {
                                (report.complete_activity_percent === 100) ?

                                <div className="col-md-12 col-lg-2 text-center ml-2">
                                <button onClick={certificateUrl} to="#" className="btnset m-auto myreport-btn">
                                प्रमाणपत्र
                                </button>
                              </div>:""

                              }


                            </div>
                            <div className="row mt-30 row-set-equal">
                              {activi_all.map((act, index) => {
                                var compStatus = "";
                                var linkUrl = "";
                                if (act.complete_per === 100) {
                                  linkUrl = "";
                                  compStatus = "कम्पलीट";
                                } else {
                                  linkUrl = "/board-screen";
                                  compStatus = "अधूरा";
                                }
                                return (
                                  <>
                                    <div className="col-md-6 col-lg-3 mt-m-2 row-set-equal">
                                      <div className="meri-report-box">
                                        <div className="d-flex align-items-center">
                                          <div className="mr-img">
                                            <img
                                              src="assets/img/general/baja-single.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div className="hamara-nara-wi">
                                            <h2 className="title-box-tab">
                                              {act.activity_name}
                                            </h2>
                                          </div>
                                          <div className=" text-center">
                                            <NavLink
                                              className="complete-btn "
                                              to={linkUrl}
                                            >
                                              {compStatus}
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className="pro-set-box">
                                          <div className="w3-light-grey">
                                            <div
                                              className="w3-grey"
                                              style={{
                                                height: "4px",
                                                width: act.complete_per + "%",
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="messages" role="tabpanel">
                          <form action="#" method="post" onSubmit={submitHelp}>
                            <div className="bg-activity overflowset">
                              <div className="help1 d-flex">
                                <div className="float-left mr-15">
                                  <img
                                    className="c-img-m"
                                    src="assets/img/on-boarding/btn/sunita.svg"
                                    alt=""
                                  />
                                </div>
                                <div className="mr-50">
                                  <h6 className="sunita">पिंकी</h6>
                                  <p className="mpara large-para">फैसिलिटेटर</p>
                                </div>
                                <div>
                                  <div className="meri-report-box shayeta-box-p">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <img
                                          className="mr-img"
                                          src="assets/img/general/pinky.svg"
                                          alt=""
                                        />
                                      </div>
                                      <div className="hamara-nara-wi">
                                        <h5>
                                          समस्या का समाधान जानने के लिए सवाल
                                          पूछें
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="help1 d-flex mt-20">
                                <h6 className="h6 mr-30 text-black">
                                  (1) समाधान पूर्ण{" "}
                                </h6>
                                <h6 className="h6 text-black">
                                  (2) जारी शिकायत
                                </h6>
                              </div>
                              {/* <div class="help2">
                                      gfdgfsgfs

                                  </div> */}
                              <div className="border-box">
                                <div className="content">
                                  <div className="row ">
                                    <div className="col-md-12 col-lg-4">


                                      <div className="input-group">
                                        <label className="levelset ">
                                          कौन सी एक्टिविटी है{" "}
                                        </label>

                                        <div className="input  right" >
                                          <select
                                            className="set-input"
                                            value={activityName}
                                            onChange={getActivity}
                                            required
                                          >
                                            <option value=" " >एक्टिविटी</option>
                                            <option value="khelo">खेलो</option>
                                            <option value="karo">करो</option>
                                            <option value="dekho">देखो</option>
                                            <option value="suno">सुनो</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-lg-4">
                                      <div className="input-group">
                                        <label className="levelset ">
                                          एक्टिविटी का नाम क्या है{" "}
                                        </label>
                                        <div className="input right">
                                          <select

                                            className="set-input"
                                            value={yourActivityName}
                                            onChange={(e) =>
                                              setYourActivityName(
                                                e.target.value
                                              )
                                            }

                                            required
                                          >

                                        <option  value=" " >एक्टिविटी का नाम चुनें</option>

                                            {
                                              allActivity.map((act)=>{
                                                return(
                                                  <>
                                                    <option value={act.activity_name}>
                                                      {act.activity_name}
                                                  </option>
                                                  </>
                                                )
                                              })
                                            }

                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-lg-4">
                                      <div className="input-group">
                                        <label className="levelset ">
                                          विषय{" "}
                                        </label>
                                        <div className="input right">
                                          <select

                                            className="set-input"
                                            value={subject}
                                            onChange={(e) =>
                                              setSubject(e.target.value)
                                            }

                                            required
                                          >

                                          <option disabled value=" " >विषय का नाम चुनें</option>

                                            <option value="समझने में मुश्किल">
                                              समझने में मुश्किल
                                            </option>
                                            <option value="पढने में मुश्किल">
                                            पढने में मुश्किल
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="input-group mt-20">
                                        <label className="levelset ">
                                          विवरण{" "}
                                        </label>

                                        <div className="input right height-100">
                                          <textarea
                                            className="set-input ml-20"
                                            id="w3review"
                                            name="w3review"
                                            rows={4}
                                            cols={50}
                                            placeholder="खेलो"
                                            defaultValue={""}
                                            onChange={(e) =>
                                              setDesc(e.target.value)
                                            }

                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="d-flex d-change-m mt-30">
                                      <span className="helppagetimer">00:{counter}</span>
                                      <audio src={audioURL} controls />

                                      <div className="d-flex">
                                        <RecordingStart />
                                        <RecordingStop />
                                        <div className="recording-btn-profile">
                                          <label className="filelabel">
                                            <img
                                              src="assets/img/on-boarding/btn/attech.svg"
                                              alt=""
                                            />
                                            <span className="title">
                                              स्क्रीनशॉट अटैच करें
                                            </span>
                                            <input
                                              className="FileUpload1"
                                              id="FileInput"
                                              name="booking_attachment"
                                              type="file"

                                              onChange={uploadAttachmentFile}
                                            />
                                          </label>

                                          <label><b>{imageName}</b></label>
                                        </div>
                                      </div>



                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="border-line" />
                                    </div>
                                    <div className="col-md-12">
                                      <div className="input-group">
                                        <label className="levelset ">
                                          कोई और समस्याएं
                                        </label>
                                        <div className="input right">
                                          <select
                                            name="cars"
                                            className="set-input"
                                            value={problem}
                                            onChange={(e) =>
                                              setProblem(e.target.value)
                                            }

                                            required
                                          >

                                            <option disabled value="">विषय का नाम चुनें</option>

                                            <option value="समझने में मुश्किल">
                                              समझने में मुश्किल
                                            </option>
                                            <option value="पढने में मुश्किल">
                                            पढने में मुश्किल
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="input-group mt-20">
                                        <label className="levelset ">
                                          कमेन्ट लिखें{" "}
                                        </label>
                                        <div
                                          className="input right"
                                          style={{ height: 100 }}
                                        >
                                          <textarea
                                            className="set-input ml-20"
                                            id="w3review"
                                            name="w3review"
                                            rows={4}
                                            cols={50}
                                            placeholder="खेलो"
                                            defaultValue={comment}
                                            onChange={(e) =>
                                              setComment(e.target.value)
                                            }

                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="d-flex mt-30">
                                        <div className=" ">
                                          <button
                                            type="submit"
                                            className="btnset m-auto"

                                          >
                                            टिकट अपलोड करें{" "}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default UserProfile;
