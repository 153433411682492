import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FlashMessage from "../FlashMessage";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import HomeMenu from '../common/HomeMenu';
import HomeMenuOuter from "../common/HomeMenuOuter";

const Otp = () => {
  const signup_details = JSON.parse(
    localStorage.getItem("student_signup_details")
  );
  const navigate = useNavigate();
  const [inputValue, setIputValue] = useState();
  const [inputValueErr, setinputValueErr] = useState({});
  const [buttondisable,setButtonDisabled] = useState(false);

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const matchOtp = (e) => {
    e.preventDefault();
    const isValid = formValidation();



    const data = JSON.stringify({
      message_id: signup_details.email_message_id,
      otp: inputValue,
    });

    var config = {
      method: "post",
      url: BaseURL+"/forgotPassword_OTP_Match",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
          if(response.data.success === true)
          {
            setButtonDisabled(true);

            var data = JSON.stringify({
                "fullName": signup_details.fullName,
                "dob": signup_details.dob,
                "mobemail": signup_details.email,
                "email":signup_details.email,
                "username": signup_details.username,
                "password": signup_details.password,
                "type": signup_details.type,
                "gender": signup_details.gender,
                "registration_type":signup_details.registration_type,
                "auth_provider":"without_google"
                });

                var config = {
                method: 'post',
                url: BaseURL+'/studentRegisterAPI',
                headers: {
                    'Content-Type': 'application/json'
                },
                data : data
                };

                axios(config).then( (response)=> {
                    // console.log(response.data.success);
                    if(response.data.success=== true)
                    {
                      setButtonDisabled(true);

                        const userId = response.data.data.id;
                        const name = response.data.data.name;
                        const user_unique_id = response.data.data.user_unique_id;
                        const token = response.data.token;
                        const role_id = response.data.data.role_id;
                        const logUsername = response.data.data.logUsername;

                        localStorage.removeItem("student-details")
                        localStorage.removeItem("student_signup_details")

                        const userData = {"token":token,"user_id":userId,"name":name,"user_unique_id":user_unique_id,"role_id":role_id,'logUsername':logUsername};
                        localStorage.setItem('user_details',JSON.stringify(userData))

                        // if(signup_details.type === 'student')
                        // {

                        //   navigate('/acceptance');
                        // }else{
                        //   navigate('/anumati');

                        // }
                        navigate('/register-success');
                    }else{

                        setMessage(response.data.message);
                        setType('danger');
                        // console.log('falso');
                    }

                }).catch(function (error) {
                console.log(error);
                });

            //   console.log(response);
            // navigate('/reset-password');

          }else{
            setButtonDisabled(false);
            setMessage(response.data.message);
            setType("danger");
          }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   // form validation
  const formValidation = () => {
    const inputvalueErr = {};

    const inputValue1 = inputValue === undefined ? "" : inputValue;

    let isValid = true;

    // console.log(mobEmail.length);
    if (inputValue1.length === 0) {
      inputvalueErr.required = "Field Is Required";
      isValid = false;
    }

    setinputValueErr(inputvalueErr);

    return isValid;
  };

  //   console.log(forgetdetails.message);

  return (
    <>
      <form method="" onSubmit={matchOtp}>
        <main>
          <section className="bg-flexible">

          <HomeMenuOuter  />
          <div class="data-adjust">
            <div className="container-fluid cf-set">

              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                <FlashMessage type={type} message={message} />
                  <h3
                    className="main-headding wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    ओ.टी.पी.
                  </h3>

                  <div
                    className="input-group wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    {/* <FlashMessage type="success" message={forgetdetails.message} /> */}
                    <label
                      className="levelset "
                    >
                     ब्रेकथ्रू डिजिटल टी.के.टी. पर रजिस्ट्रेशन करने के लिए, आपके द्वारा दर्ज किये गए ईमेल या मोबाइल नंबर पर भेजे गए ओ.टी.पी. को दिए गये बॉक्स में भरें।  ओ.टी.पी. दे कर आप टी.के.टी. वेबसाइट/मोबाइल एप्लीकेशन पर हमें आपके लिए खाता बनाने की अनुमति देते हैं।  अब आगे बढ़ें
                    </label>



                    <div className="input right">
                      <input
                        type="text"
                        className="set-input"
                        placeholder=" "
                        value={inputValue}
                        onChange={(e) => {
                          setIputValue(e.target.value);
                        }}
                      />
                    </div>

                    {Object.keys(inputValueErr).map((key) => {
                      return (
                        <span className="error">{inputValueErr[key]}</span>
                      );
                    })}
                  </div>

                  <div
                    className="mt-15 mb-15"

                  >
                    <button type="submit" disabled={buttondisable} className="btnset blue-shadow mt-m-1 ">
                      {" "}
                      <span>आगे बढ़ेंं </span>
                    </button>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mt-50"
                      width="95%"
                      src="assets/img/general/sign-up2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};

export default Otp;
