import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna08KheloActivity = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20">
                <div className="col col-lg-6 ">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    कैसे खेलें ?
                  </h2>
                </div>
                <div className="col col-lg-6">
                  <NavLink
                    className="float-right width-auto"
                    to="/khelo-08-activity-02-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                  <p
                    className="mpara mt-m-1 large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {" "}
                    आपसे कुछ सवाल किए जाएंगे और हर सवाल का जवाब देने के लिए आपके
                    पास तीन विकल्प होंगे,
                  </p>
                  <div
                    className="gkw-img cursor-remove d-flex mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <div className="mr-30">
                      <NavLink to="#">
                        <center>
                          <img
                            src="assets/img/khelo/icon/kkkn-ladka.svg"
                            alt=""
                          />
                        </center>
                        <p className="side-btn">लड़का</p>
                      </NavLink>
                    </div>
                    <div className="mr-30">
                      <NavLink to="#">
                        <center>
                          <img
                            src="assets/img/khelo/icon/kkkn-ladki.svg"
                            alt=""
                          />
                        </center>
                        <p className="side-btn">लड़की</p>
                      </NavLink>
                    </div>
                    <div className="">
                      <NavLink to="#!">
                        <center>
                          <img
                            src="assets/img/khelo/icon/kkkn-dono.svg"
                            alt=""
                          />
                        </center>
                        <p className="side-btn">दोनों</p>
                      </NavLink>
                    </div>
                  </div>
                  <p
                    className="mpara large-para mt-m-1 fs-30 mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    आपको जो भी सही लगे आप उस पर क्लिक करें और अपना जवाब दें।
                  </p>
                </div>
                <div className="col-lg-4 offset-lg-1">
                  <div
                    className="six-tare wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p>
                      इस एक्टिविटी को पूरा करने पर आपको 14 तारे मिल सकते हैं। हर
                      सही जवाब के लिए आपको एक तारा मिलेगा
                    </p>
                    <ul className="ul-r align-left">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>X 14</h4>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d"
                      to="/khelo-08-activity-03-screen"
                    >
                      शुरू करें
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna08KheloActivity;
