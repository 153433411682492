import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FlashMessage from "../FlashMessage";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const SignUpOtp = () => {
  const forgetdetails = JSON.parse(
    localStorage.getItem("forgot_password_details")
  );
  const navigate = useNavigate();
  const [inputValue, setIputValue] = useState();
  const [inputValueErr, setinputValueErr] = useState({});

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const auth = localStorage.getItem("user_details")

  const matchOtp = (e) => {
    e.preventDefault();
    const isValid = formValidation();

    const data = JSON.stringify({
      message_id: forgetdetails.email_message_id,
      otp: inputValue,
    });

    var config = {
      method: "post",
      url: BaseURL + "/forgotPassword_OTP_Match",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate("/reset-password");
        } else {
          setMessage(response.data.message);
          setType("danger");
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   // form validation
  const formValidation = () => {
    const inputvalueErr = {};

    const inputValue1 = inputValue === undefined ? "" : inputValue;

    let isValid = true;

    // console.log(mobEmail.length);
    if (inputValue1.length === 0) {
      inputvalueErr.required = "Field Is Required";
      isValid = false;
    }

    setinputValueErr(inputvalueErr);

    return isValid;
  };

  //   console.log(forgetdetails.message);

  return (
    <>
      <form method="" onSubmit={matchOtp}>
        <main>
          <section className="bg-flexible privany-bg-fixed">
          {(auth)?<HomeMenu />:<HomeMenuOuter/>}

            <div class="data-adjust2">
              <div className="container-fluid cf-set">
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-12">
                    <div className="the-content mt-3 privacy-para">
                      <h3>उपयोग करने के नियम व शर्तें (टीकेटी के लिए)</h3>
                      <p>
                        ब्रेकथ्रू ट्रस्ट एक ऐसी संस्था है जो मानव अधिकारों के
                        बारे में जन जागरूकता और संवाद को बढ़ावा देने के लिए
                        शिक्षा और लोकप्रिय संस्कृति का उपयोग करती है। ब्रेकथ्रू
                        ने किशोर-किशोरी सशक्तिकरण से संबंधित मुद्दों पर जागरूकता
                        और क्षमता निर्माण कार्यक्रम आयोजित करने, बाल विवाह को
                        रोकने, जेंडर भेदभाव और हिंसा को रोकने और इसी तरह के
                        कार्यक्रमों के लिए -
                        <a href="https://genderonline.org">
                          https://genderonline.org
                        </a>{" "}
                        वेबसाइट और टीकेटी आईओएस और एंड्रॉइड ऐप मोबाइल एप्लीकेशन
                        बनाई है। हम व्यक्तियों और समुदायों को मीडिया, इंटरनेट,
                        मंचों और शैक्षिक सामग्री के माध्यम से मानवाधिकारों की
                        संस्कृति को बढ़ावा देने में शामिल होने और नीचे दिए गए
                        नियमों और शर्तों के अनुसार{" "}
                        <a href="https://genderonline.org">
                          https://genderonline.org
                        </a>
                        (इसके बाद वेबसाइट कहा जायेगा ) और टीकेटी आईओएस और
                        एंड्रॉइड ऐप ( इसके बाद मोबाइल एप्लिकेशन कहा जायेगा) का
                        उपयोग करने के लिए प्रोत्साहित करते हैं।
                      </p>
                      <p>
                        ये नियम और शर्तें सभी विज़िटर्स,उपयोगकर्ताओं और अन्य
                        लोगों पर लागू होती हैं जो वेबसाइट और मोबाइल एप्लिकेशन की
                        सेवा का उपयोग करते हैं और वेबसाइट और मोबाइल एप्लिकेशन के
                        उपयोग के लिए नियमों और विनियमों की रूपरेखा तैयार करते
                        हैं । कृपया उपयोग की इन शर्तों को ध्यान से पढ़ें।
                      </p>
                      <p>
                        यह वेबसाइट 18 वर्ष से कम आयु के किसी भी व्यक्ति के लिए
                        उपलब्ध नहीं है जिसका पंजीकरण माता-पिता या क़ानूनी अभिभावक
                        द्वारा अप्रूव नहीं किया गया है। आपके द्वारा "स्वीकार है" बटन दबाकर या किसी दूसरे तरीक़े से इस वेबसाइट पर
                        अकाउंट का उपयोग या पंजीकरण करना, इस बात की पुष्टि करता
                        हैं कि इन सेवाओं का उपयोग करने के लिए आपके माता-पिता या
                        कानूनी अभिभावक द्वारा अप्रूव किया गया है, और यह भी कि
                        आपका पंजीकरण और सेवाओं का उपयोग सभी लागू कानूनों का पालन
                        करता है।
                      </p>
                      <p>
                        अगर आप माता-पिता या क़ानूनी अभिभावक हैं और आप अपने बच्चे
                        को वेबसाइट का उपयोग करने की सहमति देते हैं, तो आप बच्चे
                        द्वारा वेबसाइट के उपयोग के संबंध में सेवा शर्तों को
                        मानने के लिए भी सहमत हैं।
                      </p>
                      <p>
                        आपके द्वारा वेबसाइट और मोबाइल एप्लीकेशन का इस्तेमाल करने
                        से, हम यह मानते हैं कि आप इन नियमों और शर्तों को स्वीकार
                        करते हैं। यदि आप इस पेज पर बताए गए सभी नियमों और शर्तों
                        से सहमत नहीं हैं तो कृपया इस वेबसाइट का उपयोग करना जारी
                        न रखें।
                      </p>
                      <p>
                        वेबसाइट और मोबाइल एप्लिकेशन तक आपकी पहुंच और उपयोग, आपके
                        द्वारा हमारी गोपनीयता नीति की स्वीकृति और पालना मानी
                        जाएगी, जो आपकी व्यक्तिगत जानकारी के संग्रह, उपयोग और
                        साझा करने के बारे में हमारी नीतियों और प्रक्रियाओं का
                        वर्णन करती है जब आप वेबसाइट और मोबाइल एप्लिकेशन का उपयोग
                        करते हैं और यह आपको आपके गोपनीयता संबंधी अधिकारों के
                        बारे में बताता है। इसलिए, हमारी सेवा का उपयोग करने से
                        पहले कृपया हमारी गोपनीयता नीति को ध्यान से पढ़ें।
                      </p>

                      <h3>वेबसाइट का उपयोग; </h3>
                      <p>
                        वेबसाइट और सेवाएं आपको शैक्षणिक उद्देश्यों के लिए दी
                        जाती हैं और आपको सभी लागू कानूनों, नियमों का पालन करते
                        हुए वेबसाइट और सेवाओं का उपयोग करना चाहिए। आप इस वेबसाइट
                        और मोबाइल एप्लिकेशन का उपयोग किसी भी उद्देश्य के लिए जो
                        गैरकानूनी है या किसी ऐसे आचरण में संलग्न है जो हमारे
                        अधिकारों का उल्लंघन करता है या हमारे या किसी अन्य
                        वेबसाइट और मोबाइल एप्लिकेशन के उपयोग और आनंद को
                        प्रतिबंधित या बाधित करता है, नहीं करने के लिए सहमत हैं।
                        आप विशेष रूप से, इस वेबसाइट और मोबाइल एप्लिकेशन की
                        सामग्री, सॉफ़्टवेयर, टेक्स्ट, ग्राफ़िक्स, लेआउट या
                        डिज़ाइन में परिवर्तन, संशोधन, पुनरुत्पादन, सार्वजनिक रूप
                        से संवाद नहीं कर सकते हैं।
                      </p>
                      <p>
                        ब्रेकथ्रू ट्रस्ट वेबसाइट और मोबाइल एप्लिकेशन के उपयोग से
                        होने वाले किसी भी नुकसान के लिए ज़िम्मेदार नहीं है। आप
                        अवैध उद्देश्यों के लिए वेबसाइट और मोबाइल एप्लिकेशन का
                        उपयोग नहीं करेंगे। आप (1) वेबसाइट और मोबाइल एप्लिकेशन
                        (बौद्धिक संपत्ति से संबंधित कानूनों सहित) के अपने उपयोग
                        के दौरान लागू होने वाले सभी स्थानीय, प्रांतीय, राष्ट्रीय
                        और अंतर्राष्ट्रीय कानूनों और नियमों विनियमों का पालन
                        करेंगे , (2) अन्य उपयोगकर्ताओं द्वारा वेबसाइट और मोबाइल
                        एप्लिकेशन के उपयोग में हस्तक्षेप या बाधा नहीं डालेंगे
                        (3) प्रत्यक्ष या अप्रत्यक्ष रूप से, "स्पैम", चेन
                        लेटर/पत्र श्रंखला, जंक मेल या किसी अन्य प्रकार के
                        अवांछित संचार के प्रसारण में शामिल नहीं हैं, और (4)
                        वेबसाइट और मोबाइल एप्लिकेशन के अन्य उपयोगकर्ताओं की
                        बदनामी, उत्पीड़न, दुर्व्यवहार या सर्विस बाधित नहीं करते
                        हैं।
                      </p>

                      <h3>बौद्धिक संपत्ति </h3>
                      <p>
                        यह वेबसाइट और मोबाइल एप्लिकेशन कॉपीराइट, ट्रेडमार्क और
                        भारत के अन्य कानूनों द्वारा संरक्षित है। इस साइट पर
                        शामिल सभी सामग्री, जैसे कि टेक्स्ट, ग्राफिक्स, लोगो, बटन
                        आइकन, चित्र, ऑडियो क्लिप, डिजिटल डाउनलोड, डेटा संकलन और
                        सॉफ्टवेयर, ब्रेकथ्रू ट्रस्ट या इसके सामग्री
                        आपूर्तिकर्ताओं (कंटेन्ट सप्लायर्स) की संपत्ति हैं और इस
                        वेबसाइट का कोई भी भाग ब्रेकथ्रू ट्रस्ट की लिखित सहमति के
                        बिना किसी व्यावसायिक उद्देश्य के लिए दुबारा उपयोग,
                        डुप्लिकेट, कॉपी या अन्य किसी तरीके से उपयोग नहीं किया
                        जाना चाहिए। ट्रस्ट की पूर्व लिखित सहमति के बिना किसी भी
                        उत्पाद या सेवा के संबंध में ट्रेडमार्क और कॉपीराइट की गई
                        हमारी सामग्री का उपयोग नहीं किया जा सकता है।
                      </p>
                      <p>
                        एक बच्चे या किशोर उपयोगकर्ता की ओर से एक अकाउंट को
                        पंजीकृत/रजिस्टर और प्रबंधित करने के लिए आप प्रतिनिधित्व
                        करते हैं, कि आप कम से कम 18 वर्ष की आयु के हैं और एक
                        बच्चे के कानूनी माता-पिता या अभिभावक हैं जो हमारी
                        वेबसाइट और मोबाइल एप्लिकेशन की सेवाओं का उपयोग करना
                        चाहते हैं। जब आप किसी बच्चे/किशोर उपयोगकर्ता के पंजीकरण
                        करते हैं या, पंजीकरण के लिए अनुमोदन/अप्रूवल या सहमति
                        देते हैं, या अन्यथा जिम्मेदारी लेते हैं, तो आप
                        प्रतिनिधित्व करते हैं और वचन देते हैं कि आप ऐसे बच्चे
                        उपयोगकर्ता के माता-पिता या कानूनी अभिभावक हैं और आप बाल
                        उपयोगकर्ता की तरफ से, इस तरह की शर्तों से बाध्य होने के
                        लिए सहमत हैं जिसमें बिना किसी सीमा के बाल/किशोर
                        उपयोगकर्ता द्वारा वेबसाइट और मोबाइल एप्लिकेशन के सभी तरह
                        के उपयोग के लिए जिम्मेदार होना शामिल है।
                      </p>
                      <p>
                        आप स्वीकार करते हैं कि ब्रेकथ्रू ट्रस्ट सीधे या तीसरे
                        पक्ष के माध्यम से कोई पूछताछ कर सकता है, लेकिन इसके लिए
                        बाध्य नहीं है, जहां सत्यापन करने के लिए तीसरे पक्ष को
                        शामिल करने सहित आपकी पंजीकरण जानकारी को सत्यापित करना
                        आवश्यक है। हम किसी भी व्यक्ति के खिलाफ कानूनी कार्रवाई
                        करने के सभी अधिकार सुरक्षित रखते हैं जो व्यक्तिगत
                        जानकारी को गलत तरीके से प्रस्तुत करता है या किसी तरह से
                        उनकी पहचान के बारे में गलत/झूठ है। आप स्वीकार करते हैं
                        कि ब्रेकथ्रू ट्रस्ट किसी भी उपयोगकर्ता द्वारा दी गई किसी
                        भी जानकारी के सही होने की गारंटी नहीं दे सकता है और
                        उपयोगकर्ता द्वारा पंजीकरण के दौरान माता-पिता या कानूनी
                        अभिभावक होने के किसी भी दावे सहित किसी भी उपयोगकर्ता की
                        पहचान सत्यापित करने के लिए बाध्य नहीं है, ऐसे बाल
                        उपयोगकर्ता के वास्तविक माता-पिता या अभिभावक हैं
                        ब्रेकथ्रू ट्रस्ट द्वारा किसी भी समय इन शर्तों का पालन
                        नहीं होने पर बिना किसी पूर्व सूचना/ चेतावनी के अकाउंट को
                        बंद किया जा सकता है।
                      </p>
                      <h3>मानी गई सहमति:</h3>
                      <p>
                        जब भी माता-पिता या कानूनी अभिभावक किसी बच्चे/किशोर
                        उपयोगकर्ता की ओर से अकाउंट पंजीकृत/रजिस्टर कर रहे हैं,
                        तो एक ओटीपी भेजा जाएगा और माता-पिता/कानूनी अभिभावक
                        द्वारा दिए गए मोबाइल नंबर/ईमेल पर भेजा जाएगा। पंजीकरण
                        पूरा करने के लिए ओटीपी भरने के बाद, माता-पिता/कानूनी
                        अभिभावक को बच्चे/किशोर द्वारा वेबसाइट और मोबाइल
                        एप्लिकेशन के उपयोग और इसके कंटेन्ट को देखने के लिए सहमति
                        माना जाता है।
                      </p>

                      <h3>उपयोगकर्ता खाते/यूजर अकाउंट :</h3>
                      <p>
                        वेबसाइट और मोबाइल एप्लिकेशन के माध्यम से/ पर एक अकाउंट
                        बनाते हैं, तो आप हमें हर समय सटीक, पूरी और नवीनतम
                        जानकारी देने के लिए सहमत होते हैं। ऐसा नहीं करने पर
                        शर्तों का उल्लंघन होता है, जिसके परिणामस्वरूप हमारी
                        वेबसाइट और मोबाइल एप्लिकेशन पर आपका अकाउंट तुरंत बंद
                        किया जा सकता है। आप उस पासवर्ड की सुरक्षा के लिए
                        ज़िम्मेदार हैं जिसका उपयोग आप इस सर्विस तक पहुँचने के
                        लिए करते हैं और अपने पासवर्ड के तहत किसी भी गतिविधि या
                        कार्यों को करते हैं, चाहे आपका पासवर्ड हमारी पास या किसी
                        तीसरे-पक्ष की सोशल मीडिया सर्विस के पास हो। आप सहमत हैं
                        कि आप अपना पासवर्ड किसी तीसरे पक्ष को नहीं बताएंगे।
                        सुरक्षा के किसी भी उल्लंघन या आपके अकाउंट के अनधिकृत
                        उपयोग के बारे में पता चलने पर आपको तुरंत हमें सूचित करना
                        चाहिए। आप एक यूजर नेम के रूप में किसी अन्य व्यक्ति या
                        संस्था के नाम का उपयोग नहीं कर सकते हैं या जो कानूनी रूप
                        से उपयोग के लिए उपलब्ध नहीं है, एक नाम या ट्रेडमार्क जो
                        आपके अलावा किसी अन्य व्यक्ति या संस्था के अधिकारों के
                        अधीन है, या ऐसा नाम जिसपे आपका अधिकार नहीं है किसी तरीके
                        से आपत्तिजनक, अश्लील या कामुक है।
                      </p>
                      <h3>डाउनलोड करने योग्य सामग्री</h3>
                      <p>
                        हमारी सेवाएं आपको मोबाइल एप्लिकेशन या कुछ डिजिटल
                        शैक्षणिक सामग्री डाउनलोड करने की अनुमति दे सकती हैं।
                        ब्रेकथ्रू ट्रस्ट आपको यहां निर्धारित सभी नियमों और
                        शर्तों के साथ वस्तु कोड के रूप में, आपके स्वामित्व या
                        आपके द्वारा नियंत्रित उपकरणों पर, केवल आपके व्यक्तिगत,
                        गैर-वाणिज्यिक उद्देश्यों के लिए आपके पूर्ण और अभी चल रहे
                        अनुपालन के अधीन, डाउनलोड करने योग्य कंटेन्ट को डाउनलोड
                        करने, इंस्टॉल करने, देखने और उपयोग करने के लिए एक सीमित,
                        गैर-विशिष्ट, गैर-हस्तांतरणीय, गैर-उप-लाइसेंस योग्य, खंडन
                        करने योग्य लाइसेंस प्रदान करता है। आप सहमत नहीं हैं
                        <ul>
                          <li>
                            • (i) डाउनलोड करने योग्य सामग्री के शाधित कार्यों को
                            संशोधित या बनाने के लिए
                          </li>
                          <li>
                          • (ii) डाउनलोड करने योग्य सामग्री की सुरक्षा,उसकी कॉपी
                            होने से सुरक्षा, अधिकार प्रबंधन, तकनीकी सीमाओं या
                            सुरक्षा सुविधाओं को हटाने, बंद करने, धोखा देने या और
                            किसी तरह से बनाने या लागू करने के लिए, और
                          </li>
                          <li>
                          • (iii) डाउनलोड करने योग्य सामग्री और उसकी सभी पर किसी
                            भी कॉपीराइट और अन्य मालिकाना नोटिस को हटा दें।
                          </li>
                        </ul>
                      </p>
                      <h3>कंटेन्ट की जिम्मेदारी </h3>
                      <p>
                        जबकि हमने इस वेबसाइट और मोबाइल एप्लिकेशन पर सही जानकारी
                        प्रदान करने में सभी उचित सावधानी बरती है, हम इस वेबसाइट
                        और मोबाइल एप्लिकेशन पर दिखाई देने वाली सामग्री की
                        सटीकता, पूर्णता, समयबद्धता या किसी विशेष उद्देश्य के लिए
                        उपयुक्तता के संबंध में कोई वारंटी या अन्य आश्वासन नहीं
                        देते हैं, और हम किसी भी अशुद्धि, त्रुटि, चूक, या किसी
                        अन्य प्रकार की अपर्याप्तता, कमी, या दोष के लिए या इस
                        वेबसाइट और इसकी सबसाइट्स,एप्लिकेशन, तीसरे पक्ष और बाहरी
                        लिंक सहित इसमें निहित जानकारी के संबंध में उत्तरदायी
                        नहीं होंगे; हम इस बात की गारंटी नहीं दे सकते कि ये लिंक
                        हर समय काम करेगा और लिंक किए गए पेजों की उपलब्धता पर
                        हमारा कोई नियंत्रण नहीं है। आप जिस भी किसी व्यक्ति या
                        संस्था के प्रति किसी भी प्रकार के जानकारी पर भरोसा करना
                        चुनते हैं तो उसके लिए सभी प्रकार के जिम्मेदारियों से
                        हमें पूरी तरह से स्वतंत्र करने के लिए सहमत हैं। न तो हम
                        या हमारे लाइसेंसकर्ता, किसी भी परिस्थिति में, प्रताड़ना
                        कानून अनुबंध या अन्यथा किसी आय, लाभ, डेटा या बचत के
                        नुकसान के लिए या किसी अप्रत्यक्ष, आकस्मिक, परिणामी,
                        अनुकरणीय, दंडात्मक या विशेष नुकसान या क्षति के लिए
                        उत्तरदायी होंगे।बेशक,यह नुकसान वेबसाइट और मोबाइल
                        एप्लिकेशन के उपयोग या इस वेबसाइट और मोबाइल एप्लिकेशन पर
                        किसी भी जानकारी पर निर्भरता के संबंध में उत्पन्न होने
                        वाले किसी भी पक्ष (तीसरे पक्ष सहित) के कारण हुई हो। इस
                        वेबसाइट और इस से संबंधित सेवाओं के उपयोग में आपको
                        कभी-कभी रुकावट या देरी का सामना करना पर सकता है। हम
                        और/या हमारे सर्विस प्रॉवईडर इस बात की वारंटी नहीं देते
                        हैं कि यह वेबसाइट और मोबाइल एप्लिकेशन या कोई भी संबंधित
                        सेवाओं में रुकावट या देरी या डिजाइन में कमी नहीं होंगी।
                        अगर यह वेबसाइट या इस वेबसाइट के माध्यम से दी जाने वाली
                        सेवाएं किसी भी कारण से बंद, बाधित या विलंबित हो जाती
                        हैं, तो हम किसी भी उपयोगकर्ता के प्रति उत्तरदायी नहीं
                        होंगे।
                      </p>
                      <h3>सुरक्षा</h3>
                      <p>
                        हालांकि हम इस वेबसाइट में वायरस या अन्य
                        हानिकारक/दुर्भावनापूर्ण कोड को आने से रोकने का प्रयास
                        करते हैं, हम इस बात की गारंटी या वारंटी नहीं देते हैं कि
                        यह वेबसाइट, या इस पर उपलब्ध कोई भी डेटा किसी भी
                        दुर्भावनापूर्ण कोड से पूरी तरह मुक्त है। आप यह अपने
                        जोखिम और जिम्मेदारी पर सुनिश्चित करते हैं कि इस वेबसाइट
                        पर आने के लिए आप सहमत हैं और यह वेबसाइट आपके कंप्यूटर
                        सिस्टम को दुर्भावनापूर्ण कोड से हस्तक्षेप या क्षति के
                        जोखिम में नहीं डालती है। हम दुर्भावनापूर्ण कोड के कारण
                        होने वाली किसी भी क्षति या नुकसान के लिए उत्तरदायी नहीं
                        होंगे। हालांकि, हम आपकी व्यक्तिगत जानकारी की सुरक्षा की
                        रक्षा करने का प्रयास करते हैं, आप सहमत हैं कि आपके
                        प्रसारण या डेटा या आपके कंप्यूटर सिस्टम या इस वेबसाइट पर
                        निहित जानकारी तक अनधिकृत पहुंच (या बदलाव) का जोखिम रहता
                        ही है। हम इस तरह के अनधिकृत उपयोग या बदलाव के
                        परिणामस्वरूप आपको होने वाले किसी भी नुकसान के लिए किसी
                        भी प्रकार की जिम्मेदारी या उत्तरदायित्व स्वीकार नहीं
                        करते हैं। आपको मिलने वाली या आपके द्वारा भेजी सभी
                        जानकारी आपके जोखिम पर प्रसारित की जाती है, और आप इस
                        वेबसाइट और इंटरनेट के आपके उपयोग के संबंध में होने वाली
                        सभी जिम्मेदारी और जोखिमों को स्वीकार करते हैं। हम आपके
                        अपने कंप्यूटर सिस्टम में किसी भी हस्तक्षेप या क्षति के
                        लिए जिम्मेदारी स्वीकार नहीं करते हैं जो आपके द्वारा इस
                        वेबसाइट या किसी बाहरी हाइपरलिंक तक पहुँचने से हो सकती
                        है।
                      </p>

                      <h3>कुकीज़</h3>
                      <p>
                        हम कुकीज़ का उपयोग करते हैं। इस वेबसाइट और मोबाइल
                        एप्लिकेशन पर आ कर आप हमारी गोपनीयता नीति के साथ अनुबंध
                        में कुकीज़ का उपयोग करने के लिए सहमत हुए हैं। अधिकांश
                        इंटरएक्टिव वेबसाइट कुकीज़ का उपयोग करती हैं ताकि हम
                        प्रत्येक विज़िट के लिए उपयोगकर्ता के विवरण को पुनः
                        प्राप्त कर सकें। हमारी वेबसाइट पर आने वाले लोगों के लिए
                        कुछ क्षेत्रों की कार्यक्षमता को आसान बनाने के लिए कुकीज़
                        का उपयोग हमारी वेबसाइट द्वारा किया जाता है। हमारे कुछ
                        सहभागी भी कुकीज़ का उपयोग कर सकते हैं।
                      </p>

                      <h3>लाइसेंस</h3>
                      <p>
                        वेबसाइट और मोबाइल एप्लिकेशन पर सभी सामग्री के लिए
                        बौद्धिक संपत्ति पर ब्रेकथ्रू का अधिकार हैं । सभी बौद्धिक
                        संपत्ति अधिकार सुरक्षित हैं। आप इन नियमों और शर्तों में
                        निर्धारित प्रतिबंधों के अनुसार अपने स्वयं के व्यक्तिगत
                        उपयोग के लिए इसका उपयोग कर सकते हैं। आपको निम्नलिखित
                        नहीं करना चाहिए:
                        <ul className="list-set">
                          <li>
                          • वेबसाइट और मोबाइल एप्लिकेशन से कंटेंट को
                            पुनर्प्रकाशित करना।
                          </li>
                          <li>
                          • कंटेन्ट को बेचना, किराये या उप-लाइसेंस पर देना{" "}
                          </li>
                          <li>
                          • कंटेन्ट का पुनरुत्पादन, डुप्लिकेट या कॉपी करना{" "}
                          </li>
                          <li>
                          • वेबसाइट और मोबाइल एप्लिकेशन से कंटेन्ट का
                            पुनर्वितरण करना।
                          </li>
                        </ul>
                        इस वेबसाइट और मोबाइल एप्लिकेशन के उपयोगकर्ताओं को कुछ
                        मुद्दों पर राय और जानकारी पोस्ट करने और आदान-प्रदान करने
                        का अवसर दे सकते हैं। ब्रेकथ्रू वेबसाइट और मोबाइल
                        एप्लिकेशन पर उनकी उपस्थिति से पहले कमेंट्स को
                        फ़िल्टर,काट-छाट, प्रकाशित या समीक्षा नहीं करता है।
                        कमेंट्स ब्रेकथ्रू, उसके एजेंटों और/या सहयोगियों की राय
                        और विचारों को नहीं दर्शाती। कमेंट्स उस व्यक्ति के
                        विचारों और राय को दर्शाती हैं जो अपने विचार और राय पोस्ट
                        करते हैं। लागू कानूनों की सीमा के अनुसार, ब्रेकथ्रू
                        वेबसाइट और मोबाइल एप्लिकेशन पर कमेंट्स के किसी भी उपयोग
                        या पोस्ट करने या होने के परिणामस्वरूप किसी भी देनदारी,
                        नुकसान या व्यय के लिए उत्तरदायी नहीं होगा।ब्रेकथ्रू सभी
                        कमेंट्स की निगरानी करने और किसी भी कमेंट्स को हटाने का
                        अधिकार सुरक्षित रखता है जिसे अनुचित, आक्रामक या इन
                        नियमों और शर्तों के उल्लंघन का कारण माना जा सकता है। आप
                        जिम्मेदारी लेते हैं और उसका प्रतिनिधित्व करते हैं:
                        <ul className="list-set">
                          <li>
                          • हमारे वेबसाइट और मोबाइल एप्लिकेशन पर टिप्पणियां
                            पोस्ट करने के हकदार हैं और ऐसा करने के लिए आपके पास
                            सभी आवश्यक लाइसेंस और सहमति हैं;
                          </li>
                          <li>
                          • टिप्पणियाँ बिना किसी सीमा के कॉपीराइट, पेटेंट या
                            किसी तीसरे पक्ष के ट्रेडमार्क सहित किसी भी बौद्धिक
                            संपत्ति अधिकार पर आक्रमण नहीं करती हैं;
                          </li>
                          <li>
                          • टिप्पणियों में कोई भी मानहानिकारक, अपमानजनक,
                            आपत्तिजनक, अशोभनीय या अन्यथा गैर-कानूनी सामग्री नहीं
                            है जो निजता का हनन है।
                          </li>
                        </ul>
                        आप इसके द्वारा ब्रेकथ्रू को किसी भी और सभी रूपों,
                        स्वरूपों या मीडिया में अपनी किसी भी कमेंट्स का उपयोग
                        करने, पुन: पेश करने, संपादित करने और दूसरों को भी उपयोग
                        करने, पुन: पेश करने और संपादित करने के लिए एक
                        गैर-विशिष्ट लाइसेंस देते हैं।
                      </p>
                      <h3>कंटेन्ट की मोनिट्रिंग करना या हटाना </h3>
                      <p>
                        हम वेबसाइट और मोबाइल एप्लिकेशन पर किए गए किसी भी कमेंट,
                        या पोस्ट या कंटेन्ट अपलोड सहित आपके उपयोग की निगरानी या
                        संचालित करने के लिए बाध्य नहीं होंगे। हालाँकि, हम
                        कमेंट्स या कंटेन्ट को प्रकाशित करने, हटाने या संशोधित
                        करने से इनकार करने का अधिकार सुरक्षित रखते हैं, अगर हम
                        अपने विवेकाधिकार से उन्हें गलत मानते हैं या मानते हैं कि
                        वे किसी तरीके से इन उपयोग की शर्तों का उल्लंघन करते हैं।
                        आप सहमत हैं कि हमारी वेबसाइट पर आपके द्वारा पोस्ट किए गए
                        किसी भी कंटेन्ट पर हमारा अधिकार होगा, हम आपके द्वारा
                        प्रकाशित कंटेन्ट को अपने भागीदारों और हितधारकों के साथ
                        साझा करने का अधिकार सुरक्षित रखते हैं।{" "}
                      </p>
                      <h3>हमारे अधिकार और उपाय</h3>
                      <p>
                        हमारे लिए उपलब्ध अन्य अधिकारों और उपचारों पर प्रतिकूल
                        प्रभाव डाले बिना, यदि हमें लगता है कि आपने उपयोग की इन
                        शर्तों का उल्लंघन किया है, तो हम अपने विवेक से वेबसाइट
                        और मोबाइल एप्लिकेशन पर आपकी गतिविधियों को सीमित,निलंबित
                        या रद्द कर सकते हैं; आपको चेतावनी जारी करेंगे या अन्य
                        उपयोगकर्ताओं को आपके कार्यों के बारे में बताएंगे। हम
                        किसी भी लॉ एनफोरकेमेन्ट अथॉरिटी या अदालत के आदेश पर पूरी
                        तरह से सहयोग करने का अधिकार सुरक्षित रखते हैं या हमें इन
                        उपयोग की शर्तों के उल्लंघन में किसी भी कंटेन्ट को पोस्ट
                        करने वाले किसी भी व्यक्ति का पता लगाने या पहचान का
                        खुलासा करने का अनुरोध करते हैं।
                      </p>
                      <h3>सामान्य क्षतिपूर्ति</h3>
                      <p>
                        आप सभी कार्रवाइयों, दावों, कानूनी लागतों और खर्चों सहित
                        लागतों, नुकसानों, कार्यवाहियों, नुकसानों, देनदारियों, या
                        आपकी विफलता के संबंध में या उसके संबंध में उत्पन्न होने
                        वाले किसी भी व्यक्ति को हुए नुकसान या हमें और हमारे
                        द्वारा उपयोग की इन शर्तों का पालन करने के लिए की गई
                        मांगों के खिलाफ क्षतिपूर्ति करने के लिए सहमत हैं।
                      </p>

                      <h3>लॉग इन करें</h3>
                      <p>
                        10.1 आपकी लॉगिन जानकारी की सुरक्षा जहां आपको वेबसाइट और
                        मोबाइल एप्लिकेशन के किसी भी हिस्से में पंजीकरण या लॉगिन
                        करने की आवश्यकता होती है, वहां आपको सभी उपयोगकर्ता
                        नाम(यूजर-नेम)और पासवर्ड को सुरक्षित और गोपनीय रखना चाहिए
                        और उन्हें किसी तीसरे पक्ष के सामने/साथ साझा नहीं करना
                        चाहिए। आपको निम्नलिखित बातों का ध्यान रखना चाहिए:
                        <ul className="list-set">
                          <li>
                            {" "}
                            • (क) एक ऐसा पासवर्ड चुनें जो सिर्फ आपको पता हों और
                            जिसका आसानी से अनुमान नहीं लगाया जा सकता हो;
                          </li>
                          <li>• (ख) अपना पासवर्ड किसी को नहीं बताना;</li>
                          <li>
                          • (ग) किसी को भी अपना पासवर्ड देखने की अनुमति न दें या
                            जब आप इसे कंप्यूटर पर दर्ज करते हैं तो उसे देखने या
                            रिकॉर्ड करने का अवसर न दें; और
                          </li>
                          <li>• (घ) नियमित अंतराल पर अपना पासवर्ड बदलें।</li>
                        </ul>
                      </p>
                      <h3>पासवर्ड के लिए समझौता</h3>
                      <p>
                        आपको तुरंत अपना पासवर्ड बदलना चाहिए और हमें सूचित करना
                        चाहिए अगर : (क) आपको लगता हैं कि आपके पासवर्ड या आपके
                        द्वारा दिए गए सुरक्षा प्रश्नों की गोपनीयता से छेड़छाड़
                        किया गया है; या (ख) आप अपने उपयोगकर्ता नाम या पासवर्ड के
                        किसी भी अनधिकृत तरीके से इस्तेमाल के बारे में जान जाते
                        हैं। आप सहमत हैं कि हम आपके हार्डवेयर डिवाइस द्वारा बनाए
                        गए आपके उपयोगकर्ता नाम(यूजर-नेम),पासवर्ड और सत्र-विशिष्ट
                        कोड की प्रामाणिकता और अधिकार पर भरोसा करने के हकदार हैं
                        या आपके द्वारा वेबसाइट पर सबमिट किए गए अनुरोधों को
                        संसाधित करने के लिए और हम बिना किसी पूछताछ के ऐसा कर
                        सकते हैं।
                      </p>

                      <h3>
                        आपकी निजता/गोपनीयता कृपया गोपनीयता नीति पढ़ें बदलाव
                      </h3>
                      <p>
                        हम इस वेबसाइट और मोबाइल एप्लिकेशन की किसी भी जानकारी,
                        सुविधा, विशिष्टता, या इस के अन्य भाग (किसी भी समय और
                        आपको सूचित किये बिना) को जोड़ने, संशोधित करने या हटाने
                        का अधिकार सुरक्षित रखते हैं।हम इस वेबसाइट पर बदली हुई
                        शर्तों को प्रकाशित करके समय-समय पर उपयोग की इन शर्तों को
                        पूर्ण या आंशिक रूप से बदलने का अधिकार सुरक्षित रखते हैं।
                        जब हम वेबसाइट पर उपयोग की बदली हुई शर्तों को पोस्ट
                        करेंगे तो संशोधन तुरंत प्रभावी होंगे।ऐसे परिवर्तनों का
                        पता रखने के लिए आपको समय-समय पर इन उपयोग की शर्तों की
                        समीक्षा करनी चाहिए। इस तरह के प्रकाशन के बाद इस वेबसाइट
                        और मोबाइल एप्लिकेशन की आपकी निरंतर पहुंच या उपयोग की
                        बदली हुई शर्तों के लिए आपकी स्वीकृति माना जाएगा।
                      </p>

                      <h3>कंटेन्ट को पोस्ट करना </h3>
                      <p>
                        हमारी वेबसाइट और मोबाइल एप्लिकेशन आपको कंटेन्ट पोस्ट
                        करने की अनुमति देता है।लेकिन जिसे आप पोस्ट करते हैं, उस
                        कंटेन्ट की वैधता, विश्वसनीयता और उपयुक्तता के लिए आप ही
                        ज़िम्मेदार हैं। इस सेवा के माध्यम से कंटेन्ट पोस्ट करके,
                        आप ब्रेकथ्रू ट्रस्ट को ऐसी कंटेन्ट का उपयोग करने,
                        संशोधित करने, सार्वजनिक रूप से प्रदर्शित करने, दुबारा
                        बनाने और वितरित करने का अधिकार और लाइसेंस देते हैं।आपके
                        द्वारा सेवा पर या उसके माध्यम से सबमिट, पोस्ट या
                        प्रदर्शित की जाने वाली किसी भी कंटेन्ट पर आपका कोई भी या
                        सभी अधिकार आपके पास हैं और आप उन अधिकारों की सुरक्षा के
                        लिए जिम्मेदार हैं। आप सहमत हैं कि इस लाइसेंस में अन्य
                        सर्विस यूज़र्स के लिए आपकी कंटेन्ट उपलब्ध कराने के लिए
                        ब्रेकथ्रू ट्रस्ट का अधिकार शामिल है, जो इन शर्तों के
                        अधीन आपकी कंटेन्ट का इस्तेमाल भी कर सकते हैं।आप
                        प्रतिनिधित्व करते हैं और वारंटी देते हैं कि : ( i ) आपके
                        द्वारा सबमिट की गई कंटेन्ट के स्वामी आप हैं या आपके पास
                        इसे उपयोग करने का और इन शर्तों के आधार पर ब्रेकथ्रू
                        ट्रस्ट को अधिकार और लाइसेंस देने का अधिकार है, और (ii)
                        इस वेबसाइट और मोबाइल एप्लिकेशन पर या इसके माध्यम से अपनी
                        कंटेन्ट को पोस्ट करना,किसी भी व्यक्ति के गोपनीयता
                        अधिकार, प्रचार अधिकार, कॉपीराइट, अनुबंध अधिकार या किसी
                        अन्य अधिकार का उल्लंघन नहीं करता है।
                      </p>
                      <h3>कंटेन्ट पर पाबंदिया </h3>
                      <p>
                        ब्रेकथ्रू ट्रस्ट वेबसाइट और मोबाइल एप्लिकेशन युज़र्स की
                        सामग्री के लिए ज़िम्मेदार नहीं है। आप स्पष्ट रूप से
                        समझते हैं और सहमत हैं कि आप कंटेन्ट के लिए और आपके
                        अकाउंट के अंतर्गत होने वाली गतिविधियों के लिए पूरी तरह
                        से जिम्मेदार हैं, चाहे ऐसा आपके द्वारा या आपके खाते का
                        उपयोग करने वाले किसी तीसरे व्यक्ति द्वारा किया गया हो।
                        आप ऐसी कोई भी कंटेन्ट पोस्ट नहीं कर सकते हैं जो
                        गैरकानूनी, आक्रामक, धमकी देने वाली, अपमानजनक,
                        मानहानिकारक, अश्लील या आपत्तिजनक हो। ऐसी आपत्तिजनक
                        कंटेन्ट के उदाहरणों में निम्न शामिल हैं, लेकिन इन्हीं तक
                        सीमित नहीं हैं:
                        <ul className="list-set">
                          <li>
                          • झूठी जानकारी, गैरकानूनी या गैरकानूनी गतिविधि को
                            बढ़ावा देना।
                          </li>
                          <li>
                          • अपमानजनक, भेदभावपूर्ण कंटेन्ट, जिसमें धर्म, जाति,
                            यौनिक पहचान, जेन्डर, राष्ट्रीय/जातीय मूल, या अन्य
                            लक्षित समूहों(टारगेट ग्रुप्स) के बारे में संदर्भ या
                            टिप्पणी शामिल है।
                          </li>
                          <li>
                          • स्पैम, मशीन - या बेतरतीब ढंग से - उत्पन्न, अनधिकृत
                            या अवांछित विज्ञापन का गठन,
                          </li>
                          <li>
                          • किसी भी वायरस, वर्म, मैलवेयर, ट्रोजन हॉर्स, या
                            अन्य सामग्री को शामिल करना या स्थापित करना जो किसी
                            सॉफ़्टवेयर, हार्डवेयर या दूरसंचार उपकरण के कामकाज को
                            बाधित करना, क्षति पहुंचाना या सीमित करना या किसी
                            तीसरे व्यक्ति की डेटा या जानकारी को अनधिकृत रूप से
                            देखना या नुकसान पहूंचाना।
                          </li>
                          <li>
                          • पेटेंट, ट्रेडमार्क, व्यापार रहस्य, कॉपीराइट,
                            प्रचार के अधिकार या अन्य अधिकारों सहित किसी भी
                            पार्टी के किसी भी मालिकाना अधिकार का उल्लंघन करना।
                          </li>
                          <li>
                          • ब्रेकथ्रू ट्रस्ट और उसके कर्मचारियों या
                            प्रतिनिधियों सहित किसी भी व्यक्ति या संस्था का रूप
                            धारण करना।
                          </li>
                          <li>
                          • किसी तीसरे व्यक्ति की निजता/गोपनीयता का उल्लंघन
                            करना।
                          </li>
                           अपने विवेकाधिकार के अनुसार, ब्रेकथ्रू ट्रस्ट के पास यह
                          निर्धारित करने का अधिकार है, कि कोई कंटेन्ट उपयुक्त है
                          या नहीं और इन शर्तों का अनुपालन करते हुए ,उस कंटेन्ट
                          को पोस्ट करने या अगर नहीं तो हटाने का अधिकार है, लेकिन
                          जिम्मेदारी नहीं हैं। ब्रेकथ्रू आगे किसी भी कंटेन्ट को
                          बनाने और एडिट करने और क्रम को बदलने का अधिकार सुरक्षित
                          रखता है। अगर आप आपत्तिजनक कंटेन्ट पोस्ट करते हैं तो हम
                          सेवा के उपयोग को सीमित या बंद भी कर सकते हैं। चूंकि
                          ब्रेकथ्रू सेवा पर यूजर्स या थर्ड पार्टी द्वारा पोस्ट
                          किए गए सभी कंटेन्ट को नियंत्रित नहीं कर सकता है, तो आप
                          अपने जोखिम पर सेवा का उपयोग करने के लिए सहमत हैं। आप
                          समझते हैं कि सेवा का उपयोग करने से आप उस कंटेन्ट के
                          संपर्क में आ सकते हैं जो आपको गलत या आपत्तिजनक लग सकता
                          है, और आप सहमत हैं कि किसी भी परिस्थिति में किसी भी
                          कंटेन्ट जिसमें कोई त्रुटि या चूक, या किसी भी कंटेन्ट
                          के उपयोग के परिणामस्वरूप आपको होने वाली किसी भी प्रकार
                          की हानि या नुकसान के लिए ब्रेकथ्रू किसी भी तरह से
                          उत्तरदायी नहीं होगा।
                        </ul>
                      </p>
                      <h3>कंटेन्ट बैकअप</h3>
                      <p>
                        हालाँकि कंटेन्ट का नियमित बैकअप लिया जाता है, पर फिर भी
                        इस बात की कोई गारंटी नहीं है कि डेटा के मामले मे कोई
                        नुकसान या गड़बड़ी नहीं होगी। ब्रेकथ्रू कंटेन्ट के बैकअप को
                        प्रभावित करने वाली किसी भी ज्ञात या खोजी गई समस्या के
                        निवारण के लिए सहयोग और प्रयास करेगी। लेकिन आप स्वीकार
                        करते हैं कि ब्रेकथ्रू ट्रस्ट के कंटेन्ट की अखंडता या
                        उपयोग करने योग्य स्थिति में कंटेन्ट को सफलतापूर्वक बहाल
                        करने में विफलता से संबंधित कोई दायित्व हमारा नहीं है।
                      </p>
                      <h3>हमारे लिए आपकी प्रतिक्रिया </h3>
                      <p>
                        वेबसाइट और मोबाइल एप्लिकेशन के माध्यम से ब्रेकथ्रू
                        ट्रस्ट को दी गई किसी भी फीडबैक के जरिये आप सभी अधिकार,
                        शीर्षक और रुचि हमे देते हैं और हमें एक गैर-विशिष्ट,
                        स्थायी, अपरिवर्तनीय, रॉयल्टी मुक्त, लाइसेंस का उपयोग
                        करने, पुन: प्रस्तुत करने, साझा करने, ऐसी प्रतिक्रिया
                        बिना रोक टोक के देने के लिए सहमत हैं।{" "}
                      </p>
                      <h3>अन्य वेब साइटों के लिंक</h3>
                      <p>
                        हमारी वेबसाइट और मोबाइल एप्लिकेशन में तीसरे पक्ष की वेब
                        साइटों या सेवाओं के लिंक हो सकते हैं जो हमारे स्वामित्व
                        या नियंत्रण में नहीं हैं। ब्रेकथ्रू का किसी तीसरे पक्ष
                        की वेब साइटों या सेवाओं के कंटेंट, गोपनीयता नीतियों या
                        प्रथाओं पर कोई नियंत्रण नहीं है और न ही हम इसके लिए कोई
                        ज़िम्मेदारी लेते हैं। आप आगे स्वीकार करते हैं और सहमत
                        होते हैं कि ब्रेकथ्रू ट्रस्ट प्रत्यक्ष या अप्रत्यक्ष रूप
                        से ऐसी किसी वेबसाइट या सेवा पर या उसके माध्यम से उपलब्ध
                        किसी भी कंटेन्ट, सामान या सेवाओं के उपयोग या निर्भरता के
                        कारण या कथित तौर पर हुए किसी भी नुकसान या हानि के लिए
                        जिम्मेदार या उत्तरदायी नहीं होगा। हम आपको सख़्ती से सलाह
                        देते हैं कि आप जिन तीसरे-पक्ष की वेबसाइटों या सेवाओं पर
                        जाते हैं, उनके नियमों और शर्तों और गोपनीयता नीतियों को
                        पढ़ें।
                      </p>
                      <h3>समापन</h3>
                      <p>
                        यदि आप किसी भी कारण से इन नियमों और शर्तों का उल्लंघन
                        करते हैं, तो हम बिना किसी पूर्व सूचना या दायित्व के,
                        पूर्णरूप से, आपके अकाउंट को तुरंत समाप्त या निलंबित कर
                        सकते हैं। समाप्ति पर, सेवा का उपयोग करने का आपका अधिकार
                        तुरंत समाप्त हो जाएगा। यदि आप अपना अकाउंट बंद करना चाहते
                        हैं, तो आप सिर्फ सेवा का उपयोग करना बंद करना है।
                      </p>

                      <h3>दायित्व की सीमा</h3>
                      <p>
                        लागू कानून द्वारा दी गई अधिकतम सीमा तक, किसी भी स्थिति
                        में ब्रेकथ्रू किसी भी विशेष, आकस्मिक, अप्रत्यक्ष, या
                        भाविष्य में होने वाले नुकसान के लिए उत्तरदायी नहीं होगा,
                        जो वेबसाइट और मोबाइल एप्लिकेशन के साथ उपयोग किए गए थर्ड
                        पार्टी सॉफ़्टवेयर या हार्डवेयर, या अन्यथा इन शर्तों के
                        किसी भी प्रावधान के संबंध में, उपयोग या उपयोग करने में
                        असमर्थता से संबंधित या अन्य किसी भी तरह से उत्पन्न होता
                        है। सेवा आपको "जैसी है" और "जैसी उपलब्ध है" और किसी भी
                        प्रकार की वारंटी के बिना सभी दोषों और समस्याओं के साथ दी
                        जाती है। लागू कानून के तहत दी गई अधिकतम सीमा तक,
                        ब्रेकथ्रू ट्रस्ट अपनी ओर से और अपने सहयोगियों और अपने
                        संबंधित लाइसेंस दाताओं और सेवा प्रदाताओं की ओर से,
                        स्पष्ट रूप से वेबसाइट के संबंध में सभी वारंटियों, चाहे
                        व्यक्त, निहित, वैधानिक या अन्यथा, को अस्वीकार करता है।
                        वेबसाइट और मोबाइल एप्लिकेशन, किसी विशेष उद्देश्य के लिए
                        उपयोगिता की सभी निहित वारंटियों सहित, पूर्वगामी तक सीमित
                        किए बिना, ब्रेकथ्रू कोई वारंटी या वचन नहीं देता है, और
                        किसी भी प्रकार का प्रतिनिधित्व नहीं करता है कि सेवा आपकी
                        आवश्यकताओं को पूरा करेगी, किसी भी इच्छित परिणाम को
                        प्राप्त करेगी, संगत होगी या किसी अन्य सॉफ़्टवेयर,
                        एप्लिकेशन, सिस्टम या सेवाओं के साथ काम करें, बिना किसी
                        रुकावट के काम करें, किसी भी प्रदर्शन या विश्वसनीयता
                        मानकों को पूरा करें या त्रुटि मुक्त रहें या कोई भी
                        त्रुटि या दोष ठीक किया जा सकता है या किया जाएगा।
                        पूर्वगामी को सीमित किए बिना, न तो ब्रेकथ्रू ट्रस्ट और न
                        ही उनका कोई प्रदाता किसी भी प्रकार के बताये गए या
                        अपेक्षित प्रतिनिधित्व या वारंटी नहीं देता है,: ( i )
                        सेवा के संचालन या उपलब्धता, या जानकारी, कंटेंट और या
                        उत्पादों के बारे में उस पर; (ii) कि सेवा बाधा रहित या
                        त्रुटि रहित होगी; (iii) सेवा के माध्यम से प्रदान की गई
                        किसी भी जानकारी या कंटेंट की सटीकता, विश्वसनीयता, या
                        मुद्रा के रूप में; या (iv) कि सेवा के लिए ब्रेकथ्रू
                        ट्रस्ट की ओर से भेजे गए, इसके सर्वर, कंटेंट, या ई-मेल
                        वायरस, स्क्रिप्ट, ट्रोजन हॉर्स, वर्म्स, मैलवेयर,
                        टाइमबॉम्ब या अन्य हानिकारक घटकों से मुक्त हैं।
                      </p>

                      <h3>इन नियमों और शर्तों में बदलाव </h3>
                      <p>
                        हम किसी भी समय इन शर्तों को अपने विवेकाधिकार के हिसाब से
                        संशोधित करने या बदलने का अधिकार सुरक्षित रखते हैं। उन
                        संशोधनों के प्रभावी होने के बाद हमारी सेवा को देखने या
                        उपयोग जारी रखने से, आप संशोधित शर्तों से बाध्य होने के
                        लिए सहमत हैं। यदि आप पूरी तरह या आंशिक रूप से नई शर्तों
                        से सहमत नहीं हैं, तो आप वेबसाइट और मोबाइल एप्लिकेशन और
                        सेवा का उपयोग बंद कर सकते हैं।
                      </p>

                      <h3> विवादों का समाधान</h3>
                      <p>
                        इन उपयोग की शर्तों के किसी भी उल्लंघन के बारे में हमें
                        सूचित करने के लिए आपको हमसे संपर्क करने के अलावा, आप
                        किसी भी विवाद में या आपके और किसी अन्य उपयोगकर्ता के बीच
                        उत्पन्न होने वाले विवादों के समाधान में शामिल नहीं होने
                        या उसमे हमें शामिल करने का प्रयास करने के लिए सहमत हैं।
                        यदि आप हमारे साथ अपने किसी भी विवाद के बारे में हमें
                        सूचित करना चाहते हैं, तो आपको contact@inbreakthrough.org  पर हमसे संपर्क
                        करना चाहिए। यदि आपका एक या एक से अधिक उपयोगकर्ताओं के
                        साथ कोई विवाद है, तो आप इस तरह के विवादों से उत्पन्न
                        होने वाले या किसी भी तरह से ज्ञात और अज्ञात, सभी प्रकार
                        के दावों, मांगों और नुकसान (वास्तविक और अपेक्षित) , लाभ,
                        सद्भावना, उपयोग, गोपनीयता या डेटा के नुकसान से ब्रेकथ्रू
                        ट्रस्ट को मुक्त करने के लिए सहमत हैं।
                      </p>
                      <h3>कोई छूट नहीं</h3>
                      <p>
                        अगर हम उपयोग की इन शर्तों के तहत हमें उपलब्ध किसी भी
                        अधिकार का प्रयोग या लागू नहीं करते हैं, तो यह उन
                        अधिकारों की छूट नहीं देता है।
                      </p>
                      <h3>सम्पूर्ण समझौता </h3>
                      <p>
                        हमारी गोपनीयता व वेबसाइट और मोबाइल एप्लिकेशन पर दी गई
                        उपयोग की ये शर्तें, और कोई भी अतिरिक्त शर्तें हमारे
                        संपूर्ण समझौते का गठन करती हैं और उपयोग की इन शर्तों का
                        विषय हमारे द्वारा दिए गए या हमारे बीच किए गए सभी पूर्व
                        समझौतों, व्यवस्थाओं, समझ और प्रतिनिधित्व (चाहे मौखिक या
                        लिखित) से संबंधित हैं।
                      </p>

                      <h3>विभाज्यता </h3>
                      <p>
                        उपयोग की इन शर्तों का प्रत्येक प्रावधान अलग-अलग है। यदि
                        कोई प्रावधान अवैध, अप्रवर्तनीय या अमान्य है या हो जाता
                        है, तो इसे उपयोग की इन शर्तों से अलग माना जाएगा और उपयोग
                        की शेष शर्तें प्रभावित नहीं होंगी।
                      </p>
                      <h3>अधिकार क्षेत्र:</h3>
                      <p>
                        ब्रेकथ्रू ट्रस्ट मुख्य रूप से भारत में काम करता है और
                        उपयोग की ये शर्तें भारत के कानूनों द्वारा शासित होती
                        हैं। उपयोग की इन शर्तों के संबंध में होने वाले किसी भी
                        विवाद को सुनने और निर्धारित करने के लिए भारत की अदालतों
                        के पास गैर-विशिष्ट अधिकार क्षेत्र होगा। इस वेबसाइट और
                        मोबाइल एप्लिकेशन का उपयोग करते समय , आप किसी भी विवाद या
                        असहमति (जो इससे उत्पन्न हो सकता है) के समाधान के लिए नई
                        दिल्ली, भारत के अधिकार क्षेत्र से सहमत होते हैं ।
                      </p>
                      <h3>संचार</h3>
                      <p>
                        आप इलेक्ट्रॉनिक रूप से हमसे संचार प्राप्त करने के लिए
                        सहमति देते हैं और आप सहमत हैं कि सभी समझौते, नोटिस,
                        डिस्कलोजर/खुलासे और अन्य संचार जो हम आपसे इलेक्ट्रॉनिक
                        रूप से करते हैं, जो किसी भी कानूनी आवश्यकता को पूरा करते
                        हैं कि ऐसे संचार लिखित रूप में हों। आप हमारी गोपनीयता
                        नीति में दिए गए किसी भी उद्देश्य के लिए हमारे द्वारा (या
                        हमारी ओर से) भेजे गए इलेक्ट्रॉनिक संदेशों और सूचनाओं को
                        प्राप्त करने के लिए सहमति देते हैं।
                      </p>

                      <h3>संपर्क करें</h3>
                      <p>
                        यदि इन नियमों और शर्तों के बारे में आपके कोई प्रश्न हैं,
                        तो आप contact@inbreakthrough.org पर एक ईमेल भेजकर हमसे
                        संपर्क कर सकते हैं
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};

export default SignUpOtp;
