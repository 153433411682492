import React from "react";
import { NavLink } from "react-router-dom";

const Kyakrna05kheloActivity = () => {
  return (
    <>
      <main>
        <section className="kya-karna-hai-scroll khelo-bg">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20">
                <div className="col col-lg-7">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                   कैसे खेलें ?
                  </h2>
                </div>
                <div className="col col-lg-5">
                  <NavLink
                    className="float-right width-auto"
                    to="/khelo-05-activity-02-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6">
                <p
                  className="mpara large-para fs-30 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  इस एक्टिविटी को करने के लिए हमने इस चक्के पर कई टॉपिक्स लिखे
                  हैं, आपको यह चक्का घुमाना है और जो भी टॉपिक पर निशान आए उसके
                  बारे में बोलना है।
                </p>
                <div
                  id="wrapper"
                  className="wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h1 className="h1 mb-20 mt-0">चक्का घुमाओ </h1>
                  i
                  <center><img width="70%" draggable="false" src="assets/img/chakka-ghuma-img.png" alt="" /></center>

                  {/* <div id="wrapper2">
                    <div id="wheel2">
                      <div id="inner-wheel2">
                        <div className="sec2" data-name1="मेरी सबसे पसंदीदा फिल्म है">
                          <span className="fa "> मेरी सबसे पसंदीदा फिल्म है</span>
                        </div>
                        <div className="sec2" data-name1="मुझे सबसे अच्छा गेम लगता है">
                          <span className="fa ">  मुझे सबसे अच्छा गेम लगता है</span>
                        </div>
                        <div className="sec2" data-name1="आज कल की लड़कियाँ">
                          <span className="fa "> आज कल की लड़कियाँ</span>
                        </div>
                        <div className="sec2" data-name1="आज कल के लड़के">
                          <span className="fa "> आज कल के लड़के</span>
                        </div>
                        <div className="sec2" data-name1="मेरी इच्छाएँ / महत्वकांक्षा ">
                          <span className="fa"> मेरी इच्छाएँ / महत्वकांक्षा </span>
                        </div>
                        <div className="sec2" data-name1="मुझे सबसे अच्छा गेम लगता है">
                          <span className="fa ">  मुझे सबसे अच्छा गेम लगता है</span>
                        </div>
                      </div>
                      <div id="spin2">
                        <div id="inner-spin2" />
                      </div>
                      <div id="shine2" />
                    </div>
                    <div id="txt2" />
                  </div> */}
                  {/* <div id="txt" /> */}
                </div>

                <div
                  className="d-flex align-items-center mt-m-5  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="mr-20">
                    <div className="timer timer-small">
                      <div className="content-alarm">
                        <p>01:00</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="mpara large-para fs-22 text-left mt-0 ">
                      यहाँ पर 5 टॉपिक्स है, कोई भी 1 आपका टॉपिक हो सकता है आपके
                      पास 1 मिनट का समय है।
                    </p>
                    <ul  className="ullist-set wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s">
                      <li>1 मिनट के बाद रिकॉर्डिंग अपने आप बंद हो जाएगी| </li>
                      <li>आप जैसे ही रिकॉर्डिंग का बटन दबाएँगे वैसे ही आपका समय शुरू हो जाएगा |</li>
                    </ul>
                  </div>
                </div>
                <p
                  className="mpara large-para fs-30 mt-10 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  1 मिनट के बाद रिकॉर्डिंग अपने आप बंद हो जाएगी।
                </p>
                {/* <div
                  className="d-flex wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="mr-20">
                    <label className="filelabel">
                      <img
                        src="assets/img/on-boarding/btn/recording-img.svg"
                        alt=""
                      />
                      <span className="title"></span>
                      <input
                        className="FileUpload1"
                        id="FileInput"
                        name="booking_attachment"
                        type="file"
                      />
                    </label>
                  </div>
                  <div>
                    <p className="mpara large-para fs-22 text-left mt-0">
                      आप जैसे ही रिकॉर्डिंग का बटन दबाएँगे वैसे ही आपका समय शुरू
                      हो जाएगा।
                    </p>
                  </div>
                </div> */}
              </div>
              <div
                className="col-lg-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="six-tare">
                  <div className="d-flex align-items-top">
                    <div className="mr-20">
                      <img
                        className="balb-kk"
                        src="assets/img/dekho/icon/balb-kk.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="mpara large-para fs-22 text-left mt-0 ">
                        ध्यान दें - आप बोलते समय कोशिश करें कि आप बस इस टॉपिक के
                        बारे में ही सोचें, इससे आपको आगे क्या बोलना है उसमें मदद
                        मिलेगी। आप साफ़, स्पष्ट और ऊँची आवाज़ में बोलें ताकि आपकी बात
                        अच्छे से समझ आ सके|
                      </p>
                    </div>
                  </div>
                  <p>इस एक्टिविटी को खत्म करने पर आपको 10 तारे मिलेंगे। </p>
                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 10</h4>
                    </li>
                  </ul>
                  <p className="mt-25">
                    प्रयास करने के लिए आपको 1 तारा मिलेगा{" "}
                  </p>
                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 1</h4>
                    </li>
                  </ul>
                  <p className="mt-25">
                    यदि आपकी रिकॉर्डिंग 30 सेकंड्स की है तो आपको 3 तारे मिलेंगे
                  </p>
                  <ul className="ul-r align-left">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>X 3</h4>
                    </li>
                  </ul>

                </div>
                <p
                  className="mpara large-para fs-30 mt-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <b>अपनी रिकॉर्डिंग को सुनिए और यह सुनिश्चित करिए कि - </b>{" "}
                </p>
                <ul
                  className="ullist-set wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <li>क्या आपने बिना रुके अपने टॉपिक पर बात की है?</li>
                  <li>क्या आपने टॉपिक से संबंधित ही बातें की हैं?</li>
                  <li>1 मिनट तक इस विषय पर बोलें </li>
                </ul>
                <div
                  className="mt-15 mb-15 "

                >
                  <NavLink to="/khelo-05-activity-03-screen" className="btnset ">
                    <span>शुरू करें </span>{" "}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Kyakrna05kheloActivity;
